import { Navigate, Outlet } from 'react-router-dom';
import { Header, Loader } from '..';
import { useStoreAuth, useStoreUserAuthMethods } from '../../hooks';
import "./security.scss";

const Security = () => {
  const auth = useStoreAuth();
  useStoreUserAuthMethods();

  return (
    <>
      {auth?.isLoading && < Loader />}
      {auth?.error && <Navigate to="/login" replace={true} />}
      <Header />
      <Outlet />
    </>
  )
}

export default Security