import { Stack } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector, useStoreAuth } from "../../hooks";
import { userSelector } from "../../store/slices/userSlice";
import { Header, Loader, NavSidemenu, ResponsiveSidemenu } from "..";
import { getAuthFromStorage } from "../../services";
import MediaQuery from 'react-responsive'
import { WindowSize } from '../../core'
import './home.scss';

const Home = () => {
    const userState = useAppSelector(userSelector);
    const auth = useStoreAuth();
    return (
        <>
            {auth?.isLoading && <Loader />}
            {(!getAuthFromStorage() || auth?.error) && <Navigate to="/login" replace={true} />}
            {userState.isLoggedIn &&
                <Stack gap={4}>
                    {/* While using laptops */}
                    <MediaQuery minWidth={WindowSize.MinWebWidth}>
                        <Header />
                    </MediaQuery>

                    {/* While using mobile or tablet */}
                    <MediaQuery maxWidth={WindowSize.MaxMobileWidth}>
                        <ResponsiveSidemenu />
                    </MediaQuery>

                    <Stack gap={3} direction="horizontal" className="web-stack">
                        <MediaQuery minWidth={WindowSize.MinWebWidth}>
                            <NavSidemenu />
                        </MediaQuery>
                        <Outlet />
                    </Stack>

                </Stack>}
        </>
    )
}

export default Home

