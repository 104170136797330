interface IInputProps {
  onSearch: (search: string) => void;
  placeHolder: string;
  children?: JSX.Element;
  value: string;
  setValue: (value: string) => void;
}

const Input = (props: IInputProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const search = e.target.value;
    props.setValue(search);
    props.onSearch(search);
  }
  return (
    <>
      <div className={`input-wrapper px-2 py-1`}>
        {props.children}
        <input onChange={handleChange} max="60" placeholder={props.placeHolder} value={props.value} />
      </div>
    </>
  )
}

export default Input