import { Container, Card, Row, Col, Button, Stack } from "react-bootstrap";
import "./serviceSetting.scss";
import _ from "lodash";
import React from "react";
import { EditServiceSetting, ServiceLogo } from "..";
import { IUpdateServiceSetting } from "../../core";
const ServiceSetting = ({ services, setServices }: { services: IUpdateServiceSetting[], setServices: (value: IUpdateServiceSetting[]) => void }) => {

    const [mode, setMode] = React.useState<'display' | 'edit'>('display');
    const [withCachedLogos, setWithCachedLogos] = React.useState<boolean>(false);

    const toggleMode = () => {
        if (mode === 'edit') {
            setMode('display');
        } else {
            setMode('edit');
        }

    }

    const enableCachedLogos = () =>{
        setWithCachedLogos(true);
    }


    return (<>
        <Container>
            <Card body>
                <div className="card-container">
                    <Row>
                        <Col className="services-title">
                            <h4>Services</h4>
                        </Col>
                        <Col>
                            {mode === 'display' && <Button className="edit-setting-btn" onClick={toggleMode}>Edit</Button>}
                        </Col>
                    </Row>
                    {mode === 'display' && (<Row className="services">
                        <Stack gap={2}>
                            {
                                _.map(services, (service, index) => {
                                    return (
                                        <Card body key={service.settingId} >
                                            <Container>
                                                <Row>
                                                    <h5>Service {index + 1}</h5>
                                                </Row>
                                                <Row className="service-row">
                                                    <Col md={2} className="service-label">Logo Image</Col>
                                                    <Col md={3}><ServiceLogo id={service.settingId} withCachedLogos={withCachedLogos} onSuccessLogos={enableCachedLogos} /></Col>
                                                    <Col md={1} className="service-label">Title</Col>
                                                    <Col md={5} className="service-value">{service.title}</Col>
                                                </Row>
                                                <Row className="service-row">
                                                    <Col md={2} className="service-label">Description</Col>
                                                    <Col md={10} className="service-value">{service.description}</Col>
                                                </Row>
                                            </Container>
                                        </Card>
                                    )
                                })
                            }
                        </Stack>
                    </Row>)}
                    {mode === 'edit' && (<EditServiceSetting services={services} onUpdate={(value) => { setServices(value); setWithCachedLogos(false) }} onClose={toggleMode} onCancel={setServices} withCachedLogos={withCachedLogos} onSuccessLogos={enableCachedLogos} />)}
                </div>
            </Card>
        </Container>
    </>);
};

export default ServiceSetting;
