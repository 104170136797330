import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Middleware, MiddlewareAPI, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit";
import { saveAuthToStorage, ApiRequest, updateTwoFactorAuthMethodInStorage } from "../../services";
import { ErrorCodes, IRestResponse, IRestResponseError, TwoFactorAuthMethod } from '../../core';
import _ from "lodash";
import { authActions } from "../slices/authSlice";
import { notificationActions } from '../slices/notificationSlice';
import { GeneralError } from '../../utils/errorMessage';
import { userActions } from '../slices/userSlice';
import { firebaseSignOut } from '../../utils/firebase';
import { authMethodActions } from '../slices/authMethodSlice';


const apiMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: PayloadAction<any>) => {
    //handle api actions
    if (_.startsWith(action.type, 'api')) {
        //save token in storage    
        if (ApiRequest.endpoints.authenticateUser.matchFulfilled(action) || ApiRequest.endpoints.verifyOtp.matchFulfilled(action)) {
            saveAuthToStorage(action.payload.Data);
        }
        if(ApiRequest.endpoints.enableAuthApp.matchFulfilled(action)){
            updateTwoFactorAuthMethodInStorage(TwoFactorAuthMethod.AuthApp)
        }
        if(ApiRequest.endpoints.enableSmsAuth.matchFulfilled(action)){
            updateTwoFactorAuthMethodInStorage(TwoFactorAuthMethod.SMS)
        }
        const apiActionError = ((action.payload as FetchBaseQueryError)?.data as IRestResponse<any>)?.Errors;
        const error: IRestResponseError | null = apiActionError ? apiActionError[0] : null;
        const isLoggedout: boolean = (action.payload && (ApiRequest.endpoints.me.matchRejected(action) ||
            ApiRequest.endpoints.authenticateUser.matchRejected(action))) || ApiRequest.endpoints.disableTwoFactorAuth.matchFulfilled(action) ||
            ApiRequest.endpoints.disableTwoFactorAuth.matchRejected(action) || (error !== null && (error?.Code === ErrorCodes.InvalidToken));
            if (isLoggedout) {
            //remove token from storage 
            saveAuthToStorage(null);
            api.dispatch(authActions.reset());
            api.dispatch(userActions.reset());
            api.dispatch(authMethodActions.reset())
            firebaseSignOut();      //end firebase sms session
        }
        if (isRejectedWithValue(action) && error?.Code !== ErrorCodes.InvalidToken) {
            //view notification in case of error
            api.dispatch(notificationActions.set({
                view: true,
                state: 'danger',
                title: 'Error',
                text: error?.Message ?? GeneralError,
                autoHide: true,
            }));
            
        }
    }
    return next(action);
};

export default apiMiddleware;