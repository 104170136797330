import { ApiRequest } from './../services';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer } from "./slices/authSlice";
import apiMiddleware from './middlewares/apiMiddleware';
import { notificationReducer } from './slices/notificationSlice';
import { userReducer } from './slices/userSlice';
import { authMethodReducer } from './slices/authMethodSlice';

export const store = configureStore({
    reducer: {
        [ApiRequest.reducerPath]: ApiRequest.reducer,
        auth: authReducer,
        user: userReducer,
        notifaction: notificationReducer,
        authMethod: authMethodReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([ApiRequest.middleware, apiMiddleware]),
});

export const getAppState = (): RootState => store.getState();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

