import React from 'react'
import { AutoCompleteSelect } from '..'
import { useGetAllAccountsQuery } from '../../services'

interface IProps {
    onSelect: (value:{label: string, value: number}[]) => void,
    accountNumbers: {label: string, value: number}[] | null
}
const AccountNumberSearch = ({ onSelect, accountNumbers }: IProps) => {

    return (
        <>
            <AutoCompleteSelect name="cbsAccounts" isMulti={true} 
                value={accountNumbers} setValue={onSelect}
                getOptionsQuery={useGetAllAccountsQuery}
                placeHolder="Search By Account Number"
                requestParamsConverter={input => ({ page: 1, searchByAccountNumber: input })}
                optionsConverter={data => data ? data.Data.map(cbsAccount => ({ value: cbsAccount.Id, label: cbsAccount.SerialNumber.toString() })) : []}
                validationKeys={{ pattern: 'digitsString' }} />    </>
    )
}

export default AccountNumberSearch