import { ConfirmationResult } from 'firebase/auth';
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { LoggedOutHeader, SendOtp, VerifyAppAction, VerifyLoginCode, VerifySmsAction } from '..';
import { Timer as TimerEnum, TwoFactorAuthMethod } from '../../core';
import { useAppSelector, useMaskPhoneNumber, useStoreAuth, useStoreUserAuthMethods } from '../../hooks';
import { VerifyOtpLabel } from '../../utils/confirmationMessage';
import "./VerifyOtp.scss";
import { authMethodSelector } from '../../store/slices/authMethodSlice';

interface VerifyFormState {
  twoFactorAuthMethod: TwoFactorAuthMethod,
  otp: string,
}
const VerifyOtp = ({phoneNumber}: {phoneNumber: string}) => {
  useStoreUserAuthMethods();
  const { isOtpVerified } = useStoreAuth()
  const formMethods = useForm<VerifyFormState>({ mode: 'all' });
  const authMethodState = useAppSelector(authMethodSelector);
  const maskedPhoneNumber = useMaskPhoneNumber(phoneNumber || "")

  const [selectedMethod, setSelectedMethod] = React.useState<TwoFactorAuthMethod | undefined>(authMethodState.twoFactorAuthMethods?.[0])
  const [confirmationResult, setConfirmationResult] = React.useState<ConfirmationResult>();
  const [time, setTime] = React.useState<number>(TimerEnum.initialSmsTime)
  const [restartTimer, setRestartTimer] = React.useState(false)
  const handleSelectVerificationMethod = (method: TwoFactorAuthMethod) => {
    setSelectedMethod(method)
    formMethods.clearErrors()
    formMethods.setValue('otp', "")
    if(method !== TwoFactorAuthMethod.SMS){
      setConfirmationResult(undefined)
      setTime(0)
    }
  }
  React.useEffect(() => {
    setSelectedMethod(authMethodState.twoFactorAuthMethods?.[0]);
  }, [authMethodState.twoFactorAuthMethods]);

  return (
    <>
      <LoggedOutHeader wrapperClass="header-container">
       <></>
      </LoggedOutHeader>
      <Container className='verify-card'>
          <div id='recaptcha-container' className='recaptcha'></div>

          <span className='title'>  Two-factor authentication required</span>
          <hr></hr>
          <div className='card-body'>
            <p>
              Enter the 6-digit code from your
              <span className="bold-text"> code generator </span>
              or third-party app below.
            </p>
          </div>
          <FormProvider {...formMethods}>

            <div className='generator-div'>
              <Form.Group>

                {
                  authMethodState && authMethodState.twoFactorAuthMethods?.map((method, index) => {
                    return (
                      <div key={index}>
                        <Form.Check
                          defaultChecked={authMethodState.twoFactorAuthMethods?.[0] === method}
                          className='auth-radio'
                          type="radio"
                          id={`authentication-id-${method}`}
                          label={VerifyOtpLabel(method, maskedPhoneNumber)}
                          name="verify-otp"
                          onClick={() => handleSelectVerificationMethod(method)}

                        />
                        {(method === TwoFactorAuthMethod.SMS && !confirmationResult && selectedMethod === TwoFactorAuthMethod.SMS) &&
                          <SendOtp setConfirmationResult={setConfirmationResult} setRestartTimer={setRestartTimer} time={time} setTime={setTime} title='Send Code' isCodeResent={true} phoneNumber={phoneNumber} />}
                        {((selectedMethod === method && method === TwoFactorAuthMethod.AuthApp) || (selectedMethod === method && confirmationResult && method === TwoFactorAuthMethod.SMS)) &&
                          <>
                            <VerifyLoginCode currentMethod={method} time={time} restartTime={restartTimer} setTime={setTime} setRestartTime={setRestartTimer} setConfirmationResult={setConfirmationResult} phoneNumber={phoneNumber} />
                          </>
                        }
                        {index !== Number(authMethodState?.twoFactorAuthMethods?.length) - 1 && <hr></hr>}
                      </div>
                    )
                  })

                }

              </Form.Group>

            </div>

            {selectedMethod === TwoFactorAuthMethod.AuthApp ? <VerifyAppAction btnStyle='continue' btnTitle='Continue' isOtpVerified={isOtpVerified} isLoggedIn={true} />
              : <VerifySmsAction confirmationResult={confirmationResult} btnTitle='Continue' btnStyle='verify-next'  isOtpVerified={isOtpVerified} isLoggedIn={true} />}
          </FormProvider>

        </Container>
    </>
  )
}

export default VerifyOtp