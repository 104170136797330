import React from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import "./printSetting.scss";
import { IMarginValue } from "../../core";
import { PrintSettingModal } from "..";

const PrintSetting = ({ marginValues, setMargrinValues }: { marginValues: IMarginValue, setMargrinValues: (value: any) => void }) => {

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const initModal = () => {
    setShowModal(true);
  };

  return (
    <Container>

      <Card body>
        <div className="card-container">
          <Row>
            <Col className="print-setting-title">
              <h4>Print Settings </h4>
            </Col>
            <Col>
              <Button className="edit-setting-btn" onClick={initModal}>
                Edit
              </Button>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col md={3} className="margin-setting">
              <span className="margin-key">Top Margin</span>
              <span className="margin-value">
                {(marginValues?.topMargin !== undefined)
                  ? Number(marginValues.topMargin)
                  : ""}
              </span>
            </Col>
            <Col md={3} className="margin-setting">
              <span className="margin-key">Left Margin</span>
              <span className="margin-value">
                {(marginValues?.leftMargin !== undefined)
                  ? Number(marginValues.leftMargin)
                  : ""}
              </span>
            </Col>
          </Row>
        </div>
      </Card>
      <PrintSettingModal setShowModal={setShowModal} showModal={showModal} marginValues={marginValues} setMarginValues={setMargrinValues} />
    </Container>
  );
};

export default PrintSetting;
