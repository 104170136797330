import { Col, Row } from 'react-bootstrap'
import { TwoFactorAuthMethod } from '../../core'
import {AuthAppCard, SmsAuthCard} from '..'
import { useNavigate } from 'react-router-dom'


const SecurityMethod = ({ title, securityMethods, enabledAuthMethods }: { title: string, securityMethods: TwoFactorAuthMethod[], enabledAuthMethods: TwoFactorAuthMethod[] }) => {
  const navigate = useNavigate();

  const handleDisableOtp = () => {
    navigate('/')
  }
  return (
    <>
      {securityMethods.length !== 0 && <div className='selected-security-method'>
       <h4 className='selected-method-title'> {title}</h4>
        <Row>
          {

            securityMethods.map((method) => {
              return (
                <Col key={method} md={4}>
                  {method === TwoFactorAuthMethod.AuthApp ? <AuthAppCard onDisableOtp={handleDisableOtp} userAuthMethods={enabledAuthMethods} /> : method === TwoFactorAuthMethod.SMS && <SmsAuthCard onDisableOtp={handleDisableOtp} userAuthMethods={enabledAuthMethods}  />}
                </Col>
              )
            })
          }
        </Row>
      </div>}
    </>
  )
}

export default SecurityMethod