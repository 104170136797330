import { ExportCsv } from '..';
import { IGetLocationRequest } from '../../core';
import { useExportLocationsCsvMutation } from '../../services';
import { formatDateTime } from '../../utils/dateTime';
import { LocationsCsvFileName } from '../../utils/fileName';

interface props {
    exportCsvParams: IGetLocationRequest;
}
const ExportLocationsCsv = ({ exportCsvParams }: props) => {
    const [exportCsv, { data: exportedData, isLoading: csvIsLoading }] = useExportLocationsCsvMutation();
    const exportHandler = () => exportCsv(exportCsvParams);
    const dateTo =  exportCsvParams.dateTo && formatDateTime(exportCsvParams.dateTo, "MM/DD/YY");
    const dateFrom =  exportCsvParams.dateFrom && formatDateTime(exportCsvParams.dateFrom, "MM/DD/YY")
    const fileName = LocationsCsvFileName(dateFrom, dateTo);

    return (
        <div  className='location-export-col'>
            <ExportCsv handleAction={exportHandler} response={exportedData} isLoading={csvIsLoading}  fileName={fileName} />
        </div>
    )
}

export default ExportLocationsCsv
