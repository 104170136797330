import React from 'react'
import { useVerifyOtpMutation } from '../../services'
import { TwoFactorAuthMethod } from '../../core';
import { Button } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import {Loader} from '..';
import { useFormContext } from 'react-hook-form';

interface VerifyAppProps {
  btnTitle: string, 
  btnStyle: string,
  isOtpVerified?: boolean,
  isLoggedIn?: boolean

}
const VerifyAppAction = ({ btnTitle, btnStyle, isOtpVerified, isLoggedIn }: VerifyAppProps) => {
  const navigate = useNavigate();
  const [verifyOtp, { data: verificationData, isLoading }] = useVerifyOtpMutation()
  const { getValues } =  useFormContext();

  const handleOnSubmit = () => {
      if (isOtpVerified && isLoggedIn){
      navigate('/home', {replace: true})
       }
       else {
        verifyOtp({ twoFactorAuthMethod: TwoFactorAuthMethod.AuthApp, otp: getValues().otp }) 
       }
  }


  return (
    <>
    {isLoading && <Loader/>}
      {(verificationData ) && <Navigate to={"/home"} replace={true} />}
      <Button onClick={handleOnSubmit} className={btnStyle} >{btnTitle}</Button>

    </>
  )
}

export default VerifyAppAction