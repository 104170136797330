import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TwoFactorAuthMethod } from '../../core';

interface AuthMethodState {
    twoFactorAuthMethods: TwoFactorAuthMethod[] | undefined
};

export const initialState: AuthMethodState = {
    twoFactorAuthMethods: []
};

export const authMethodSlice = createSlice({
    name: 'authMethod',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<AuthMethodState>) => {
            state.twoFactorAuthMethods = action.payload.twoFactorAuthMethods
        },
        reset: (state) => {
            state.twoFactorAuthMethods = initialState.twoFactorAuthMethods
        },
    },

});

export const authMethodSelector = (state: RootState): AuthMethodState => state.authMethod;
export const authMethodActions = authMethodSlice.actions;
export const authMethodReducer = authMethodSlice.reducer;