import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { IRetentionValue } from '../../core';
import { RetentionSettingModal } from "..";
import "./retentionSetting.scss";

interface IRetentionProps {
    retentionDaysValue: IRetentionValue,
    onUpdateRetentionValue: (value: IRetentionValue) => void
}
const RetentionSetting = ({retentionDaysValue, onUpdateRetentionValue}: IRetentionProps) => {

    const [showRetentionModal, setShowRetentionModal] = React.useState<boolean>(false);
    const initModal = () => {
        setShowRetentionModal(true);
    };
    return (
        <Container>

            <Card body>
                <div className="card-container">
                    <Row>
                        <Col className="print-setting-title">
                            <h4>Retention Settings </h4>
                        </Col>
                        <Col>
                            <Button className="edit-setting-btn" onClick={initModal}>
                                Edit
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        {" "}
                        <Col md={3} className="retention-setting">
                            <span className="retention-key">Retention Days</span>
                            <span className="retention-value">
                                {(retentionDaysValue?.retentionDays !== undefined)
                                    ? Number(retentionDaysValue.retentionDays)
                                    : ""}
                            </span>
                        </Col>
                    </Row>
                </div>
            </Card>
            <RetentionSettingModal onCloseModal={setShowRetentionModal} showModal={showRetentionModal} retentionValue={retentionDaysValue} onUpdateRetentionValue={onUpdateRetentionValue} />
        </Container>)
}

export default RetentionSetting