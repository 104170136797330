import React from 'react';
import { IUser } from '../../core';
import { ConfirmationDialog, Loader } from '..';
import { removeUserConfirmationMessage, removeUserSuccessMessage } from '../../utils/confirmationMessage';
import { useDeleteUserMutation } from '../../services';

interface IDeleteUserProps {
    user: IUser,
    onSuccess: () => void,
    setShowModal: (value: boolean) => void,
    showModal: boolean,

}
const RemoveUser = ({ user, onSuccess, setShowModal, showModal }: IDeleteUserProps) => {
    const [deleteUser, { data: deletedUser, isLoading: isDeleteUserLoading, isSuccess: isUserDeleted }] = useDeleteUserMutation();
    const handleOnDeleteUser = () => deleteUser({ id: user.Id });
    const confirmationName = (user.FirstName && user.LastName) ? `${user.FirstName} ${user.LastName}` : `${user.Email}`
    return (
        <>
            { isDeleteUserLoading && <Loader/>}
            <ConfirmationDialog btnTitle='Remove' title='Remove User' onSave={handleOnDeleteUser} showModal={showModal} setShowModal={setShowModal}
                onSuccess={onSuccess} successMessage={removeUserSuccessMessage()} isSuccess={isUserDeleted} response={deletedUser}>
                <>
                    {removeUserConfirmationMessage(confirmationName)}
                </>
            </ConfirmationDialog>
        </>
    );
}

export default RemoveUser;