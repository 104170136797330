import { getAuthFromStorage } from './../services';
import * as React from 'react';
import { useAppDispatch } from ".";
import { authMethodActions } from '../store/slices/authMethodSlice';

export const useStoreUserAuthMethods = () => {

    ///init user if isOtp verified true
    const dispatch = useAppDispatch();
    const auth = getAuthFromStorage();
    React.useEffect(() => {
        if (auth) {
            dispatch(authMethodActions.set({twoFactorAuthMethods: auth.twoFactorAuthMethods}));
        }
        // eslint-disable-next-line
    }, [dispatch ]);

}