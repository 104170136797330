import { ConfirmationResult } from 'firebase/auth'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { Loader } from '..'
import { TwoFactorAuthMethod } from '../../core'
import { useAppDispatch } from '../../hooks'
import { useVerifyOtpMutation } from '../../services'
import { notificationActions } from '../../store/slices/notificationSlice'
import { FirebaseOtpExpired, SmsVerificationFailed } from '../../utils/errorMessage'
import { verifySmsOtp } from '../../utils/firebase'

interface VerifySmsProps {
  confirmationResult?: ConfirmationResult,
  btnTitle: string,
  btnStyle: string,
  isOtpVerified?: boolean,
  isLoggedIn?: boolean

}
const VerifySmsAction = ({ confirmationResult, btnTitle, btnStyle, isOtpVerified, isLoggedIn }: VerifySmsProps) => {
  const { getValues } = useFormContext()
  const [verifyOtp, { data: verificationData, isLoading: isVerifyLoading }] = useVerifyOtpMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [smsLoading, setSmsLoading] = React.useState<boolean>(false)

  const handleOnSubmit = async () => {
    setSmsLoading(true)
    verifySmsOtp(getValues().otp, confirmationResult).then(async (credentials) => {
      setSmsLoading(false)
      const smsToken = await credentials?.user.getIdToken();
      if (isOtpVerified && isLoggedIn){
      navigate('/home', {replace: true})
       }
       else {
      verifyOtp({ twoFactorAuthMethod: TwoFactorAuthMethod.SMS, otp: getValues().otp, smsToken: smsToken })
    }
    }).catch((err) => {
     let firebaseErrorMessage = SmsVerificationFailed() 
      if(String(err).includes('code-expired')){
        firebaseErrorMessage = FirebaseOtpExpired()
      }
      setSmsLoading(false)
      dispatch(notificationActions.set({
        view: true,
        autoHide: true,
        state: 'danger',
        text: firebaseErrorMessage,
        title: 'Error!'
      }));
    })
  }

  return (
    <>
      {verificationData && <Navigate to={"/home"} replace={true} />}
      {(isVerifyLoading || smsLoading) && <Loader />}
      <Button onClick={handleOnSubmit} className={btnStyle} >{btnTitle}</Button>

    </>
  )
}

export default VerifySmsAction

