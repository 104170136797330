export enum Month {
    JAN = 1,
    FEB = 2,
    MAR = 3,
    APR = 4,
    MAY = 5,
    JUN = 6,
    JUL = 7,
    AUG = 8,
    SEP = 9,
    OCT = 10,
    NOV = 11,
    DEC = 12
}

export const MonthDescription: Record<Month, string> = {
    [Month.JAN]: 'JAN',
    [Month.FEB]: 'FEB',
    [Month.MAR]: 'MAR',
    [Month.APR]: 'APR',
    [Month.MAY]: 'May',
    [Month.JUN]: 'JUN',
    [Month.JUL]: 'JUL',
    [Month.AUG]: 'AUG',
    [Month.SEP]: 'SEP',
    [Month.OCT]: 'OCT',
    [Month.NOV]: 'NOV',
    [Month.DEC]: 'DEC',
}


export const MonthBarColor: Record<Month, string> = {
    [Month.JAN]: '#98c7d8',
    [Month.FEB]: '#cbe6f8',
    [Month.MAR]: '#cce2f0',
    [Month.APR]: '#70d7ec',
    [Month.MAY]: '#38c4e0',
    [Month.JUN]: "#c6e9ff",
    [Month.JUL]: "#84cee9",
    [Month.AUG]: "#2680eb",
    [Month.SEP]: "#3c83ac",
    [Month.OCT]: "#225e96",
    [Month.NOV]: "#332d83",
    [Month.DEC]: "#332d83",
}