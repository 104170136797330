import React from 'react'
import { getTimerStartTime, saveTimerStartTime } from '../../../services';
import { Timer as TimerEnum } from '../../../core';

const Timer = ({ time, setTime, restart, setRestart }: { time: number, setTime: (value: any) => void, restart: boolean, setRestart: (value: boolean) => void }) => {

    React.useEffect(() => {
        const startTime = getTimerStartTime()
        if(!startTime){
            saveTimerStartTime()
        }else{
            const timeDiff = Math.floor(
                (new Date().getTime()- new Date(startTime).getTime())/ 1000
            );
            if(timeDiff <= TimerEnum.initialSmsTime){
                setTime(time -timeDiff)
            }else{
                setTime(0)
            }
        }
        const timer = setInterval(() => {
            setTime((time: number) => {
                if (time === 0) {
                    clearInterval(timer);
                    setRestart(false)
                    return 0;
                } else return time - 1;
            });
        }, 1000);
        return ()=>{
            clearInterval(timer)
        }
        // eslint-disable-next-line
    }, [restart]);

    return (
        <div>
            {time !== 0 ? <span> Time Remaining( {`${Math.floor(time / 60)}`.padStart(2, '0')}:
                {`${time % 60}`.padStart(2, '0')} )</span> : <></>}
        </div>
    )
}

export default Timer