import _ from "lodash";
import { Select } from "..";
import { OrderStatus, OrderStatusLabel } from "../../core";

interface IOrderStatusSelect {
    onChange: (selectedStatus: unknown) => void,
    value: OrderStatus[],
    setValue: React.Dispatch<React.SetStateAction<OrderStatus[]>>
}
const OrderStatusSelect = (props: IOrderStatusSelect) => {
    return (
        <Select options={_.map(OrderStatusLabel, (value, index) => ({ value: Number(index), label: value }))}
            isMulti={true} onChange={props.onChange} value={props.value} setValue={props.setValue} placeHolder="Select Status..." />
    )
}

export default OrderStatusSelect;