import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth, IToken } from '../../core';
import { ApiRequest } from "../../services";
import { refreshTokenAction } from '../refreshTokenAction';
import { RootState } from '../store';

interface AuthState {
    token: IToken | null,
    isOtpVerified: boolean
};

export const initialState: AuthState = {
    token: null,
    isOtpVerified: false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<IAuth>) => { 
            state.token = action.payload.token;
            state.isOtpVerified = action.payload.isOtpVerified
        },
        reset: (state) => {
            state.token = initialState.token;
            state.isOtpVerified = initialState.isOtpVerified
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshTokenAction, (state, action) => {
                state.token  = action.payload
                    // eslint-disable-next-line 
                state.isOtpVerified = state.isOtpVerified
                
        });
        //update token after authentication
        builder.addMatcher(ApiRequest.endpoints.authenticateUser.matchFulfilled, (state, action) => {
            state.token = action.payload.Data.token; 
            state.isOtpVerified = action.payload.Data.isOtpVerified
        });
    }
})

export const authSelector = (state: RootState): AuthState => state.auth;

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;