import { Role } from "../core";

export const tabs = [
    { name: "statistic", text: "Home", icon: "fa fa-home", route: "statistic", roles: [Role.ADMIN] },
    { name: "order", text: "Orders", icon: "fa fa-list", route: "order", roles: [Role.ADMIN, Role.NORMAL, Role.SUPERVISOR] },
    { name: "quality-control", text: "Quality Control", icon: "fa fa-shield", route: "quality-control", roles: [Role.ADMIN, Role.SUPERVISOR] },
    { name: "team", text: "Team", icon: "fa fa-user-plus", route: "team", roles: [Role.ADMIN, Role.SUPERVISOR] },
    { name: "accounting", text: "Accounting", icon: "fa fa-money", route: "accounting", roles: [Role.ADMIN] },
    { name: "setting", text: "Settings", icon: "fa fa-cog", route: "setting", roles: [Role.ADMIN] },

];
