import { isAccessTokenExpired, saveTokenToStorage } from './authService';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import {
  IUser, IAuthRequest, IRestResponse, ErrorCodes, IToken, IPaginationResponse, IGetOrderRequest, IGetUserRequest, IUpdateUserRequest, IResendEmailRequest, ICancelOrderRequest, IRefundOrderRequest,
  IGetUserByInvitation, IRegisterUser, IInviteUser, IResendTcnEmailRequest, IForgetPassword, IResetPassword, IGetUserByResetPasswordCode, IOrderCounts, IOrderShipment, ICreateOrderShipment, IResendResubmittedEmailRequest,
  IUpdateSetting, ISetting, ICbsAccount, IGetAccountsRequest, IRequestLocationRequest, IOrderLocation, OrderFingerPrint, IGetOrderFingerprintsRequest, IGetOrderFingerprintsImageRequest, IResubmitOrderRequest, ILocation, IGetLocationRequest, IVerifyOtp, IOtpQrCode, IDisableOtp, IEnableSmsAuth, IAuth, IOrderPagination, IGetServiceLogo,
IUpdateOrderRequest} from "../core";
import _ from "lodash";
import { saveAuthToStorage } from '.';
import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { refreshTokenAction } from '../store/refreshTokenAction'
import { RootState } from '../store/store';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { EndpointBuilder, MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { userActions } from '../store/slices/userSlice';
import { firebaseSignOut } from '../utils/firebase';

type MethodType = 'POST' | 'PUT' | 'GET' | 'DELETE';
type ResposneType = 'json' | 'blob';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const logoutUrl = '/users/actions/logout';
interface IQueryArgs<Trequest, Tresponse> {
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, '', "api">;
  url: string;
  method: MethodType;
  withToken?: boolean;
  withParams?: boolean;
  withBody?: boolean;
  withUrlParams?: boolean;
  withTokenBody?: boolean;
  isMultipart?: boolean;

  onQueryStarted?: (args: Trequest, api: MutationLifecycleApi<Trequest,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, Tresponse, "api">) => void,
  responseType?: ResposneType,
}

const baseQuery = fetchBaseQuery({ baseUrl: baseUrl, mode: 'cors' });

//return fetchArgs
const prepareRequest = (url: string, method: MethodType, token: string | null, body?: any | null, params?: any | null, responeType: ResposneType = 'json', isMultipart?: boolean, urlParams?: boolean): FetchArgs => {


  let headers: { [key: string]: string } = {
    "accept": "application/json, text/plain, */*"
  };

  if(!isMultipart){
    headers['Content-Type'] = "application/json";
  }

  let restUrl = url;
  if (urlParams) {

    let urlParams: string[] = [];
    for (const param in params) {
      if (_.includes(restUrl, `:${param}`)) {
        restUrl = _.replace(restUrl, `:${param}`, params[param]);
        urlParams.push(param);
      }
    }
    const otherArgs: { [key: string]: any } = _.pickBy(params, (value, key) => {
      return !_.includes(urlParams, key);
    });
    params = otherArgs;
  }

  let httpParams = params;
  if (params) {
    httpParams = new URLSearchParams();
    _.forIn(params, (value, i) => {
      if (value || value === 0) {
        if (_.isArray(value)) {
          _.mapValues(value, (v) => httpParams.append(`${i}[]`, v));
        } else { httpParams.append(i, value); }
      }
    });

  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return ({
    url: restUrl,
    method,
    headers,
    body,
    params: httpParams,
    responseHandler: (response) => {
      switch (responeType) {
        case 'blob':
          return response.ok ? response.blob().then(blob => URL.createObjectURL(blob)) : response.json();
        default:
          return response.json();
      }
    }
  });
}

const refreshToken = async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const appState = api.getState() as RootState;
  const refreshArgs = prepareRequest('/users/actions/refresh', 'POST', appState.auth.token?.refreshToken ?? null);
  const refreshRequest = await baseQuery(refreshArgs, api, extraOptions);
  const refreshRequestData = refreshRequest.data as IRestResponse<IToken>;
  if (refreshRequestData) {
    // store the new tokens
    api.dispatch(refreshTokenAction(refreshRequestData.Data));
    //update tokens in storage
    saveTokenToStorage(refreshRequestData.Data);
    //update authorization headers with the new token
    _.update(args.headers as object, 'Authorization', () => `Bearer ${refreshRequestData.Data.accessToken}`);
    return baseQuery(args, api, extraOptions);
  }
  //logout incase of error
  return logout({ url: logoutUrl, method: 'POST' }, api, extraOptions);
}

const logout = async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const appState = api.getState() as RootState;
  args.body = {
    accessToken: appState.auth.token?.accessToken,
    refreshToken: appState.auth.token?.refreshToken
  }
  const logoutRequest = await baseQuery(args, api, extraOptions);
  api.dispatch(refreshTokenAction(null));
  saveAuthToStorage(null);
  api.dispatch(userActions.reset())
  firebaseSignOut();        //end firebase sms session
  return logoutRequest;
}

const baseQueryWithRefreshToken: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  async (args, api, extraOptions) => {
    const requestArg = args as FetchArgs;
    //log out 
    if (requestArg.url.endsWith(logoutUrl)) {
      return await logout(requestArg, api, extraOptions);
    }
    if (_.has(requestArg.headers, 'Authorization') && isAccessTokenExpired()) {
      //request refresh token 
      return await refreshToken(requestArg, api, extraOptions);
    }
    let result = await baseQuery(args, api, extraOptions);
    const errors = (result.error?.data as IRestResponse<any>)?.Errors
    if (errors && (errors[0].Code === ErrorCodes.ExpiredToken)) {
      return await refreshToken(requestArg, api, extraOptions);
    }
    if (errors && errors[0].Code === ErrorCodes.TokenNotProvided) {
      return await logout({ url: logoutUrl, method: 'POST' }, api, extraOptions);
    }
    return result
  }

const queryFn = async <Tresponse, Trequest>(args: Trequest, api: BaseQueryApi, extraOptions: {},
  baseQuery: (arg: string | FetchArgs) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>,
  url: string, method: MethodType, withToken?: boolean, withParams?: boolean, withBody?: boolean, withUrlParams?: boolean, responeType?: ResposneType, withTokenBody?: boolean, isMultipart?: boolean) => {
  const { auth } = api.getState() as RootState;
  const token = withToken ? auth.token?.accessToken ?? null : null;
  const body = args && withBody ? withTokenBody ? { ...args, token: auth?.token } : args : null;
  const params = args && (withParams || withUrlParams) ? args : null;
  const requestArg = prepareRequest(url, method, token, body, params, responeType, isMultipart, withUrlParams);
  const result = await baseQuery(requestArg);
  return result.data ? { data: result.data as Tresponse } : { error: result.error as FetchBaseQueryError };
}

const mutationEndPoint = <TResponse, TRequest>(mutationArgs: IQueryArgs<TRequest, TResponse>) => {
  return mutationArgs.builder.mutation<TResponse, TRequest>({
    queryFn: (args, api, extraOptions, baseQuery) =>
      queryFn<TResponse, TRequest>(args, api, extraOptions, baseQuery, mutationArgs.url,
        mutationArgs.method, mutationArgs.withToken, mutationArgs.withParams, mutationArgs.withBody, mutationArgs.withUrlParams, mutationArgs.responseType, mutationArgs.withTokenBody, mutationArgs.isMultipart),
    //update cache manually 
    onQueryStarted: mutationArgs.onQueryStarted,
  });
}

const queryEndPoint = <TResponse, TRequest>(queryArgs: IQueryArgs<TRequest, TResponse>) => {
  return queryArgs.builder.query<TResponse, TRequest>({
    queryFn: (args, api, extraOptions, baseQuery) =>
      queryFn<TResponse, TRequest>(args, api, extraOptions, baseQuery, queryArgs.url,
        queryArgs.method, queryArgs.withToken, queryArgs.withParams, queryArgs.withBody, queryArgs.withUrlParams, queryArgs.responseType, queryArgs.withTokenBody, queryArgs.isMultipart),


  });
}

export const ApiRequest = createApi({
  baseQuery: baseQueryWithRefreshToken,
  endpoints: (builder) => ({
    authenticateUser: mutationEndPoint<IRestResponse<IAuth>, IAuthRequest>({
      builder,
      url: '/users/actions/auth', method: 'POST', withBody: true,
    }),
    me: queryEndPoint<IRestResponse<IUser>, void>({
      builder,
      url: '/users/actions/me', method: 'GET', withToken: true
    }),
    logout: mutationEndPoint<IRestResponse<boolean>, void>({
      builder,
      url: logoutUrl, method: 'POST'
    }),
    getOrders: queryEndPoint<IRestResponse<IOrderPagination>, IGetOrderRequest>({
      builder,
      url: '/order', method: 'GET', withToken: true, withParams: true
    }),
    resendConfirmationEmail: mutationEndPoint<IRestResponse<boolean>, IResendEmailRequest>({
      builder,
      url: '/order/:id/actions/resend-email', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    cancelOrder: mutationEndPoint<IRestResponse<boolean>, ICancelOrderRequest>({
      builder,
      url: '/order/:id/actions/cancel', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    refundOrder: mutationEndPoint<IRestResponse<boolean>, IRefundOrderRequest>({
      builder,
      url: '/order/:id/actions/refund', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    requestOrderFingerprints: mutationEndPoint<IRestResponse<boolean>, { id: number }>({
      builder,
      url: '/order/:id/actions/request-fingerprints', method: 'POST', withToken: true, withUrlParams: true
    }),
    getUsers: queryEndPoint<IRestResponse<IPaginationResponse<IUser>>, IGetUserRequest>({
      builder,
      url: '/users', method: 'GET', withToken: true, withParams: true
    }),
    updateUser: mutationEndPoint<IRestResponse<IUser>, IUpdateUserRequest>({
      builder,
      url: '/users/:id', method: 'PUT', withToken: true, withBody: true, withUrlParams: true
    }),
    inviteUser: mutationEndPoint<IRestResponse<boolean>, IInviteUser>({
      builder, url: '/users/actions/invite', method: 'POST', withBody: true, withToken: true
    }),
    getUserByInvitation: queryEndPoint<IRestResponse<IUser>, IGetUserByInvitation>({
      builder, url: '/users/actions/get-by-invitation/:invitationCode', method: 'GET', withUrlParams: true
    }),
    registerUser: mutationEndPoint<IRestResponse<IUser>, IRegisterUser>({
      builder, url: '/users/actions/register-account', method: 'POST', withBody: true
    }),
    resendTcnEmail: mutationEndPoint<IRestResponse<boolean>, IResendTcnEmailRequest>({
      builder,
      url: '/order/:id/actions/resend-tcn-email', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    downloadFbiForm: mutationEndPoint<string, { id: number }>({
      builder,
      url: '/order/:id/actions/download-fingerprint-form', method: 'GET', withToken: true, withUrlParams: true, responseType: 'blob'
    }),
    forgetPassword: mutationEndPoint<IRestResponse<boolean>, IForgetPassword>({
      builder,
      url: '/users/actions/forget-password', method: 'POST', withBody: true
    }),
    resetPassword: mutationEndPoint<IRestResponse<boolean>, IResetPassword>({
      builder,
      url: '/users/actions/reset-password', method: 'POST', withBody: true
    }),
    getUserByResetPasswordCode: queryEndPoint<IRestResponse<IUser>, IGetUserByResetPasswordCode>({
      builder, url: '/users/actions/get-by-reset-password-code/:resetPasswordCode', method: 'GET', withUrlParams: true
    }),
    orderCounts: queryEndPoint<IRestResponse<IOrderCounts[]>, void>({
      builder,
      url: '/order/actions/statistics', method: 'POST', withToken: true, withBody: true
    }),
    exportOrdersCsv: mutationEndPoint<string, IGetOrderRequest>({
      builder,
      url: '/order/actions/export-csv', method: 'GET', withToken: true, withParams: true, responseType: 'blob'
    }),
    setShippingInfo: mutationEndPoint<IRestResponse<IOrderShipment>, ICreateOrderShipment>({
      builder,
      url: '/order-shipments', method: 'POST', withToken: true, withBody: true
    }),
    resubmitOrder: mutationEndPoint<IRestResponse<boolean>, IResubmitOrderRequest>({
      builder,
      url: '/order/:id/actions/resubmit-finger-print', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    updateSetting: mutationEndPoint<IRestResponse<ISetting>, IUpdateSetting>({
      builder,
      url: '/settings/:id', method: 'PUT', withToken: true, withBody: true, withUrlParams: true
    }),
    getAllSettings: queryEndPoint<IRestResponse<IPaginationResponse<ISetting>>, void>({
      builder,
      url: '/settings', method: 'GET', withToken: true
    }),
    resendResubmittedEmail: mutationEndPoint<IRestResponse<boolean>, IResendResubmittedEmailRequest>({
      builder,
      url: '/order/:id/actions/resend-resubmitted-email', method: 'POST', withBody: true, withToken: true, withUrlParams: true
    }),
    getAllAccounts: queryEndPoint<IRestResponse<ICbsAccount[]>, IGetAccountsRequest>({
      builder,
      url: '/cbs-accounts', method: 'GET', withToken: true, withParams: true
    }),
    requestOrderLocation: mutationEndPoint<IRestResponse<IOrderLocation>, IRequestLocationRequest>({
      builder,
      url: '/locations/actions/request-location', method: 'POST', withBody: true, withToken: true,
    }),
    getOrderFingerprints: queryEndPoint<IRestResponse<OrderFingerPrint[]>, IGetOrderFingerprintsRequest>({
      builder,
      url: '/order/:id/fingerprints', method: 'GET', withToken: true, withUrlParams: true
    }),
    getOrderFingerprintsImage: queryEndPoint<string, IGetOrderFingerprintsImageRequest>({
      builder,
      url: '/order/:id/fingerprints/:imageName', method: 'GET', withToken: true, withUrlParams: true, responseType: 'blob'
    }),
    getLocations: queryEndPoint<IRestResponse<IPaginationResponse<ILocation>>, IGetLocationRequest>({
      builder,
      url: '/locations/', method: 'GET', withToken: true, withParams: true
    }),
    exportLocationsCsv: mutationEndPoint<string, IGetLocationRequest>({
      builder,
      url: '/locations/actions/export-csv', method: 'GET', withToken: true, withParams: true, responseType: 'blob'
    }),
    deleteUser: mutationEndPoint<IRestResponse<boolean>, { id: number }>({
      builder,
      url: '/users/:id', method: 'DELETE', withToken: true, withUrlParams: true
    }),

    verifyOtp: mutationEndPoint<IRestResponse<IAuth>, IVerifyOtp>({
      builder,
      url: '/users/actions/verify-otp', method: 'POST', withBody: true, withToken: true, withTokenBody: true
    }),
    enableAuthApp: mutationEndPoint<IRestResponse<IOtpQrCode>, void>({
      builder,
      url: '/users/actions/enable-auth-app', method: 'POST', withToken: true
    }),
    enableSmsAuth: mutationEndPoint<IRestResponse<boolean>, IEnableSmsAuth>({
      builder,
      url: '/users/actions/enable-sms-auth', method: 'POST', withToken: true, withBody: true
    }),
    disableTwoFactorAuth: mutationEndPoint<IRestResponse<boolean>, IDisableOtp>({
      builder,
      url: '/users/actions/disable-otp', method: 'POST', withToken: true, withBody: true, withTokenBody: true
    }),
    getServiceLogo: queryEndPoint<string, IGetServiceLogo>({
      builder,
      url: '/settings/:id/actions/download-logo', method: 'GET', withUrlParams: true, responseType: 'blob'
    }),
    bulkUpdateServiceSetting: mutationEndPoint<IRestResponse<ISetting[]>, any>({
      builder,
      url: '/settings', method: 'PUT', withToken: true, withBody: true, isMultipart: true
    }),
    updateOrder: mutationEndPoint<IRestResponse<boolean>,IUpdateOrderRequest>({
      builder,
      url: '/order/:id', method: 'PUT', withToken: true, withBody: true, withUrlParams: true
    }),
    resetUserAuth: mutationEndPoint<IRestResponse<boolean>, {id: number}>({
      builder, 
      url: "/users/:id/actions/reset-authentication", method: 'DELETE', withToken: true, withUrlParams: true
    })
  })
});

export const { useAuthenticateUserMutation, useLazyMeQuery, useLogoutMutation, useGetOrdersQuery, useUpdateUserMutation,
  useResendConfirmationEmailMutation, useResendResubmittedEmailMutation, useCancelOrderMutation, useGetUsersQuery,
  useInviteUserMutation, useGetUserByInvitationQuery, useRegisterUserMutation, useResendTcnEmailMutation, useDownloadFbiFormMutation
  , useForgetPasswordMutation, useResetPasswordMutation, useGetUserByResetPasswordCodeQuery, useOrderCountsQuery, useExportOrdersCsvMutation,
  useSetShippingInfoMutation, useResubmitOrderMutation, useUpdateSettingMutation, useLazyGetAllSettingsQuery, useGetAllAccountsQuery,
  useRefundOrderMutation, useRequestOrderLocationMutation, useRequestOrderFingerprintsMutation,
  useLazyGetOrderFingerprintsQuery, useGetOrderFingerprintsImageQuery, useGetLocationsQuery, useExportLocationsCsvMutation, useDeleteUserMutation,
  useVerifyOtpMutation, useEnableAuthAppMutation, useDisableTwoFactorAuthMutation, useEnableSmsAuthMutation, useLazyGetServiceLogoQuery,
   useBulkUpdateServiceSettingMutation, useResetUserAuthMutation, useUpdateOrderMutation } = ApiRequest
