import React from 'react';
import { Modal } from '..';
import { IOtpQrCode } from '../../core';


interface IModalProps {
  qrCode?: IOtpQrCode,
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  setShowVerifyOtpModal: (value: boolean) => void,
}

const SetupAuthAppModal = ({ qrCode, showModal, setShowModal, setShowVerifyOtpModal }: IModalProps) => {
  const closeAuthModal = () => setShowModal(true);

  const [copied, setCopied] = React.useState(false);

  const handleNextBtn = () => {
    setShowModal(false)
    setShowVerifyOtpModal(true)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrCode?.secret || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };
  return (
    <div className='auth-modal'>
      <Modal show={showModal} saveBtnTitle='Next' title='Instructions for setup' onSave={handleNextBtn} onClose={closeAuthModal} size='lg' hideCancelBtn={true} hideCloseBtn={true} >
        <div>

          <span className='instruction-list'>1- Download an authentication app</span>
          <p className='instruction-list-text'>You can choose an authentication app etc. ( Google Authenticator ) or you can use any app</p>
          <span className='instruction-list'>2 - Scan this QR code or copy the key</span>
          <p className='instruction-list-text'>Scan this QR code into the authentication app or copy the key and paste it into the authentication app.</p>
          <div className='qr-code'>
            <img alt='Qr-Code' src={qrCode?.image} />
            <span>{qrCode?.secret} </span>
            <span className={`${copied ? "copied": "copy-key"}`} onClick={copyToClipboard}> {copied ? "Copied!" : "Copy Key"} </span>
          </div>
          <span className='instruction-list'>3 - Copy and enter 6-digit code</span>
          <p className='instruction-list-text'>After the QR code has been scanned or the key has been entered, your authentication app
            will generate a 6-digit code. Copy the code and then press next</p>
        </div>
      </Modal>
    </div>
  )
}

export default SetupAuthAppModal