import { ExportCsv } from '..';
import { IGetOrderRequest } from '../../core';
import { useExportOrdersCsvMutation } from '../../services';
import { OrdersCsvFileName, } from '../../utils/fileName';

interface props {
    exportCsvParams: IGetOrderRequest;
}
const ExportOrdersCsv = ({ exportCsvParams }: props) => {
    const [exportCsv, { data: exportedData, isLoading: csvIsLoading }] = useExportOrdersCsvMutation();
    const exportHandler = () => exportCsv(exportCsvParams);
    const fileName = OrdersCsvFileName();

    return (
        <div className='accounting-export-col'>
            <ExportCsv handleAction={exportHandler} response={exportedData} isLoading={csvIsLoading}  fileName={fileName} />
        </div>
    )
}

export default ExportOrdersCsv
