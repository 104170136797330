import React from 'react';
import { Spinner } from 'react-bootstrap';

const ThumbnailImage = ({ isLoading, image}: {isLoading: boolean; image: string | undefined}) => {
    return (
        <>
            {isLoading ? (
                <div className="d-inline-block">
                    <Spinner animation="border" role="status" variant="dark" className='spinner-ml-0'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <img src={image} className="img-fluid" alt="" />
            )}
        </>
    );
}

export default ThumbnailImage;
