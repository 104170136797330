import { Modal, Loader } from '..';
import { useForm } from 'react-hook-form';
import { IMarginValue, SettingEnum } from '../../core';
import { validationRules } from '../../utils/formValidator';
import { Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import { useUpdateSettingMutation } from '../../services';
import { notificationActions } from '../../store/slices/notificationSlice';
import { UpdatePrintSettingConfirmationMessage } from '../../utils/confirmationMessage';
import { useAppDispatch } from "../../hooks";
import "./printSetting.scss";


const PrintSettingModal = ({ showModal, setShowModal, marginValues, setMarginValues }: { showModal: boolean, setShowModal: (showModal: boolean) => void, marginValues: IMarginValue, setMarginValues: (values: any) => void }) => {

    const printSettingModalData = { title: 'Print Settings', btnTitle: 'Set' }
    const { register, reset, getValues: getPrintSettingValues, setValue: setModalSettingValues, formState: { errors } } = useForm<IMarginValue>({ mode: 'all', defaultValues: { leftMargin: marginValues.leftMargin, topMargin: marginValues.topMargin } });
    const [updatePrintSetting, { data: updatePrintSettingResponse, isLoading: updatePrintSettingLoading }] = useUpdateSettingMutation();
    const dispatch = useAppDispatch();

    const printSettingValidation = validationRules<{ margin: number }>({
        margin: { required: true, pattern: 'positiveNegativeNumbers', displayName: 'Margin' },
    });
    const closeModal = () => {
        reset();
        setShowModal(false);
    };
    const onSaveModalHandler = () => {
        updatePrintSetting({ id: SettingEnum.FBIFORMPrintSettings, value : {topMargin: getPrintSettingValues().topMargin, leftMargin: getPrintSettingValues().leftMargin} });
    }

    const submitHandler = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        onSaveModalHandler();
    }

    React.useEffect(() => {
        if (updatePrintSettingResponse?.Data) {
            closeModal();
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: UpdatePrintSettingConfirmationMessage(),
                title: 'Success!'
            }));
            setMarginValues(updatePrintSettingResponse.Data.Value)
        }
        // eslint-disable-next-line 
    }, [updatePrintSettingResponse])

    React.useEffect(() => {
        if (showModal) {
            setModalSettingValues("leftMargin", marginValues.leftMargin);
            setModalSettingValues("topMargin", marginValues.topMargin)
        }
        // eslint-disable-next-line 
    }, [showModal])

    return (
        <>
            {(updatePrintSettingLoading) && <Loader />}
            <Modal show={showModal} saveBtnTitle={printSettingModalData.btnTitle} title={printSettingModalData.title}
                onSave={onSaveModalHandler} onClose={closeModal} >
                <>
                    <Form onSubmit={submitHandler} >

                        <Form.Group as={Row} className="mb-3" >
                            <Row>
                                <Form.Label column sm={4} className='margin-title'>Top Margin</Form.Label>
                                <Col sm={6} className='margin-input'>
                                    <Form.Control className="input-control" {...register('topMargin', printSettingValidation['margin'])} isInvalid={!!errors.topMargin} type="text" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                </Col>
                                <Col sm={8}>
                                    <Form.Text>
                                        {errors.topMargin && <span className='error-msg'>{errors.topMargin.message}</span>}
                                    </Form.Text>
                                </Col>
                            </Row>


                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Row>
                                <Form.Label column sm={4} className='margin-title'>Left Margin</Form.Label>
                                <Col sm={6} className='margin-input'>
                                    <Form.Control className="input-control" {...register('leftMargin', printSettingValidation['margin'])} isInvalid={!!errors.leftMargin} type="text" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                </Col>
                                <Col sm={8}>
                                    <Form.Text>
                                        {errors.leftMargin && <span className='error-msg'>{errors.leftMargin.message}</span>}
                                    </Form.Text>
                                </Col>
                            </Row>

                        </Form.Group>
                    </Form>

                </>
            </Modal >
        </>
    )
}

export default PrintSettingModal
