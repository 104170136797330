import React from 'react';
import { useLazyGetOrderFingerprintsQuery } from '../../services';
import { Loader, Modal, QualityScoreItem } from "..";
import { Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { FingerprintsNotAvailable } from '../../utils/errorMessage';
import { OrderFingerPrint } from '../../core';

const QualityScore = ({ orderId, orderFingerprints }: { orderId: number, orderFingerprints: OrderFingerPrint[] | undefined }) => {
    const [showQualityScoreModal, setShowQualityScoreModal] = React.useState<boolean>(false);
    const [hasCalcultedScore, setHasCalcultedScore] = React.useState<boolean>(true);

    const [getOrderFingerprints, { data: fetchedOrderFingerprints, isLoading }] = useLazyGetOrderFingerprintsQuery();

    const initQualityScoreModal = React.useMemo(() => {
        return () => {
            if (!orderFingerprints?.some(fingerprint => fingerprint.QualityScore !== null)) {
                getOrderFingerprints({ id: orderId }, true);
                setHasCalcultedScore(false);
            }
            setShowQualityScoreModal(true);
        };
    }, [getOrderFingerprints, orderId, orderFingerprints]);

    const closeQualityScoreModal = React.useMemo(() => {
        return () => {
            setShowQualityScoreModal(false);
        };
    }, []);

    return (
        <>
            {(isLoading) && <Loader />}
            {orderFingerprints?.length ? (
                <>
                    <Button className="show-quality-score-btn" size="lg" onClick={initQualityScoreModal}>Quality Score</Button>
                    <Modal show={showQualityScoreModal} size="lg" saveBtnTitle="Done" title="FBI Quality Score"
                        onSave={closeQualityScoreModal} onClose={closeQualityScoreModal} isDisabledBtn={false} hideCancelBtn={true}>
                        <>
                            <div className="grid-header pt-4">
                                <Col sm={6}>Fingerprint Position (Code)</Col>
                                <Col sm={2}>Score</Col>
                                <Col sm={4}>Quality</Col>
                            </div>
                            {
                                _.map(hasCalcultedScore ? orderFingerprints : fetchedOrderFingerprints?.Data, (value, index) => {
                                    return (
                                        // show row for every fingerprint that has a score
                                        // if no score (null), show row if no image exist to show that is is an annotation
                                        (value.QualityScore || value.Annotation !== 0) &&
                                            <QualityScoreItem orderId={orderId} fingerprint={value} key={value.Id} />
                                    )
                                })
                            }
                        </>
                    </Modal >
                </>
            ) : (
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            {FingerprintsNotAvailable()}
                        </Tooltip>
                    }><span className='show-quality-score-btn-span'><Button className='show-quality-score-btn' size='lg' disabled>Quality Score</Button></span>
                </OverlayTrigger>
            )}
        </>
    );
}

export default QualityScore;
