import { useMemo } from "react";
import { Role } from "../core";
import { useAppSelector } from "./storeHook";
import { userSelector } from "../store/slices/userSlice";

export const useIsAuthUserPermitted = ( targetRole: Role): boolean => {
    const userState = useAppSelector(userSelector);

    return useMemo(() => userState.me?.RoleId === targetRole, [targetRole, userState]);
};
