import _ from "lodash";

export enum Role {
    ADMIN = 1,
    NORMAL = 2,
    SUPERVISOR = 3,
}
export const GetLevelByRole: Record<number, number> = {
    [Role.ADMIN]: 1,
    [Role.SUPERVISOR]: 2,
    [Role.NORMAL]: 3,
};


export const RolesDescription: Record<number, { label: string, value: number }> = {
    [Role.ADMIN]: { label: 'Admin', value: Role.ADMIN },
    [Role.SUPERVISOR]: { label: 'Supervisor', value: Role.SUPERVISOR },
    [Role.NORMAL]: { label: 'Regular', value: Role.NORMAL },
}

export const getLowerLevelRoles = (currentRole: Role) => {
    const currentRoleLevel = GetLevelByRole[currentRole];
    const rolesArray = _.values(RolesDescription); // Get roles objects from description mapping
    
    return _.filter(rolesArray, (role) => GetLevelByRole[role.value] > currentRoleLevel);
};