import React from "react";
import { OrderType, autoProgressBar } from "../../core";
import { useDownloadFbiFormMutation } from "../../services";
import {  useDownload } from "../../hooks";
import _ from "lodash";
import { FingerprintsNotAvailable } from "../../utils/errorMessage";
import { CaPdfReportFileName, FbiPdfReportFileName, FdFormFileName } from "../../utils/fileName";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Progress } from "..";

const DownloadFingerprint = ({ orderId, orderTypeId, firstName, lastName, isDimmed }: { orderId: number, orderTypeId: OrderType, firstName: string, lastName: string, isDimmed: boolean }) => {
    const [progress, setProgress] = React.useState<number>(0);

    const [downloadPdf, { data: downloadPdfResponse, isLoading }] = useDownloadFbiFormMutation();

    const pdfFileName = React.useMemo(() => {
        switch(orderTypeId) {
            case OrderType.CA:
                return CaPdfReportFileName(firstName, lastName, 'zip');
            case OrderType.FBI:
                return FbiPdfReportFileName(firstName, lastName, 'zip');
            default:
                return FdFormFileName(firstName, lastName, 'pdf');
        }
    }, [orderTypeId, firstName, lastName]);

    useDownload(downloadPdfResponse, pdfFileName);

    const downloadHandler = () => {
        setProgress(0);
        downloadPdf({ id: orderId });
    }

    // progress bar for downloading pdf
    React.useEffect(() => {
        if (isLoading && progress <= autoProgressBar.MaxAutoPercentage) {
            const debounce_fun = _.debounce(() => {
                setProgress(function (currentProgress) {
                    return currentProgress + autoProgressBar.StepPercentage;
                });
            }, autoProgressBar.IntervalToUpdateStep);

            debounce_fun();
        }

    }, [isLoading, progress]);

    return (
        <>
            {(isLoading) && <Progress percentage={progress} />}
            {!isDimmed ? (
                <Button className='set-shipping-info-btn' size='lg' onClick={downloadHandler}>
                    Download Fingerprint  <i className='fa fa-download'></i>
                </Button>
            ) : (
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            {FingerprintsNotAvailable()}
                        </Tooltip>
                    }>
                    <span>
                        <Button className='download-btn' size='lg' disabled>Download finger print</Button>
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
}

export default DownloadFingerprint;
