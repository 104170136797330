import { PaymentType, TwoFactorAuthMethod } from "../core";

export const ResendEmailSuccessMessage = () => 'Email sent Successfully';
export const CancelOrderSuccessMessage = (ReferenceId: string) => `Order ${ReferenceId} is canceled and Refunded to the applicant`;
export const InviteUSerConfirmationMessage = () => `You have been signed up in Certifix Sterling dashboard, To start use the service, Please login`;
export const InvitationMailSentSuccessMessage = () => `Invitation Email sent successfully`;
export const NotificationSuccessTitle = () => 'Success!';
export const ResetPasswordSuccessMessage = () => `Password is reset successfully`;
export const OrderShipmentCreatedSuccessMessage = () => `Order Shipment is submitted successfully`;
export const confirmCancelationDialogMessage = (paymentType: PaymentType) => paymentType === PaymentType.onApplicant ? `Are you sure you want to cancel and refund this order?` : `Are you sure you want to cancel this order?`;
export const OrderResubmitSuccessMessage = (ReferenceId: string) => `Order ${ReferenceId} is re-submitted successfully`;
export const OrderResubmitConfirmMessage = () => 'Are you sure you want to resubmit this order?';
export const UpdatePrintSettingConfirmationMessage = () => `Print settings updated successfully.`;
export const ConfirmRefundOrderDialogMessage = () => `Are you sure you want to refund this order?`;
export const AccessCodeCreatedSuccessMessage = () => `Access Code is submitted successfully.`;
export const RefundOrderSuccessMessage = (ReferenceId: string) => `Order ${ReferenceId} is refunded successfully.`;
export const invalidExternalLocationIdErrorMessage = () => `Sterling externalLocationId is invalid.`;
export const requestOrderFingerprintsSuccessMessage = (OrderCode: string) => `Fingerprints is now available for Order ${OrderCode}`;
export const removeUserConfirmationMessage = (displayName: string) => `Are you sure you want to remove ${displayName}?`
export const removeUserSuccessMessage = () => `User removed successfully.`
export const VerifyOtpLabel = (method: TwoFactorAuthMethod, phoneNumber?: string) => method === TwoFactorAuthMethod.SMS ?`Text message (SMS) to ${phoneNumber}` : `Authentication app`;
export const AddPhoneNumberModalTitle = () => `Add Phone Number`
export const EditPhoneNumberModalTitle = () => `Modify Phone Number`
export const VerifyAuthAppModalTitle= () => `Get your code from your authentication app`
export const VerifySmsModalTitle= () => `Enter Confirmation Code`;
export const VerifyAuthAppModalBody = () => `Enter the 6-digit code generated by your authentication app.`
export const VerifySmsModalBody = (phoneNumber: string) =>`Enter the 6-digit code we sent to ${phoneNumber}. \n It may take up to a minute for you to receive this code.`;
export const UsaCountryCode = () =>`+1`
export const UpdateRetentionSettingConfirmationMessage = () => `Retention settings updated successfully.`;
export const UpdateOrderSuccessMessage = () => `Order updated successfully.`;
export const editUserLabel = (fullName: string) => `Changes will be applied to ${fullName}.`;
export const EditUserSuccessMessage = () => `User updated successfully.`
export const ResetAuthConfirmationMessage = () => `Are you really want to reset 2FA secret?`
export const ResetUserAuthSuccessMessage = () => `User authentication reset successfully!`
