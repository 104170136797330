import React from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Label } from "recharts";
import { Col, Container, Row } from "react-bootstrap";
import { MonthBarColor, MonthDescription, OrderStatus, IMonthStatistic, IChartData } from "../../core"
import "./Statistic.scss";
import { useOrderCountsQuery } from "../../services";
import _ from "lodash";
import { Loader } from "..";
import moment from "moment";


const Statistic = () => {

    const { data: orderCounts, isLoading } = useOrderCountsQuery();
    const [statistics, setStatistics] = React.useState<IMonthStatistic[]>([])
    const [chartData, setChartData] = React.useState<IChartData[]>([]);
    const [selectedData, setSelectedData] = React.useState<IChartData>();

    React.useEffect(() => {
        if (orderCounts?.Data) {
            const data = _.range(12, 0).map((value, index) => {
                const month = Number(moment.utc().startOf('month').subtract(11, 'month').add(index, 'month').format('M'))
                const monthlyStatistics = _.filter(orderCounts.Data, d => d.Month === month);
                const completedValue = _.find(monthlyStatistics, d => d.StatusId === OrderStatus.COMPLETED);
                const completedCount = completedValue?.Count ?? 0;
                const completedFees = completedValue?.RollingFees ?? "0";
                const resubmittedValue = _.find(monthlyStatistics, d => d.StatusId === OrderStatus.RESUBMITTED);
                const resubmittedCount = resubmittedValue?.Count ?? 0;
                const resubmittedFees = resubmittedValue?.RollingFees ?? "0";
                const pendingValue = _.find(monthlyStatistics, d => d.StatusId === OrderStatus.NEW);
                const pendingCount = pendingValue?.Count ?? 0;
                const pendingFees = pendingValue?.RollingFees ?? "0";
                return ({
                    month: month,
                    completedOrders: completedCount ,
                    completedRevenue: completedFees,
                    canceledOrders: _.find(monthlyStatistics, d => d.StatusId === OrderStatus.CANCELED)?.Count ?? 0,
                    pendingOrders: pendingCount,
                    resubmittedOrders: resubmittedCount,
                    totalRevenue: (Number(completedFees) + Number(resubmittedFees) + Number(pendingFees)).toFixed(2),
                    year: moment.utc().startOf('month').subtract(11, 'months').add(index, 'month').format('YY')
                });
            })
            setStatistics(data);
        }
    }, [orderCounts]);

    React.useEffect(() => {
        if (statistics) {
            const data = _.map(statistics, (monthData, index) => ({
                name: `${MonthDescription[monthData.month]} ${monthData.year}`,
                value: monthData.completedOrders + monthData.pendingOrders + monthData.resubmittedOrders,
                color: MonthBarColor[monthData.month],
                monthKey: monthData.month,
                index: index,
                year: monthData.year,
            }));
            setChartData(data);
        }
    }, [statistics]);

    return (
        <Container >
            {isLoading && <Loader />}
            <ResponsiveContainer aspect={3}>
                <BarChart margin={{ top: 50, right: 5, left: 15, bottom: 5 }} data={chartData} className="box"
                    onClick={(data) => {
                        if (data && data.activePayload && data.activePayload.length > 0) {
                            setSelectedData(data.activePayload[0].payload);
                        }
                    }}>
                    <CartesianGrid strokeDasharray="5 5" />
                    <Tooltip active={true} cursor={{ fill: "transparent" }} />
                    <XAxis dataKey="name" tabIndex={5} angle={0}
                        label={<Label offset={-5} value="month" position="insideBottomRight" className="axis-lable" />} />
                    <YAxis >
                        <Label value="Total" position="top" dy={-20} className="axis-lable" />
                        <Label value="Orders" position="top" dy={-5} className="axis-lable" />
                    </YAxis>
                    <Bar dataKey="value" radius={[10, 10, 0, 0]} label={{ position: "top" }}>
                        {chartData.map((entry, key) => (<Cell fill={(selectedData?.monthKey === entry.monthKey) ? "red" : entry?.color} key={key} />))}
                    </Bar>

                </BarChart>
            </ResponsiveContainer>

            {selectedData &&
                <Col className="box mb-4">
                    <Row className="m-3 pt-4">
                        <h3 className="theme-color"> <b>{selectedData?.monthKey ? MonthDescription[selectedData?.monthKey] : ''} {selectedData?.year} </b></h3>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="d-flex justify-content-center">
                                <b>{statistics[selectedData?.index].completedOrders}</b>
                            </div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-check-circle theme-color"></i>
                                <span className="mx-2 mb-2">Completed</span>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="d-flex justify-content-center"><b>{statistics[selectedData?.index].pendingOrders}</b></div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-clock-o theme-color"></i>
                                <span className="mx-2 mb-2">Pending</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex justify-content-center"><b> $ {statistics[selectedData?.index].completedRevenue}</b></div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-dollar theme-color"></i>
                                <span className="mx-2 mb-2">Revenue Completed</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4}>
                            <div className="d-flex justify-content-center"><b>{statistics[selectedData?.index].resubmittedOrders}</b></div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-rotate-right theme-color"></i>
                                <span className="mx-2 mb-2">Resubmitted</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex justify-content-center"><b>{statistics[selectedData?.index].canceledOrders}</b></div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-ban theme-color"></i>
                                <span className="mx-2 mb-2">Canceled</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex justify-content-center"><b> $ {statistics[selectedData?.index].totalRevenue}</b></div>
                            <div className="m-2 d-flex justify-content-center">
                                <i className="fa fa-dollar theme-color"></i>
                                <span className="mx-2 mb-2">Total Revenue</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            }
        </Container>
    )
}

export default Statistic