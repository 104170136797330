import { ConfirmationResult } from 'firebase/auth'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Loader, Modal } from '..'
import { useAppDispatch } from '../../hooks'
import { savePhoneNumberToStorage, saveTimerStartTime, useEnableSmsAuthMutation } from '../../services'
import { notificationActions } from '../../store/slices/notificationSlice'
import { InvalidPhoneNumber, SendOtpError, SmsActionModalNotImplemented } from '../../utils/errorMessage'
import { sendOtp, windowRef } from '../../utils/firebase'
import { EditPhoneNumberModalTitle, UsaCountryCode } from '../../utils/confirmationMessage'
import { Navigate } from 'react-router-dom'

interface IModalProps {
    showEnableAuthModal: boolean,
    setShowEnableAuthShowModal: (value: boolean) => void,
    setShowSetupModal: (value: boolean) => void,
    setShowVerifyOtpModal: (value: boolean) => void,
    setConfirmationResult: (value: ConfirmationResult) => void,
    setEnableSmsTitle: (value: string) => void

}
enum Action {
    Enable = 1,
    Edit = 2
}
const EnableSmsAuthModal = ({ showEnableAuthModal, setShowEnableAuthShowModal, setShowSetupModal, setConfirmationResult, setShowVerifyOtpModal, setEnableSmsTitle }: IModalProps) => {
    const dispatch = useAppDispatch();
    const [enableSmsAuth, { data: enabledSmsAuth, isLoading: isEnableSmsLoading, error: enableSmsError }] = useEnableSmsAuthMutation()
    const { getValues, setValue } = useFormContext()
    const [actionValue, setActionValue] = React.useState<Action>(Action.Enable)
    const [isSendOtpLoading, setIsSendOtpLoading] = React.useState<boolean>(false)
    const handleEnableAuth = () => {
        if (actionValue === Action.Enable) {
            setIsSendOtpLoading(true);
            windowRef.RecaptchaVerifier && sendOtp(getValues().phoneNumber, windowRef.RecaptchaVerifier).then((res) => {
                setIsSendOtpLoading(false)
                enableSmsAuth({ phoneNumber: getValues().phoneNumber });
                setConfirmationResult(res)
                saveTimerStartTime()
            }).catch((error) => {
                setIsSendOtpLoading(false)
                let firebaseErrorMessage = SendOtpError();
                if(String(error).includes('invalid-phone-number')){
                    firebaseErrorMessage = InvalidPhoneNumber();
                  }
                dispatch(notificationActions.set({
                    view: true,
                    autoHide: true,
                    state: 'danger',
                    text: firebaseErrorMessage,
                    title: 'Error'
                }));
            });
        }
        else if (actionValue === Action.Edit) {
            setEnableSmsTitle(EditPhoneNumberModalTitle())
            setShowEnableAuthShowModal(false)
            setShowSetupModal(true)
            setActionValue(Action.Enable)
        }
        else {
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'danger',
                text: SmsActionModalNotImplemented(),
                title: 'Error'
            }));
        }
    }

    const handleCloseEnableSmsModal = () => {
        setShowEnableAuthShowModal(false);
        setValue("phoneNumber", UsaCountryCode());
        setActionValue(Action.Enable);
    }

    React.useEffect(() => {
        if (enabledSmsAuth?.Data) {
            setShowEnableAuthShowModal(false)
            setShowVerifyOtpModal(true)
            savePhoneNumberToStorage(getValues().phoneNumber)
        }
        // eslint-disable-next-line
    }, [enabledSmsAuth])


    return (
        <>
            {(isEnableSmsLoading || isSendOtpLoading) && <Loader />}
            {enableSmsError && <Navigate to={"/login"} replace={true} />}

            <Modal size='lg' show={showEnableAuthModal} saveBtnTitle='Next' title={"Select or Modify The Phone Number"} onSave={handleEnableAuth} onClose={handleCloseEnableSmsModal} >
                <div>
                    <p>Please select the phone number that has been entered or modify it.
                        <br></br>
                        This is the number we'll send the login code to.
                    </p>
                    <Form.Group className='phone-number-action'>
                        <Form.Check
                            defaultChecked={actionValue === Action.Enable}
                            className='auth-radio'
                            type="radio"
                            id="inlineRadio1"
                            label={getValues().phoneNumber}
                            name="phoneNumberAction"
                            onClick={() => { setActionValue(Action.Enable) }}

                        />
                        <hr></hr>
                        <Form.Check
                            defaultChecked={actionValue === Action.Edit}
                            className='auth-radio'
                            type="radio"
                            id="inlineRadio2"
                            label={"Modify phone number"}
                            name="phoneNumberAction"
                            onClick={() => { setActionValue(Action.Edit) }}

                        />
                    </Form.Group>

                </div>
            </Modal>

        </>
    )
}

export default EnableSmsAuthModal