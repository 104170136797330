import React from 'react';
import { IRestResponse } from '../../core';
import { validationRules } from '../../utils/formValidator';
import { useForm } from 'react-hook-form';
import { ConfirmationDialog } from '..';
import { Form } from 'react-bootstrap';

interface IRefundDialogProps {
  orderId: number,
  confirmMessage: string,
  btnTitle: string,
  title: string,
  isSuccess: boolean,
  successMessage: string 
  response?: IRestResponse<boolean> ,
  isDisabledBtn?: boolean,
  showModal: boolean,
  setShowModal: (showModal: boolean) => void
  onRefund : (value: {id: number, description: string}) => void,
  onSuccess: () => void,
}
const RefundDialog = ({orderId, confirmMessage, onRefund, title, btnTitle, showModal, setShowModal, isSuccess, response, successMessage, onSuccess}: IRefundDialogProps) => {

  const { register: registerForm, reset: resetForm, getValues: getFormValues, formState: { errors: validationErrors, isValid } } = useForm<{id: number, description: string}>({ mode: 'all', defaultValues: { id: orderId, description: '' } });

  const formValidationRules = validationRules<{ note: string }>({
    note: { required: true, maxLength: 200, pattern:'cancelAndRefundNote', displayName: 'Note' }
  });

  React.useEffect(() => {
    if (showModal) {
      resetForm();
    }
    // eslint-disable-next-line
  }, [showModal]);

  const handleBtnClick = () => {
    onRefund(getFormValues());
  }

  return (
    <>
      <ConfirmationDialog onSave={handleBtnClick} title={title} btnTitle={btnTitle} showModal={showModal} setShowModal={setShowModal}
        isSuccess={isSuccess} response={response} successMessage={successMessage} onSuccess={onSuccess} isDisabledBtn={!isValid}>
        <>
          {confirmMessage}
          <Form.Group className="mt-3" >
            <Form.Control as="textarea" placeholder="Enter a note..." rows={3} {...registerForm('description', formValidationRules['note'])} isInvalid={!!validationErrors.description} />
            <Form.Text>
              {validationErrors.description && <span className='error-msg'>{validationErrors.description.message}</span>}
            </Form.Text>
          </Form.Group>
        </>
      </ConfirmationDialog>
    </>
  );
}

export default RefundDialog;