import React from 'react'
import { Select } from '..'
import { OrderType, PassThroughOrderType, OrderOrPassThroughArray } from '../../core'
import _ from 'lodash';


interface IOrderTypeSelectProps {
    onChange: (type: OrderOrPassThroughArray) => void;
    onSelect: (value: OrderOrPassThroughArray | null) => void;
    value: OrderOrPassThroughArray | null;
    options: Record<OrderType, string> | Record<PassThroughOrderType, string>;
} 
const OrderTypeSelect = ({ value, onChange, onSelect, options }: IOrderTypeSelectProps) => {
    return (
        <>
            <Select withCheckbox={false}
                options={_.map(options, (value, index) => ({ value: Number(index), label: value }))}
                isMulti={false} onChange={onChange}
                value={value} setValue={onSelect} placeHolder="Select Type" />

        </>
    )
}

export default OrderTypeSelect