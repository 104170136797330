import * as React from 'react'
import { Container, Col, Button, Row } from 'react-bootstrap';
import { IGetUserRequest } from '../../core';
import { useGetUsersQuery } from '../../services';
import _ from "lodash";
import { Pagination, Loader, UserItem, InviteUserModal } from '..';
import "./team.scss";
import { NoUsersRecord } from '../../utils/errorMessage';

const Team = () => {
    const [requestParams, setParams] = React.useState<IGetUserRequest>({});
    const {  data: users, isLoading, isFetching, refetch } = useGetUsersQuery(requestParams, { refetchOnMountOrArgChange: true });
    const [viewModal, setViewModal] = React.useState<boolean>(false);
  
    const closeModal = () => {
        setViewModal(false);
    };


    return (
        <>
            <Container fluid className="px-4">
                <Row className="m-3">
                    <Col><h3>{users?.Data?.Total ? users.Data.Total + ' Team Members' : ''}</h3> </Col>
                    <Col>
                        <Button onClick={() => setViewModal(true)} className="modal-btn-save float-end">
                            Invite team member
                        </Button>
                    </Col>
                </Row>
                {/* User grid */}
                <div className="grid-header pt-4">
                    <Col  md={2}>Name </Col>
                    <Col></Col>
                    <Col  md={3}>E-mail</Col>
                    <Col  md={2}>Status </Col>
                    <Col md={2}></Col>
                    <Col md={1}></Col>

                </div>
                <div>
                    {(isLoading || isFetching ) && <Loader />}
                    <div className="grid-body p-3">
                        {(!users?.Data.Data || users?.Data.Data.length === 0) &&
                            (
                                <div className="bg-light grid-row py-3 align-items-center">
                                    <div className="mx-3">{NoUsersRecord()}</div>
                                </div>
                            )
                        }
                        {_.map(users?.Data.Data, (value, index) => {
                            return (
                                <UserItem User={value} key={value.Id} onUpdate={refetch} />
                            )
                        })
                        }
                    </div>
                </div>
                {/* Pagination */}
                {(users?.Data.TotalPages ? (users?.Data.TotalPages > 1) : false) && <>
                    <Pagination currentPage={users?.Data.CurrentPage ?? 1} totalPages={users?.Data.TotalPages ?? 1} total={users?.Data.Total ?? 0}
                        onClick={(page: number) => { setParams((prev) => ({ ...prev, page })); }} />
                </>}
            </Container>
            <InviteUserModal showModal={viewModal} onClose={closeModal} onSuccess={refetch} />
        </>
    )
}

export default Team