import { Spinner } from 'react-bootstrap';
import "./loader.scss";

const Loader = () => {
    return (
        <>
            <div className="loader-wrapper">
                <Spinner animation="border" role="status" variant="dark">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </>
    )
}

export default Loader;