import { useEffect } from "react";
import { ThumbnailImage } from "..";
import { useLazyGetServiceLogoQuery } from "../../services";

const ServiceLogo = ({ id, withCachedLogos, onSuccessLogos }: { id: number, withCachedLogos: boolean, onSuccessLogos: () => void }) => {

    const [getServiceLogo, { data: logo, isLoading: logoLoading, isFetching: logoFetching }] = useLazyGetServiceLogoQuery();

    useEffect(() => {
        getServiceLogo({ id: id }, withCachedLogos)
    }, [getServiceLogo, id, withCachedLogos])

    useEffect(() => {
        if (!withCachedLogos) {
            onSuccessLogos();
        }
    }, [logo, onSuccessLogos, withCachedLogos]);

    return (<>
        <div className="service-logo"><ThumbnailImage isLoading={logoLoading || logoFetching} image={logo} /></div>
    </>)
}
export default ServiceLogo;