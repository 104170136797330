export enum ErrorCodes {
    GeneralError = 1,
    KeyNotFound = 2,
    ValidationError = 3,
    StripeError = 4,
    StripeValidation = 5,
    SterlingError = 6,
    SterlingNotFound = 7,
    SterlingReceivedOrder = 8,
    SterlingValidation = 9,
    OriNotFound = 10,
    LocationNotAcceptable = 11,
    BookAppointmentForbidden = 12,
    AppointmentNotAvailable = 13,
    OrderAlreadyBooked = 14,
    BookCanceledOrder = 15,
    OrderTypeNotSupported = 16,
    UserNotFound = 17,
    InvalidAuth = 18,
    LockedAccount = 19,
    TokenNotProvided = 20,
    InvalidToken = 21,
    ExpiredToken = 22,
    InvalidInvitationLink = 30,
    InvalidResetPasswordCode = 37
}