
import TagManager from 'react-gtm-module';
import { GtmOrderTypeDescription, IOrder, IRefundEvent } from '../core';

const gtmId: string = process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : "";


export const initializeGtmTagManager = (): void => {
    const tagManagerArgs = {
        gtmId: gtmId
    }
    
    TagManager.initialize(tagManagerArgs)
}

const pushData = (data: {event: string, ecommerce: IRefundEvent}): void => TagManager.dataLayer({ dataLayer: data });

export const fireRefundEvent = (order: IOrder) : void => {

    const refundData = prepareRefundEventData(order);
    pushData({ event: "refund", ecommerce: refundData }, );
}

 const prepareRefundEventData = (order: IOrder) : IRefundEvent  => {
    return {
        currency: "USD",
        transaction_id: order.OrderCode,
        value: order.Amount,
        tax: 0.00,
        shipping: 0.00,
        coupon: "",
            items: [{
            item_id: GtmOrderTypeDescription[order.OrderTypeId], 
            item_brand: order.Location?.Name, 
            item_name: "Booking", // booking or appointment
            item_category: order.Location?.City ?? "",
            item_category2: order.Location?.State ?? "",
            item_category3: "",
            item_variant: "",
            item_list_id: "", 
            item_list_name: "", 
            affiliation: "",
            coupon: "",
            discount: 0.00,
            price: order.Amount,
            quantity: 1
            }]
    }
}




