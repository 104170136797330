import React from 'react';
import { scoreToQuality } from '../../utils/scoreToQuality'
import { Row, Col } from 'react-bootstrap';
import { useGetOrderFingerprintsImageQuery } from '../../services';
import { ScoreBar, ThumbnailImage } from '..';
import { FingerprintPositionFPIDescription, FingerprintTypeDescription, OrderFingerPrint } from '../../core';
import './qualityScoreItem.scss';
import redCross from '../../../assets/images/red_cross.png';

const QualityScoreItem = ({ orderId, fingerprint }: {orderId: number; fingerprint: OrderFingerPrint}) => {
    const qualityScore = scoreToQuality(fingerprint);

    const { data: fingerprintImage, isLoading, isFetching } = useGetOrderFingerprintsImageQuery({ id: orderId, imageName: fingerprint.ImagePath! }, { skip: !fingerprint.ImagePath });

    return (
        <>
            <Row className="quality-score-item">
                <Col sm={6} className="row">
                    <Col sm={2} className="quality-score-item-image">
                        {fingerprint.ImagePath ? (
                            <ThumbnailImage isLoading={isLoading || isFetching} image={fingerprintImage} />
                        ) : (
                            <div className='d-flex justify-content-center align-items-center h-100'>
                                <img width={25} height={25} src={redCross} alt="No fingerprint" />
                            </div>
                        )}
                    </Col>    
                    <Col sm={10} className="quality-score-item-position">{FingerprintTypeDescription[fingerprint.Type]} {FingerprintPositionFPIDescription[fingerprint.Position]}</Col>
                </Col>
                <Col sm={2} className="quality-score-item-score">{qualityScore.score}</Col>
                <Col sm={4} className="quality-score-item-quality row">
                    {qualityScore.quality && (
                        <>
                            <Col sm={10}><ScoreBar filledCount={qualityScore.filledCount} filledColor={qualityScore.filledColor} /></Col>
                            <Col sm={2}>{qualityScore.quality}</Col>
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default QualityScoreItem;
