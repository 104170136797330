import { initializeApp } from "firebase/app";
import {  UserCredential, getAuth , signOut} from "firebase/auth"
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'


const firebaseConfig = {
    apiKey: `${ process.env.REACT_APP_FIREBASE_API_KEY ? process.env.REACT_APP_FIREBASE_API_KEY : "" }`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN : ""}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID ? process.env.REACT_APP_FIREBASE_PROJECT_ID : ""}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET : ""}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID : ""}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID ? process.env.REACT_APP_FIREBASE_APP_ID : ""}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
export const windowRef: any = window

export const initRecaptcha = ()=>{
    const recaptcha=  new RecaptchaVerifier(auth, 'recaptcha-container', {
       size: 'invisible', callback: ()=>{
       },    
   })
   windowRef.RecaptchaVerifier = recaptcha
}
export const sendOtp = (phoneNumber: string, recaptcha: RecaptchaVerifier) : Promise<ConfirmationResult> => {
    return signInWithPhoneNumber(auth, phoneNumber, recaptcha)
}

export const verifySmsOtp = async (otp: string, confirmationSmsResult?: ConfirmationResult) : Promise<UserCredential | undefined> => {
    return confirmationSmsResult?.confirm(otp)
  }

export const firebaseSignOut = ()=>{
    signOut(auth)
}