import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {SendOtp, Timer} from '..'
import { useFormContext } from 'react-hook-form'
import { validationRules } from '../../utils/formValidator'
import { TwoFactorAuthMethod } from '../../core'
import { ConfirmationResult } from 'firebase/auth'

interface LoginCodeProps {
  currentMethod: TwoFactorAuthMethod,
  time: number,
  setTime: (value: number) => void,
  restartTime: boolean,
  phoneNumber: string,
  setRestartTime: (value: boolean) => void,
  setConfirmationResult?: (value: ConfirmationResult) => void

}
const VerifyLoginCode = ({ currentMethod, time, restartTime, phoneNumber, setRestartTime, setTime, setConfirmationResult }: LoginCodeProps) => {

  const { register, clearErrors, formState } = useFormContext()

  const verifyValidationRules = validationRules<{ otp: string }>({
    otp: { required: true, pattern: 'digitsString', displayName: "otp", maxLength: 6, minLength: 6 }
  });
  return (
    <>
      <Row>
        <Col md={5}>
          <Form.Control placeholder='Login code' className="verify-input-control" {...register('otp', verifyValidationRules['otp'])} isInvalid={!!formState.errors.otp} type="text"
            onChange={() => {
              clearErrors('otp')
            }} />
        </Col>
        {currentMethod === TwoFactorAuthMethod.SMS && <>
          <Col className='resend-code-container' md={6} > 
          <SendOtp setConfirmationResult={setConfirmationResult} setRestartTimer={setRestartTime} time={time} setTime={setTime} title='Resend Code' isCodeResent={false} phoneNumber={phoneNumber} /> 
           <span> <Timer time={time} setTime={setTime} restart={restartTime} setRestart={setRestartTime} /> </span> 
           </Col>
        </>}
      </Row>
      <Form.Text>
        {formState.errors.otp && <span className='error-msg'>{formState.errors.otp.message}</span>}
      </Form.Text>
    </>
  )
}

export default VerifyLoginCode