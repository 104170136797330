import { Navbar, Container, Offcanvas, Button, Accordion, Stack } from 'react-bootstrap'
import { Navigate } from 'react-router-dom';
import { useAppSelector, useCurrentLocation } from '../../hooks';
import { useLogoutMutation } from '../../services';
import { userSelector } from '../../store/slices/userSlice';
import { NavSidemenu } from '..';
import { tabs } from "../../utils/tabs";
import './ResponsiveSidemenu.scss'
import React from 'react';
import _ from 'lodash';

const ResponsiveSidemenu = () => {
    const user = useAppSelector(userSelector);
    const [logout, { error, data }] = useLogoutMutation();
    const handleLogout = () => logout();
    const currrentLocation = useCurrentLocation();
    // To get the Navbar title depending on current location

    const tabsArray = tabs;
    const { route } = currrentLocation;

    const defaultTitle = _.find(tabsArray, t => (t.name === route))?.text ?? "";
    const [titleValue, setTitle] = React.useState<string>(defaultTitle);

    const onChangeHandler = (title: string) => {
        setTitle(title);
    }

    return (
        <>
            {(data || error) && <Navigate to="/" replace={true} />}
            <Navbar bg="light" expand={'lg'} >
                <Container fluid >
                    <Navbar.Toggle aria-controls="offcanvasNavbar" />
                    <div className='page-title'>{titleValue}</div>
                    <Navbar.Offcanvas className="pull-left"
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Stack gap={3}>
                                <Accordion className='user-accordion'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{user.me?.FirstName} {user.me?.LastName}</Accordion.Header>
                                        <Accordion.Body>
                                            <Button variant="outline-secondary" onClick={handleLogout}>Logout</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <NavSidemenu onChange={onChangeHandler} />
                            </Stack>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default ResponsiveSidemenu