import { IAuth, IToken, TwoFactorAuthMethod } from "../core";

const TOKEN_STORAGE_KEY = "access_token"
const TOKEN_DATE_STORAGE_KEY = "access_token_date";
const REFRESH_TOKEN_STORAGE_KEY = "refresh_token";
const REFRESH_TOKEN_DATE_STORAGE_KEY = "refresh_token_date";
const IS_OTP_VERIFIED = 'is_otp_verified'
const TWO_FACTOR_AUTH_METHOD_ARRAY = "two-factor-auth-method-array";
const PHONE_NUMBER = "phone_number";
const TOKEN_EXPIRY_MINUTES = 50;
const OTP_VERIFIED = "verified";
const TIMER_START_TIME= 'timer_start_time'
const STORAGE = localStorage;
const AUTH_REFRESH_TOKEN_EXPIRY_HOURS = 24;


export const saveAuthToStorage = (auth: IAuth | null) => {
    if (auth) {
        saveTokenToStorage(auth.token)
        auth.isOtpVerified && STORAGE.setItem(IS_OTP_VERIFIED, OTP_VERIFIED)
        auth.twoFactorAuthMethods && STORAGE.setItem(TWO_FACTOR_AUTH_METHOD_ARRAY, JSON.stringify(auth.twoFactorAuthMethods));
        auth.phoneNumber && savePhoneNumberToStorage(auth.phoneNumber);
        STORAGE.setItem(REFRESH_TOKEN_DATE_STORAGE_KEY, Date.now().toString());
    } else {
        STORAGE.removeItem(TOKEN_DATE_STORAGE_KEY);
        STORAGE.removeItem(TOKEN_STORAGE_KEY);
        STORAGE.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        STORAGE.removeItem(IS_OTP_VERIFIED);
        STORAGE.removeItem(TWO_FACTOR_AUTH_METHOD_ARRAY);
        STORAGE.removeItem(PHONE_NUMBER);
        STORAGE.removeItem(REFRESH_TOKEN_DATE_STORAGE_KEY);

    }
}
//update token while auth and refresh
export const saveTokenToStorage = (token: IToken | null) => {
    if (token) {
        STORAGE.setItem(TOKEN_DATE_STORAGE_KEY, Date.now().toString());
        STORAGE.setItem(TOKEN_STORAGE_KEY, token.accessToken);
        STORAGE.setItem(REFRESH_TOKEN_STORAGE_KEY, token.refreshToken);
      
    } else {
        STORAGE.removeItem(TOKEN_DATE_STORAGE_KEY);
        STORAGE.removeItem(TOKEN_STORAGE_KEY);
        STORAGE.removeItem(REFRESH_TOKEN_STORAGE_KEY);
       
    }
}
export const getAuthFromStorage = (): IAuth | null => {
    const accessToken = STORAGE.getItem(TOKEN_STORAGE_KEY);
    const refreshToken = STORAGE.getItem(REFRESH_TOKEN_STORAGE_KEY);
    const isOtpVerified = STORAGE.getItem(IS_OTP_VERIFIED) === OTP_VERIFIED ? true : false;
    const twoFactorAuthMethods = JSON.parse(STORAGE.getItem(TWO_FACTOR_AUTH_METHOD_ARRAY) || "[]")
    const phoneNumber = STORAGE.getItem(PHONE_NUMBER);
    return (accessToken && refreshToken ) ?
     { token: {accessToken, refreshToken} as IToken, isOtpVerified, twoFactorAuthMethods, phoneNumber: phoneNumber ? phoneNumber : "" } : null;
};

export const isAccessTokenExpired = () : boolean =>{
    const createdTime = tokenCreatedTime(TOKEN_DATE_STORAGE_KEY);
    if (!createdTime) { return true; }
    const elapsedMinutes = Math.floor((((Date.now() - createdTime) % 86400000) % 3600000) / 60000);
    return elapsedMinutes >= TOKEN_EXPIRY_MINUTES

}

export const isAuthRefreshTokenExpired = () : boolean =>{
    const createdTime = tokenCreatedTime(TOKEN_DATE_STORAGE_KEY);
    if (!createdTime) { return true; }
    const elapsedHours = Math.floor(((Date.now() - createdTime) / 3600000) );
    return elapsedHours >= AUTH_REFRESH_TOKEN_EXPIRY_HOURS;
}

const tokenCreatedTime = (key: string): number | null => {
    const timeString = STORAGE.getItem(key);
    const time = timeString ? Number(timeString) : NaN;
    return Number.isNaN(time) ? null : time;
}
export const updateTwoFactorAuthMethodInStorage = (authMethod: TwoFactorAuthMethod) => {
    let currentMethods = JSON.parse(STORAGE.getItem(TWO_FACTOR_AUTH_METHOD_ARRAY) || "[]");
    currentMethods.push(authMethod);
    localStorage.setItem(TWO_FACTOR_AUTH_METHOD_ARRAY, JSON.stringify(currentMethods))
}
export const saveTimerStartTime = ()=>{
    const time = new Date().getTime().toString()
    STORAGE.setItem(TIMER_START_TIME,  time);

}
export const getTimerStartTime =()=>{
   const time =  STORAGE.getItem(TIMER_START_TIME) ? Number(STORAGE.getItem(TIMER_START_TIME)) : NaN ;
   return Number.isNaN(time) ? null : time
}

export const hasTwoFactorAuthMethodsStored = ()  : boolean =>{
    return STORAGE.getItem(TWO_FACTOR_AUTH_METHOD_ARRAY) ? true : false;
}
export const savePhoneNumberToStorage = (phoneNumber: string|null): void =>{
   if(phoneNumber){
    STORAGE.setItem(PHONE_NUMBER, phoneNumber);
   } else{
    STORAGE.removeItem(PHONE_NUMBER);
   }

}
