import React from 'react'
import { Modal } from '..'
import { IRestResponse } from '../../core'
import { useAppDispatch } from '../../hooks';
import { notificationActions } from '../../store/slices/notificationSlice';

interface IModalProps {
    btnTitle: string,
    title: string,
    isSuccess: boolean,
    successMessage: string 
    response?: IRestResponse<boolean> ,
    isDisabledBtn?: boolean,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
    onSuccess : ()=> void,
    onSave: () => void,
    children: JSX.Element,
}
const ConfirmationDialog = ({ btnTitle, title, isSuccess, response, successMessage, isDisabledBtn, showModal, setShowModal, onSuccess, onSave, children }: IModalProps) => {
    const dispatch = useAppDispatch();
 
    const handleConfirmModal = () => {
        onSave()
    }
    const closeModal = () => setShowModal(false);

    React.useEffect(() => {
        if (response?.Data) {
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: successMessage,
                title: 'Success!'
            }))
            closeModal();
        }
        // eslint-disable-next-line
    }, [response])
    //refetch after order cancelation
    React.useEffect(() => {
        if (isSuccess) {
            onSuccess();
        } // eslint-disable-next-line
    }, [isSuccess]);
   
    return (
        <>

           <>

                <Modal show={showModal} saveBtnTitle={btnTitle} title={title}
                    onSave={handleConfirmModal} onClose={closeModal} isDisabledBtn={isDisabledBtn}>
                    <div>{children}</div>
                </Modal >
            </>
        </>
    )
}

export default ConfirmationDialog