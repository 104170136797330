import React from 'react'
import { RefundDialog, Loader } from '..'
import { IOrder, IRefundOrderRequest, PaymentType } from '../../core'
import { useRefundOrderMutation } from '../../services';
import { ConfirmRefundOrderDialogMessage, RefundOrderSuccessMessage } from '../../utils/confirmationMessage';
import { Button } from 'react-bootstrap';
import { fireRefundEvent } from '../../utils/gtm';

const RefundOrder = ({order, onSuccess}: {order: IOrder, onSuccess: () => void}) => {

  const [refundOrder, { data: refundOrderResponse, isSuccess: isRefunded, isLoading: refundOrderLoading }] = useRefundOrderMutation();
  const [showModal, setShowModal] =React.useState<boolean>(false);

  const initModal = () => setShowModal(true);

  const handleRefundOrder = (refundOrderRequest: IRefundOrderRequest) => {
    refundOrder(refundOrderRequest);
  }

  React.useEffect(()=>{
    if(refundOrderResponse?.Data && order.paymentType === PaymentType.onApplicant){
      fireRefundEvent(order);
    }
    // eslint-disable-next-line
  }, [refundOrderResponse]);
  return (
    <>
      {refundOrderLoading && <Loader />}
      <Button className='refund-btn' size='lg' onClick={initModal} > Refund </Button>
      <RefundDialog
        orderId={order.Id}
        confirmMessage={ConfirmRefundOrderDialogMessage()}
        onRefund={handleRefundOrder}
        title={"Refund Order"}
        btnTitle={"Refund"}
        showModal={showModal}
        setShowModal={setShowModal}
        isSuccess={isRefunded}
        response={refundOrderResponse}
        successMessage={RefundOrderSuccessMessage(order.ReferenceId)}
        onSuccess={onSuccess}
      />
    </>
  )
}

export default RefundOrder