import { OrderType } from "../core"

enum ResubmitSettings {
    MaxSterlingSubmitAttempts = 2,
}

export const useCanResubmit  = ( SterlingAttemptsCount: number , orderTypeId: number , FingerprintsCount: number|undefined  , isCompleted: boolean) :boolean => {
    if(SterlingAttemptsCount < ResubmitSettings.MaxSterlingSubmitAttempts && (orderTypeId === OrderType.FBI || orderTypeId === OrderType.CA || orderTypeId === OrderType.PRINTTOCARD) && isCompleted && FingerprintsCount) {
        return true;
    }
    return false
}
