import React from 'react';
import './scoreBar.scss';

const ScoreBar = ({ count = 5, filledCount, filledColor }: { count?: number, filledCount: number, filledColor: string }) => {
    const filledStyle = {
        backgroundColor: filledColor,
        borderColor: filledColor,
    };

    return (
        <>
            <div className="score-bar-container">
            {
                Array(...Array(count)).map((_, index) => {
                    return <div className="score-bar" style={index < filledCount ? filledStyle : {}} key={index} />
                })
            }
            </div>
        </>
    )
}

export default ScoreBar;
