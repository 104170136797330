import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ShortenDetail = ({detail, maxLength}: {detail: string | undefined, maxLength: number}) => {
  return (
    <>
      {detail?.length! <= maxLength ? (
        <div>{detail}</div>
      ) : (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip className='tracking-numbers-tooltip'>{detail}</Tooltip>
          }
        ><div>{_.truncate(detail, { 'length': maxLength, 'separator': ' ' })}</div>
        </OverlayTrigger>
      )}
    </>
  );
}

export default ShortenDetail;
