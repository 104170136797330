import { Toast, ToastContainer } from 'react-bootstrap/';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { notificationActions, notificationSelector } from '../../../store/slices/notificationSlice';
import * as React from "react";
import _ from 'lodash';
import './notification.scss';
const Notification = () => {
    const notification = useAppSelector(notificationSelector);
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(notificationActions.reset());
    }
    React.useEffect(() => {
        //handle auto hide
        if (notification.autoHide) {
            (_.debounce((() => dispatch(notificationActions.reset())), 4000))()
        }
    });

    return (
        <ToastContainer position="top-end" className="p-3 toast-container">
            <Toast show={notification.view} bg={notification.state} animation={true} onClose={handleClose}>
                <Toast.Header>
                    <strong className="me-auto">{notification.title}</strong>
                </Toast.Header>
                <Toast.Body>
                    <p>{notification.text}</p>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default Notification;
