import { OverlayTrigger, Stack,Button, Tooltip } from 'react-bootstrap'
import { CardTypeDescription, CreditCardType, IOrder, IOrderShipment, IOrderStatusLog, OrderStatus, OrderType, PaymentType, ResubmissionTypeDescription, RetrialPolicy,EditType } from '../../core'
import { formatDateTime, formatDateUTC } from '../../utils/dateTime'
import './orderDetails.scss'
import { useIsCompletedOrder, useCanResubmit, useIsPassThrough, useCanRefundOrder, useIsCompletedResubmittedPassThrough, useIsCompletedPassThroughNoFingerprints,useCanEditOrder } from '../../hooks';
import { CancelOrder, QualityScore, Shipment, ResendEmail, ResubmitOrder, RefundOrder, OrderLocation, DownloadFingerprint, RequestOrderFingerprints, ShortenDetail,EditOrder} from '..';
import React from 'react';
import { useResendConfirmationEmailMutation, useResendResubmittedEmailMutation, useResendTcnEmailMutation } from '../../services';
import { NoTcn } from '../../utils/errorMessage';
import _ from 'lodash';



const OrderDetails = ({ order, setIsShipped, refresh }: { order: IOrder, setIsShipped: (isShipped: boolean) => void, refresh: () => void }) => {

    const [resendEmail, { data: resendEmailResponse, isLoading: resendEmailLoading }] = useResendConfirmationEmailMutation();
    const [resendResubmittedEmail, { data: resendResubmittedEmailResponse, isLoading: resendResubmittedEmailLoading }] = useResendResubmittedEmailMutation();
    const [resendTcnEmail, { data: resendTcnEmailResponse, isLoading: resendTcnEmailLoading }] = useResendTcnEmailMutation();
    const refreshOrdersCallback = React.useCallback(() => refresh(), [refresh])

    const isCompleted = useIsCompletedOrder(order.StatusId);
    const [orderShipment, setOrderShipment] = React.useState<IOrderShipment>(order.OrderShipments?.[0]);
    const [trackingNumbersWithShipDates, setTrackingNumbersWithShipDates] = React.useState<string>(_.map(order.OrderShipments, orderShipment => `${orderShipment.TrackingNumber}-${formatDateTime(orderShipment.ShipDate, 'MM/DD/YYYY')}`).join('\n'))
    const [latestTrackingNumber, setLatestTrackingNumber] = React.useState<string>(order.OrderShipments[0]?.TrackingNumber)
    const canResubmit = useCanResubmit(order.SterlingAttemptsCount, order.OrderTypeId, order.OrderFingerPrints?.length, isCompleted);
    const isPassThrough = useIsPassThrough(order.OrderTypeId);
    const canRefundOrder = useCanRefundOrder(order.StatusId, order.paymentType)
    const isCompletedResubmittedPassThrough = useIsCompletedResubmittedPassThrough(order.OrderTypeId, order.StatusId);
    const isCompletedPassThroughNoFingerprints = useIsCompletedPassThroughNoFingerprints(order.OrderTypeId, order.StatusId, order.OrderFingerPrints?.length);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [editType, setEditType] = React.useState<EditType>('email');
    const canEditOrder = useCanEditOrder(order.StatusId,order.ClearedAt);
    
    const handleEditEmail = () => {
        setEditType('email');
        setShowEditModal(true);
    };

    const handleEditAddress = () => {
        setEditType('address');
        setShowEditModal(true);
    };
    
    const setOrderShipmentValue = (newOrderShipment: IOrderShipment) => {    
        setTrackingNumbersWithShipDatesFormat(newOrderShipment);
        setLatestTrackingNumber(`${newOrderShipment.TrackingNumber}`);
        setOrderShipment(newOrderShipment);
    }

    const setTrackingNumbersWithShipDatesFormat = (newOrderShipment: IOrderShipment) => {
        let oldTrackingNumbers = orderShipment !== undefined ? `${trackingNumbersWithShipDates}` : ``;
        let newOrderShipmentTrackingNumberWithShipDate = `${newOrderShipment.TrackingNumber}-${formatDateTime(newOrderShipment.ShipDate, 'MM/DD/YYYY')}`;
        setTrackingNumbersWithShipDates(`${newOrderShipmentTrackingNumberWithShipDate}\n${oldTrackingNumbers}`);
    }

    let resubmissionInfo: IOrderStatusLog = {};
    let cancelationInfo: IOrderStatusLog = {};
    let refundInfo: IOrderStatusLog = {};
    _.forEach(order.OrderStatusLogs, statusLog => {

        if (statusLog.StatusId === OrderStatus.CANCELED) {
            cancelationInfo = statusLog
        }
        if (statusLog.StatusId === OrderStatus.RESUBMITTED) {
            resubmissionInfo = statusLog
        }
        if (statusLog.StatusId === OrderStatus.COMPLETED_REFUNDED) {
            refundInfo = statusLog;
        }
    })



    return (
        <>
            <Stack gap={3}>
                <div className='order-details-title'>Order Details</div>
                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Order Type:</div>
                        <div>{OrderType[order.OrderTypeId]}</div>
                        <div className='label'>Applicant name:</div>
                        <div>{order.FirstName} {order.LastName}</div>
                    </Stack>
                </div>
                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Creation Date:</div>
                        <div>{order.createdAt ? formatDateTime(order.createdAt) : ''}</div>
                        <div className='label'>Email:</div>
                        <div className="edit">{order.Email}{canEditOrder && <Button className="edit-btn" onClick={handleEditEmail}><i className="fa fa-pencil pencil-icon-color"></i> </Button>}</div>
                       
                    </Stack>
                </div>
                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Order Registration Code:</div>
                        <div>{order.OrderCode}</div>
                        <div className='label'>Phone Number:</div>
                        <div>{order.PhoneNumber ? order.PhoneNumber : 'N/A'}</div>
                    </Stack>
                </div>
                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Refrence ID:</div>
                        <div>{order.ReferenceId}</div>

                    </Stack>
                </div>
                <div>

                    <Stack direction="horizontal" className='order-details-row'>
                        {order.OrderTypeId !== OrderType.FBI && <>
                            <div className='label'>ORI:</div>
                            <div>{order.Ori}</div>
                        </>
                        }
                        {isCompleted && (order.OrderTypeId === OrderType.FDLE) && <>
                            <div className='label'>TCN:</div>
                            <div>{order.Tcn ?? NoTcn()}</div>
                        </>}
                    </Stack>

                </div>

                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Address:</div>
                        <div className="edit">
                            {`${order.Address1 ?? ''} ${order.OrderTypeId !== OrderType.CA ? `${order.Address2 ?? ''} ` : ''}${order.City ?? ''} ${order.State ?? ''} ${order.Zip ?? ''} ${order.Country ?? ''}`}
                       {canEditOrder && <Button className="edit-btn" onClick={handleEditAddress}><i className="fa fa-pencil pencil-icon-color"></i></Button>} </div>
                        
                        <div className='label'>Date Of Birth:</div>
                        <div>{order.DateOfBirth ? formatDateUTC(order.DateOfBirth) : "N/A"}</div>
                    </Stack>
                </div>

                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>SSN:</div>
                        <div>{(order.SocialSecurityNumber && order.SocialSecurityNumber !== "") ? order.SocialSecurityNumber : 'N/A'} </div>
                        <div className='label'>Location:</div>
                        <div>
                            <OrderLocation orderId={order.Id} location={order.Location} externalLocationId={order.ExternalLocationId} />
                        </div>
                    </Stack>
                </div>
                {(order.OrderTypeId === OrderType.PRINTTOCARD && order.paymentType === PaymentType.onApplicant) &&
                    <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Additional Copies:</div>
                                <div>{order.AdditionalCopies}</div>
                                <div className='label'>Fee Per Copy:</div>
                                <div>$ {order.FeePerCopy}</div>
                            </Stack>
                        </div>
                    </>}
                {
                    order.OrderTypeId === OrderType.CA && <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Authorized applicant type : </div>
                                <div>{order.OrderAgency?.AuthApplicantType}</div>
                                <div className='label'>Billing Number :</div>
                                <div> {order.OrderAgency?.BillingNumber ? order.OrderAgency?.BillingNumber : 'N/A'}</div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>OCA : </div>
                                <div>{order.Oca ? order.Oca : 'N/A'}</div>
                                <div className='label'>Level of Service :</div>
                                <div>{order.OrderAgency?.DojLevelOfService && order.OrderAgency?.FbiLevelOfService ? 'DOJ and FBI' : order.OrderAgency?.DojLevelOfService ? 'Doj only' : 'Fbi only'} </div>
                            </Stack>

                        </div>
                    </>}
                {
                    isPassThrough && orderShipment && <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'> {order.OrderTypeId === OrderType.FBI ? "Access Code: " : "Tracking Number: "} </div>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip className='tracking-numbers-tooltip'>
                                            {trackingNumbersWithShipDates}
                                        </Tooltip>
                                    }
                                ><div>{latestTrackingNumber}</div>
                                </OverlayTrigger>

                                <div className='label'>{order.OrderTypeId === OrderType.FBI ? "Recorded Date: " : "Shipment Date: "}</div>
                                <div>{formatDateTime(orderShipment.ShipDate, 'MM/DD/YYYY')}</div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Notes:</div>
                                <div>{orderShipment.Notes}</div>
                            </Stack>
                        </div>
                    </>
                }
                {order.StatusId === OrderStatus.CANCELED && cancelationInfo &&
                    <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Canceled By:</div>
                                <div>{cancelationInfo?.user?.FirstName} {cancelationInfo?.user?.LastName} </div>
                                <div className='label'>Canceled On:</div>
                                <div>{cancelationInfo?.createdAt && formatDateTime(cancelationInfo.createdAt)}  </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Cancel Note:</div>
                                <ShortenDetail detail={cancelationInfo?.Description} maxLength={24} />
                            </Stack>
                        </div>
                    </>
                }
                {
                    order.StatusId === OrderStatus.UNDER_RETRIAL && <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Last Retrial</div>
                                <div>{order.OrderRetrial ? formatDateTime(order.OrderRetrial?.RetriedAt) : ""}</div>
                                <div className='label'>Reason of retrial:</div>
                                <div>{order.OrderRetrial?.LastErrorMessage ?? ""}</div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Retrials</div>
                                <div>{`${order.OrderRetrial?.NumOfRetrials ?? 0} of ${RetrialPolicy.maxRetrials}`}</div>
                            </Stack>
                        </div>
                    </>
                }

                {(Object.keys(resubmissionInfo).length !== 0) && <>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Resubmission Status:</div>
                        <div>{resubmissionInfo.StatusId === order.StatusId ? "In Progress" : "Resubmitted"}</div>
                        <div className='label'>Resubmitted On:</div>
                        <div>{resubmissionInfo.createdAt ? formatDateTime(resubmissionInfo.createdAt) : ''}</div>

                    </Stack>
                    <div>
                        <Stack direction="horizontal" className='order-details-row'>
                            <div className='label'>Resubmitted By:</div>
                            <div>{resubmissionInfo?.user?.FirstName} {resubmissionInfo?.user?.LastName}
                            </div>
                            {(order.OrderTypeId === OrderType.CA) &&
                                <>
                                    <div className='label'>OATI Number:  </div>
                                    <div>{order.OrderResubmission?.OAti ? order.OrderResubmission.OAti : "N/A"}</div>
                                </>
                            }

                        </Stack>
                    </div>
                    {(order.OrderTypeId === OrderType.CA) && <div>
                        <Stack direction="horizontal" className='order-details-row'>
                            <div className='label'>Order Resubmission Type: </div>
                            <div>{order.OrderResubmission?.ResubmissionTypeId && ResubmissionTypeDescription[order.OrderResubmission.ResubmissionTypeId]}</div>
                        </Stack>
                    </div>
                    }
                </>
                }
                {order.StatusId === OrderStatus.COMPLETED_REFUNDED && refundInfo &&
                    <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Refunded By:</div>
                                <div>{refundInfo?.user?.FirstName} {refundInfo?.user?.LastName} </div>
                                <div className='label'>Refunded On:</div>
                                <div>{refundInfo?.createdAt && formatDateTime(refundInfo.createdAt)}  </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Refund Note:</div>
                                <ShortenDetail detail={refundInfo?.Description} maxLength={24} />
                            </Stack>
                        </div>
                    </>
                }
                {
                    order.ClearedAt &&
                    <>
                        <div>
                            <Stack direction="horizontal" className='order-details-row'>
                                <div className='label'>Order data has been cleared on:</div>
                                <div>{formatDateTime(order.ClearedAt, "MM/DD/YYYY")}  </div>
                            </Stack>
                        </div>

                    </>
                }


                <div><hr className='line'></hr></div>
                <div>
                    <Stack direction="horizontal" className='order-details-row'>
                        <div className='label'>Total Fees:</div>
                        <div>$ {order.Amount}</div>
                        <div className='label'>Payment Info:</div>
                        {order.paymentType === PaymentType.onApplicant ?
                            <>
                                {(order.CardType && order.CardNumber) && <div>
                                    <img src={CardTypeDescription[order.CardType]?.icon} alt='card icon' width="35px"></img>
                                    <span>
                                        {` ${CardTypeDescription[order.CardType]?.discription} ending in ${order.CardNumber?.slice(-4)}`}
                                    </span>
                                </div>}

                                {(!order.CardType || !order.CardNumber) && <div><img src={CardTypeDescription[CreditCardType.OTHER]?.icon} alt='card icon' width="35px"></img></div>}
                            </> :
                            <div> On-Account {order.CbsAccount?.SerialNumber ?? ''} <small className='text-danger'>{order.CbsAccount?.deletedAt ? ' Deleted Account' : ''}</small> </div>
                        }
                    </Stack>
                </div>
                <Stack gap={3} direction='horizontal' className='actions'>
                    {(order.StatusId === OrderStatus.NEW && !order.IsRefunded) && <>
                        <ResendEmail resendBtnTitle='Resend Confirmation Email' btnTitle="Send Email" title="Confirm Email" handleAction={resendEmail}
                            isLoading={resendEmailLoading} order={order} response={resendEmailResponse} />
                        <CancelOrder order={order} onSuccess={refreshOrdersCallback} />
                    </>}

                    {order.ClearedAt === null &&
                        <>
                            {(isCompletedResubmittedPassThrough) &&
                                <DownloadFingerprint orderId={order.Id} orderTypeId={order.OrderTypeId} firstName={order.FirstName} lastName={order.LastName} isDimmed={!order.OrderFingerPrints?.length} />
                            }
                            {canResubmit &&
                                <ResubmitOrder order={order} refreshCallback={refreshOrdersCallback} setIsShipped={setIsShipped} />
                            }
                            {(isCompleted) && <>
                                {order.Tcn && <ResendEmail resendBtnTitle='Resend TCN Email' btnTitle="Send" title="Confirm Email" handleAction={resendTcnEmail}
                                    isLoading={resendTcnEmailLoading} order={order} response={resendTcnEmailResponse} />}
                            </>}
                            {(isCompletedResubmittedPassThrough) && (
                                <Shipment orderId={order.Id} orderTypeId={order.OrderTypeId} setOrderShipment={setOrderShipmentValue} setIsShipped={setIsShipped} isDimmed={!order.OrderFingerPrints?.length} />
                            )}
                            {(isPassThrough && order.StatusId === OrderStatus.RESUBMITTED) && <ResendEmail order={order} resendBtnTitle='Resend Resubmission Email' btnTitle="Send" title="Confirm Email" handleAction={resendResubmittedEmail}
                                isLoading={resendResubmittedEmailLoading} response={resendResubmittedEmailResponse} />}

                            {canRefundOrder && <RefundOrder order={order} onSuccess={refreshOrdersCallback} />}

                            {(isCompletedPassThroughNoFingerprints) && (
                                <RequestOrderFingerprints orderId={order.Id} orderCode={order.OrderCode} onSuccess={refreshOrdersCallback} />
                             )}
                            {(isCompletedResubmittedPassThrough) && (
                                <QualityScore orderId={order.Id} orderFingerprints={order.OrderFingerPrints} />
                            )}
                        </>
                    }

                </Stack>
               
                <EditOrder
                    order={order}
                    showModal={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    onSuccess={() => refresh()}
                    editType={editType}
                />
            </Stack>
        </>)
}


export default OrderDetails;
