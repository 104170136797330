export const GeneralError = 'An error occured, please try again later.';

// Validation Errors
export const RequiredErrorMessage = (displayName: string) => `${displayName} is required.`;
export const MinLengthErrorMessage = (displayName: string, minLength: number) => `${displayName} should not be less than ${minLength} characters.`;
export const MaxLengthErrorMessage = (displayName: string, maxLength: number) => `${displayName} should not be more than ${maxLength} characters.`;
export const PatternErrorMessage = (displayName: string) => `${displayName} is invalid.`;
export const passwordErrorMessage = () => `Password must contain at least one uppercase letter, one lowercase letter, one special character @$!%*_?&- and one digit.`
export const confirmPasswordErrorMessage = () => `Passwords must match`;
export const SearchPatternErrorMessage = () => `Invalid input.`;
export const charSpaceDashErrorMessage = () => `Only characters space and dashes are allowed.`;
export const alphanumericErrorMessage = () => `Only characters and numeric values are allowed.`;
export const alphaNumericAndMoreSpecialErrorMessage = (field: string, specialCharacters: string) => `Invalid ${field}, Only Alphanumeric and special char ${specialCharacters} and spaces`;
export const numericErrorMessage = () => `Only Integer values are allowed.`;
export const alphaCharactersDashs = () => `Only characters and dashes are allowed.`;
export const oAtiErrorMessage = () => `Please enter a string starting with a letter, followed by three digits, three letters, and ending with three digits.`;
export const minValueErrorMessage = (displayName: string, minLength: number) => `${displayName} should not be less than ${minLength}.`;
export const maxValueErrorMessage = (displayName: string, maxLength: number) => `${displayName} should not be more than ${maxLength}.`;
export const digitsStringErrorMessage = () => `Please enter digits only`;
export const invalidPhoneNumberFormat = () =>`Please enter a valid phone number starting with + and country code`
export const alphaNumericAndSpaceMessage = (displayName: string) => `${displayName} must be alphanumeric with space, dash, underscore, slash, comma, period and parenthesis.`;
export const fileErrorMessage = () => `Invalid image Format or size `;
export const alphaNumericSpaceAndDashErrorMessage = () => `Only alphanumeric with spaces or dashes are allowed.`
export const exactLengthErrorMessage = (displayName : string, exactLength?: number) => `${displayName} must be ${exactLength} characters`;



//errors
export const NoOrdersRecord = () => `No orders found`;
export const NoTcn = () => `Not available`;
export const NoUsersRecord = () => `No users found`;
export const InvalidLink = () => `Invalid invitation link`;
export const FingerprintsNotAvailable = () => `Fingerprints Not yet available`;
export const InvalidResetPasswordCode = () => `Expired reset password request, please submit a new request`;
export const NoLocationRecord = () => "No locations found.";
export const SmsVerificationFailed = () => "Otp verification failed."
export const SendOtpError = () => "Cannot send otp."
export const SmsActionModalNotImplemented = () => `This action isn't implemented.`;
export const FirebaseOtpExpired = () => `Your OTP code has expired. Please request a new one.`;
export const InvalidPhoneNumber = () => `Invalid Phone Number.`
export const rangeErrorMessage = () => `Please provide both Start Range and End Range.`;
export const rangeBoundaryErrorMessage = () => ` End range must be greater than or equal to the start range.`