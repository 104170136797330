export enum FingerprintPosition {
    RightThumb = 1,
    RightIndexFinger = 2,
    RightMiddleFinger = 3,
    RightRingFinger = 4,
    RightLittleFinger = 5,
    LeftThumb = 6,
    LeftIndexFinger = 7,
    LeftMiddleFinger = 8,
    LeftRingFinger = 9,
    LeftLittleFinger = 10,
    PlainRightThumb = 11,
    PlainLeftThumb = 12,
    PlainRightFourFingers = 13,
    PlainLeftFourFingers = 14,
    PlainThumbs = 15
}

export const FingerprintPositionFPIDescription: Record<FingerprintPosition, string> = {
    [FingerprintPosition.RightThumb]: 'Right thumb (FPI_01)',
    [FingerprintPosition.RightIndexFinger]: 'Right index finger (FPI_02)',
    [FingerprintPosition.RightMiddleFinger]: 'Right middle finger (FPI_03)',
    [FingerprintPosition.RightRingFinger]: 'Right ring finger (FPI_04)',
    [FingerprintPosition.RightLittleFinger]: 'Right little finger (FPI_05)',
    [FingerprintPosition.LeftThumb]: 'Left thumb (FPI_06)',
    [FingerprintPosition.LeftIndexFinger]: 'Left index finger (FPI_07)',
    [FingerprintPosition.LeftMiddleFinger]: 'Left middle finger (FPI_08)',
    [FingerprintPosition.LeftRingFinger]: 'Left ring finger (FPI_09)',
    [FingerprintPosition.LeftLittleFinger]: 'Left little finger (FPI_10)',
    [FingerprintPosition.PlainRightThumb]: 'Right thumb (FPI_11)',
    [FingerprintPosition.PlainLeftThumb]: 'Left thumb (FPI_12)',
    [FingerprintPosition.PlainRightFourFingers]: 'Right 4 fingers (FPI_13)',
    [FingerprintPosition.PlainLeftFourFingers]: 'Left 4 fingers (FPI_14)',
    [FingerprintPosition.PlainThumbs]: 'Two thumbs (FPI_15)'
}
