import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import "./serviceSetting.scss";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IUpdateServiceSetting, mapSettingsToServices } from "../../core";
import { useBulkUpdateServiceSettingMutation } from "../../services";
import { notificationActions } from "../../store/slices/notificationSlice";
import { useAppDispatch } from "../../hooks";
import { Loader, ServiceItem } from "..";

const EditServiceSetting = ({ services, onUpdate, onClose, onCancel, withCachedLogos, onSuccessLogos }: { services: IUpdateServiceSetting[], onUpdate: (value: IUpdateServiceSetting[]) => void, onClose: () => void, onCancel: (value: IUpdateServiceSetting[]) => void, withCachedLogos: boolean, onSuccessLogos: () => void}) => {
    const dispatch = useAppDispatch();
    const initialServices = _.clone(services);
    const methods = useForm<{ services: IUpdateServiceSetting[] }>({ mode: 'all', defaultValues: { services: initialServices } });
    const [updateServiceSetting, { data: updateServiceSettingResponse, isLoading: updateServiceSettingLoading }] = useBulkUpdateServiceSettingMutation();

    // handle drag and drop for sorting services
    const [draggingServices, setDraggingServices] = React.useState<IUpdateServiceSetting[]>(initialServices);
    const [draggingItem, setDraggingItem] = React.useState<IUpdateServiceSetting | null>(null);
    const [selectedImages, setSelectedImages] = React.useState<string[]>(_.fill(Array(services.length), ""));
    const [requestImages, setRequestImages] = React.useState<File[]>(_.fill(Array(services.length), "" as unknown as File));
    const handleDragStart = (e: any, item: IUpdateServiceSetting) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingItem(null);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any, targetItem: IUpdateServiceSetting) => {
        const items = draggingServices;
        const draggingItemTemp = draggingItem;
        if (!draggingItemTemp) return;

        const currentIndex = items.indexOf(draggingItemTemp);
        const targetIndex = items.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            [items[currentIndex], items[targetIndex]] = [items[targetIndex], items[currentIndex]];
            setDraggingServices(items);


            // //switch form values
            const tempId = methods.getValues().services.at(currentIndex)?.settingId;
            const tempTitle = methods.getValues().services.at(currentIndex)?.title;
            const tempDescription = methods.getValues().services.at(currentIndex)?.description;

            [selectedImages[currentIndex], selectedImages[targetIndex]] = [selectedImages[targetIndex], selectedImages[currentIndex]];
            [requestImages[currentIndex], requestImages[targetIndex]] = [requestImages[targetIndex], requestImages[currentIndex]];

            methods.setValue(`services.${currentIndex}.settingId`, methods.getValues().services.at(targetIndex)?.settingId ?? 0);
            methods.setValue(`services.${currentIndex}.title`, methods.getValues().services.at(targetIndex)?.title ?? "");
            methods.setValue(`services.${currentIndex}.description`, methods.getValues().services.at(targetIndex)?.description ?? "");

            methods.setValue(`services.${targetIndex}.settingId`, tempId ?? 0);
            methods.setValue(`services.${targetIndex}.title`, tempTitle ?? "");
            methods.setValue(`services.${targetIndex}.description`, tempDescription ?? "");
        }
    };

    //save or cancel
    const closeEdit = () => {
        methods.reset();
        setDraggingServices(initialServices);
        onCancel(initialServices);
        onClose();
    };

    React.useEffect(() => {
        if (updateServiceSettingResponse?.Data) {
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: "Services are updated",
                title: 'Success!'
            }));

            methods.reset();
            onClose();
            onUpdate(mapSettingsToServices(updateServiceSettingResponse.Data));
        }

        // eslint-disable-next-line 
    }, [updateServiceSettingResponse])

    const submitHandler = (data: any) => {
        const formData = new FormData();

        for (var i = 0; i < data.services.length; i++) {
            formData.append(`services[${i}][settingId]`, data.services[i].settingId);
            formData.append(`services[${i}][title]`, data.services[i].title);
            formData.append(`services[${i}][description]`, data.services[i].description);
            formData.append(`services[${i}][serviceOrder]`, data.services[i].serviceOrder);
            if (requestImages[i]) {
                formData.append(`services[${i}][logo]`, requestImages[i]);
            }
        }
        updateServiceSetting(formData);
    }

    return (<>
        {updateServiceSettingLoading && <Loader />}
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(submitHandler)} encType="multipart/form-data">
                <Stack gap={2}>
                    {
                        _.map(draggingServices, (value, index) => {
                            return (
                                <ServiceItem key={value.settingId} service={value} index={index} draggingItem={draggingItem}
                                    handleDragStart={handleDragStart} handleDragEnd={handleDragEnd} handleDragOver={handleDragOver} handleDrop={handleDrop}
                                    selectedImages={selectedImages} setSelectedImages={setSelectedImages} withCachedLogos={withCachedLogos} onSuccessLogos={onSuccessLogos} requestImages={requestImages} setRequestImages={setRequestImages} />
                            )
                        })
                    }
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Button className="save-services-btn" type="submit">Save</Button>
                            <Button className="cancel-services-btn" onClick={closeEdit}>Cancel</Button>
                        </Col>

                    </Row>
                </Stack>
            </Form>
        </FormProvider>


    </>);
};

export default EditServiceSetting;
