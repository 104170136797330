import * as React from "react";
import { BrowserRouter, Link, Navigate, Routes, Route } from "react-router-dom";
import { Accounting, Confirmation, Invitation, Order, ResetPassword, Team, Statistic, Settings, QualityControl, TwoFactorAuthentication, SecurityMethodsContainer } from "./components";
import { Role } from "./core";
import { useAppSelector } from "./hooks";
import { getAuthFromStorage, hasTwoFactorAuthMethodsStored, isAuthRefreshTokenExpired, saveAuthToStorage } from "./services";
import { userSelector } from "./store/slices/userSlice";
import { windowRef } from "./utils/firebase";
import _ from "lodash";

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<AppNavigator />} />
      <Route path="/login/*" element={<LoggedOut link={import("./components/login/Login")} />} />
      <Route path="/forget-password" element={<LoggedOut link={import("./components/forgetPassword/ForgetPassword")} />} />
      <Route path="/reset-password-request/:resetPasswordCode" element={<ResetPassword />} />
      <Route path="/home" element={<HomeRoute />}>
        <Route path="" element={<LandingRoute />} />
        <Route path="order" element={<Order />} />
        <Route path="statistic" element={<AuthorizedRoute roles={[Role.ADMIN]}><Statistic /></AuthorizedRoute>} />
        <Route path="team" element={<AuthorizedRoute roles={[Role.ADMIN, Role.SUPERVISOR]}><Team /></AuthorizedRoute>} />
        <Route path="accounting" element={<AuthorizedRoute roles={[Role.ADMIN]}><Accounting /></AuthorizedRoute>} />
        <Route path="setting" element={<AuthorizedRoute roles={[Role.ADMIN]}><Settings /></AuthorizedRoute>} />
        <Route path="quality-control" element={<AuthorizedRoute roles={[Role.ADMIN, Role.SUPERVISOR]}><QualityControl /></AuthorizedRoute>} />
      </Route>
      <Route path="/security" element={<SecurityRoute />} >
        <Route path=""  element={<SecurityMethodsContainer/>} />
        <Route path="2fa" element={<TwoFactorAuthentication />} />
      </Route>
      <Route path="/verify-otp" element={<VerifyRoute />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/invitation/:invitationCode" element={<Invitation />} />
      <Route path="/confirmation" element={<Confirmation />} />

    </Routes>
  );
};
const AppNavigator = () => {
  const token = getAuthFromStorage();
  return <Navigate to={token ? '/home' : '/login'} replace={true} />
}

const LoggedOut = (props: { link: any }) => {
  const token = getAuthFromStorage();
  if (token) {
    return <Navigate to="/home" replace={true} />
  }
  const LoggedOutComponent = React.lazy(() => props.link);//logged out
  return (
    <React.Suspense fallback={<>...</>}>
      <LoggedOutComponent />
    </React.Suspense>
  );
}

const VerifyRoute = () => {
  const auth = getAuthFromStorage();
  delete windowRef.RecaptchaVerifier
  if (auth && !auth.isOtpVerified && auth.twoFactorAuthMethods?.length && !isAuthRefreshTokenExpired() ) {
    const VerifyOtp = React.lazy(() => import("./components/verify-otp/VerifyOtp"));
    return <React.Suspense fallback={<>...</>}>
      <VerifyOtp phoneNumber={auth.phoneNumber} />
    </React.Suspense>
  }
  if (auth && auth.twoFactorAuthMethods?.length  && auth.isOtpVerified) {
    return <Navigate to={'/home'} replace={true} />
  }
  if (auth && !auth.twoFactorAuthMethods?.length && !isAuthRefreshTokenExpired()) {

    return <Navigate to={'/security'} replace={true} />
  }
    saveAuthToStorage(null)
  return <Navigate to={'/login'} />
}

const SecurityRoute = () => {
  const isTwoFactorAuthExist = hasTwoFactorAuthMethodsStored();
  const auth = getAuthFromStorage();
  if (!isTwoFactorAuthExist || (isAuthRefreshTokenExpired() && !auth?.isOtpVerified)) {
    saveAuthToStorage(null)
    return <Navigate to={'/login'} />
  }
  if (auth && (!auth.twoFactorAuthMethods?.length || auth.isOtpVerified)) {
    const Security = React.lazy(() => import("./components/security/Security"));
    return (
      <React.Suspense fallback={<>...</>}>
        <Security />
      </React.Suspense>)
  }
  if (auth && !auth.isOtpVerified) {

    return <Navigate to={'/verify-otp'} replace={true} />
  }

  return <Navigate to={'/login'} />
}


const HomeRoute = () => {
  const auth = getAuthFromStorage();
  if (auth && !auth.twoFactorAuthMethods?.length) {
    return <Navigate to={'/security'} replace={true}/>
  }
  if (auth && auth.isOtpVerified && auth.twoFactorAuthMethods?.length ) {
    const Home = React.lazy(() => import("./components/home/Home"));//logged in
    return (
      <React.Suspense fallback={<>...</>}>
        <Home />
      </React.Suspense>
    )
  }
  if (auth && !auth.isOtpVerified && auth.twoFactorAuthMethods?.length) {

    return <Navigate to={'/verify-otp'} replace={true}/>
  }
  return <Navigate to={'/login'} />
}

const AuthorizedRoute = ({ children, roles }: { children: JSX.Element; roles: Role[] }) => {
  const userState = useAppSelector(userSelector);
  return (
    <>
      {(_.includes(roles, userState.me?.RoleId)) ? children : <LandingRoute />}
    </>
  )
}

const LandingRoute = () => {
  const userState = useAppSelector(userSelector);
  if (userState.me?.RoleId === Role.ADMIN) {
    return (<Navigate to="/home/statistic" replace={true} />);
  }
  return (<Navigate to="/home/order" replace={true} />);
}

const NotFound = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>
        <Link to="/login">Login</Link>
      </p>
    </div>
  );
}

const Router = () => {
  return (
    <BrowserRouter>
      <RoutesConfig />
    </BrowserRouter>
  );
}

export default Router;