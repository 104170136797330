import * as React from 'react';
import { useAppDispatch } from ".";
import { useLazyMeQuery, getAuthFromStorage } from "../services";
import { authActions } from '../store/slices/authSlice';

export const useStoreAuth = () => {

    ///init user if isOtp verified true
    const dispatch = useAppDispatch();
    const [getMe, { isLoading, error }] = useLazyMeQuery();
    const auth = getAuthFromStorage();
    React.useEffect(() => {
        if (auth) {
            dispatch(authActions.set(auth));
           auth.isOtpVerified && getMe();
        }
        // eslint-disable-next-line
    }, [dispatch, getMe]);

    return auth?.isOtpVerified ? { isLoading, error, isOtpVerified: true } : {isOtpVerified: false, isLoading, error};
}