import React from 'react'
import { Pagination } from 'react-bootstrap';
import "./pagination.scss";

interface IPaginateProps {
  currentPage: number;
  totalPages: number;
  total: number;
  onClick: (page: number) => void
}

const Paginate = ({ currentPage, totalPages, total, onClick }: IPaginateProps) => {
  return (
    <>
      {total !== 0 &&
        <div className="pagination-container">
          <Pagination size="sm" className="paginate">
            <Pagination.Prev disabled={currentPage === 1} onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              if (currentPage > 1) {
                onClick(currentPage - 1);
              }

            }}>Previous</Pagination.Prev>
            <Pagination.Item active={currentPage === 1} onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              if (currentPage !== 1) {
                onClick(1);
              }
            }}>{1}
            </Pagination.Item>
            {(currentPage - 1 > 1) && <Pagination.Ellipsis />}

            {(currentPage !== 1 && currentPage !== totalPages) &&
              <Pagination.Item active={true} onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                onClick(currentPage);
              }}>{currentPage}
              </Pagination.Item>}
            {(totalPages - currentPage > 1) && <Pagination.Ellipsis />}
            {totalPages > 1 && <Pagination.Item active={currentPage === totalPages} onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              if (currentPage !== totalPages) {
                onClick(totalPages);
              }
            }}>{totalPages}
            </Pagination.Item>}
            <Pagination.Next disabled={currentPage === totalPages} onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              if (currentPage < totalPages) {
                onClick(currentPage + 1);
              }
            }}> Next</Pagination.Next>

          </Pagination>
        </div>}
    </>
  )
}

export default Paginate
