import { Notification } from './app/components';
import Router from './app/Route';
import { initializeGtmTagManager } from './app/utils/gtm';

const App = () => {
  initializeGtmTagManager()
  return (
    <>
      <Router />
      <Notification />
    </>
  );
}

export default App;
