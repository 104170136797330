import React from 'react'
import { Loader, Modal, Select } from '..'
import { useUpdateUserMutation } from '../../services';
import { getLowerLevelRoles, IUser, Role, RolesDescription } from '../../core';
import { useAppDispatch, useAppSelector, useIsAuthUserPermitted } from '../../hooks';
import { notificationActions } from '../../store/slices/notificationSlice';
import { Col, Form, Row } from 'react-bootstrap';
import { editUserLabel, EditUserSuccessMessage } from '../../utils/confirmationMessage';
import { userSelector } from '../../store/slices/userSlice';

interface EditUserModalProps {
  user: IUser
  onSuccess: (user: IUser) => void,
  onClose: (value: boolean) => void,
  showModal: boolean,
}
const EditUserModal = ({ showModal, user, onClose, onSuccess }: EditUserModalProps) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(userSelector);

  const isUserAdmin = useIsAuthUserPermitted(Role.ADMIN);

  const [updateUser, { data, isLoading }] = useUpdateUserMutation();
  const [isActive, setIsActive] = React.useState<boolean>(user.IsActive);
  const [userRoleId, setUserRoleId] = React.useState<Role>(user.RoleId)

  const handleUpdateUser = () => {
    updateUser({id: user.Id, isActive,  roleId: isUserAdmin ? userRoleId : undefined});
  }

  const closeModal = () => {
    setIsActive(user.IsActive);
    setUserRoleId(user.RoleId);
    onClose(false);
  }

  const handleRoleIdChange = React.useMemo(() => (selectedRole: { value: number, label: string }) => {
    setUserRoleId(selectedRole.value)
  }, []);

  const handleIsActiveChange = React.useMemo(() => (selectedRole: any) => {
    setIsActive(selectedRole.target.checked)
  }, []);



  React.useEffect(() => {
    if (data?.Data) {
      dispatch(notificationActions.set({
        view: true,
        autoHide: true,
        state: 'success',
        text: EditUserSuccessMessage(),
        title: 'Success!'
      }))
      onSuccess(data?.Data);
      onClose(false)
    }
    // eslint-disable-next-line
  }, [data]);
  
  const handleSetRoleId = (e: any)=> setUserRoleId(e.value)

  return (
    <>
    {isLoading && <Loader/>}
      <Modal show={showModal} onSave={handleUpdateUser} title='Edit User' saveBtnTitle='Save' onClose={closeModal} size='lg' >
        <Form>
          <Form.Label className='edit-modal-label'>{editUserLabel(`${user.FirstName} ${user.LastName}`)}</Form.Label>
          <Row className='edit-row'>
            <Col md={2} ><span className='edit-label'>Active Status</span></Col>
            <Col md={2}>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={isActive}
                onChange={handleIsActiveChange}
              >
              </Form.Check>
            </Col>
          </Row>
          {(isUserAdmin && (user.IsActive || isActive)) &&
            <Row>
              <Col md={2}><span className='edit-label'> User Role</span></Col>
              <Col md={4}>
                <Select options={getLowerLevelRoles(userState.me?.RoleId || 0)} isMulti={false} value={RolesDescription[userRoleId]} placeHolder='User Role'
                  onChange={handleRoleIdChange} setValue={handleSetRoleId} withCheckbox={false} withEmptyOption={false} 
                ></Select>
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    </>
  )
}

export default EditUserModal