import { Stack } from "react-bootstrap";
import { Loader, PrintSetting, ServiceSetting, RetentionSetting } from "..";
import { useLazyGetAllSettingsQuery } from "../../services";
import React, { useEffect } from "react";
import { IMarginValue, IRetentionValue, IUpdateServiceSetting, ServiceSettings, SettingEnum, mapSettingsToServices } from "../../core";
import _ from "lodash";
import "./settings.scss";

const Settings = () => {
    const [marginValues, setMargrinValues] = React.useState<IMarginValue>({ topMargin: 0, leftMargin: 0 });
    const [services, setServices] = React.useState<IUpdateServiceSetting[]>([]);
    const [retentionValue, setRetentionValue] = React.useState<IRetentionValue>({ retentionDays: 0 });

    const [getAllSettings, { data: allSettings, isLoading: getallSettingsLoading }] = useLazyGetAllSettingsQuery();

    useEffect(() => {
        if (allSettings?.Data.Data) {
            //Extract print setting
            const printSetting = _.find(allSettings.Data.Data, (setting) => { return (setting.Id === SettingEnum.FBIFORMPrintSettings) })
            setMargrinValues(printSetting?.Value)

            //Extract service setting
            const serviceSetting = _.filter(allSettings.Data.Data, (setting) => { return (ServiceSettings.includes(setting.Id)) })
            if (serviceSetting) {
                //mapping values
                setServices(mapSettingsToServices(serviceSetting));
            }

            //Extract retention setting
            const retentionSettings = _.find(allSettings.Data.Data, (setting) => { return (setting.Id === SettingEnum.RetentionDaysSettings) });
            setRetentionValue(retentionSettings?.Value)
        }
    }, [allSettings])

    useEffect(() => {
        getAllSettings();
    }, [getAllSettings])

    return (<>
        {getallSettingsLoading && <Loader />}
        <Stack gap={3} className="all-settings">
            <PrintSetting marginValues={marginValues} setMargrinValues={setMargrinValues} />
            <RetentionSetting retentionDaysValue={retentionValue} onUpdateRetentionValue={setRetentionValue}/>
            <ServiceSetting services={services} setServices={setServices} />
        </Stack>
    </>);
};

export default Settings;
