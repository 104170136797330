import { OrderStatus, OrderType } from "../core";
import { useIsPassThrough } from ".";

export const useIsCompletedResubmittedPassThrough = (orderType: OrderType, orderStatus: OrderStatus): boolean => {
    return (
      useIsPassThrough(orderType)
    ) && (
      orderStatus === OrderStatus.COMPLETED || orderStatus === OrderStatus.RESUBMITTED
    );
}
