import { ConfirmationResult } from 'firebase/auth'
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { EnableSmsAuthModal, Loader, SetupSmsAuthModal, VerifyOtpModal } from '..'
import { TwoFactorAuthMethod } from '../../core'
import { useIsAuthMethodSelected } from '../../hooks'
import { useDisableTwoFactorAuthMutation } from '../../services'
import { AddPhoneNumberModalTitle, VerifySmsModalBody } from '../../utils/confirmationMessage'
import { ReactComponent as SmsIcon } from '../../../assets/images/sms-icon.svg'; 

const SmsAuthCard = ({onDisableOtp , userAuthMethods}: {onDisableOtp?: ()=>void, userAuthMethods?: TwoFactorAuthMethod[]}) => {
  
  const isAuthSelected =  useIsAuthMethodSelected(TwoFactorAuthMethod.SMS, userAuthMethods || [])
  const [disableTwoFactorAuth,{isLoading: isDisableLoading}] = useDisableTwoFactorAuthMutation()
  const formMethods = useForm<{phoneNumber: string}>({  mode: "all" });

  const [showSetupAuthModal, setShowSetupAuthModal] = React.useState(false)
  const [showEnableAuthModal, setShowEnableAuthModal] = React.useState(false);
  const [showVerifyOtpModal, setShowVerifyOtpModal] = React.useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = React.useState<ConfirmationResult>();
  const [enableSmsTitle, setEnableSmsTitle] = React.useState<string>(AddPhoneNumberModalTitle())
  const handleSelectSmsAuth = () => {
    if (!isAuthSelected) {
      setEnableSmsTitle(AddPhoneNumberModalTitle())
      setShowSetupAuthModal(true)
    } else {
     disableTwoFactorAuth({ twoFactorAuthMethod: TwoFactorAuthMethod.SMS }).finally(()=> onDisableOtp && onDisableOtp())
    }
  }
  const handleSetConfirmationMessage = (value: ConfirmationResult) => setConfirmationResult(value)

  return (
    <>
    {isDisableLoading && <Loader />}
    <FormProvider {...formMethods}>
      <Card className='auth-card'>
        <div>
        <span> <SmsIcon/></span>
        <span className='auth-title'>  Text message (SMS)</span>
        </div>
        <p className='auth-body'>
          Use text message (SMS) to receive a verification code.
        </p>
        <Button onClick={handleSelectSmsAuth} className={`auth-btn ${isAuthSelected && "turn-off"}`}> {isAuthSelected ? "Turn Off" : "Use Text Message (SMS)"}</Button>
      </Card>
      <SetupSmsAuthModal showSetupModal={showSetupAuthModal} setShowSetupModal={setShowSetupAuthModal} title={enableSmsTitle} setShowEnableAuthModal={setShowEnableAuthModal} />
      <EnableSmsAuthModal showEnableAuthModal={showEnableAuthModal} setShowEnableAuthShowModal={setShowEnableAuthModal} setShowSetupModal={setShowSetupAuthModal} setShowVerifyOtpModal={setShowVerifyOtpModal} setConfirmationResult={setConfirmationResult} setEnableSmsTitle={setEnableSmsTitle} />
      <VerifyOtpModal showModal={showVerifyOtpModal} setShowModal={setShowVerifyOtpModal} twoFactorAuthMethod={TwoFactorAuthMethod.SMS} confirmationSmsResult={confirmationResult} setConfirmationSmsResult={handleSetConfirmationMessage} modalBody={VerifySmsModalBody(formMethods.getValues().phoneNumber)} phoneNumber={formMethods.getValues().phoneNumber} />
      </FormProvider>
    </>
  )
}

export default SmsAuthCard