import { ConfirmationResult } from 'firebase/auth'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { Modal, SendOtp, Timer, VerifyAppAction, VerifySmsAction } from '..'
import { IVerifyOtp, TwoFactorAuthMethod, Timer as TimerEnum } from '../../core'
import { validationRules } from '../../utils/formValidator'
import { VerifyAuthAppModalTitle, VerifySmsModalTitle } from '../../utils/confirmationMessage'


interface IModalProps {
  showModal: boolean,
  twoFactorAuthMethod: TwoFactorAuthMethod,
  confirmationSmsResult?: ConfirmationResult,
  modalBody: string,
  phoneNumber?: string,
  setConfirmationSmsResult?: (value: ConfirmationResult)=>void,
  setShowModal: (value: boolean) => void,

}

const VerifyOtpModal = ({ showModal, twoFactorAuthMethod, confirmationSmsResult, setConfirmationSmsResult, setShowModal , modalBody, phoneNumber}: IModalProps) => {
  const formMethods = useForm<IVerifyOtp>({ mode: 'all' });
  const [time, setTime] = React.useState<number>(TimerEnum.initialSmsTime)
  const [restartTimer, setRestartTimer] = React.useState(false);
  const title =twoFactorAuthMethod === TwoFactorAuthMethod.AuthApp ? VerifyAuthAppModalTitle() : VerifySmsModalTitle()
  const verifyValidationRules = validationRules<{ otp: string }>({
    otp: { required: true, pattern: 'digitsString', displayName: "otp", maxLength: 6, minLength: 6 }
  });
  return (
    <div>
      <Modal show={showModal} saveBtnTitle='Confirm' title={title} onSave={() => setShowModal(true)} onClose={() => setShowModal(true)} size='lg' hideCancelBtn={true} hideSaveBtn={true} hideCloseBtn={showModal} >
        <FormProvider {...formMethods}>
        <div>
          <p className='verify-modal-body'>{modalBody}</p>
          <Form.Group>
            <Form.Label className='bold-text'> Code </Form.Label>
            <Row>
              <Col md={6}>
            <Form.Control  placeholder='Login code' className="otp-input-control" {...formMethods.register('otp', verifyValidationRules['otp'])} isInvalid={!!formMethods.formState.errors.otp} type="text" 
            onChange={() => {
              formMethods.clearErrors('otp')
            }}  />
            </Col>
            { twoFactorAuthMethod === TwoFactorAuthMethod.SMS && <>
            <Col md={6} className='resend-container'>
            <SendOtp setConfirmationResult={setConfirmationSmsResult} title='Resend Code' setRestartTimer={setRestartTimer} time={time} setTime={setTime} isCodeResent={false} phoneNumber={phoneNumber} />
           { time ? <Timer restart={restartTimer} time={time} setRestart={setRestartTimer} setTime={setTime} />: <></> }
           </Col>
            </>}
            </Row>
            <Form.Text>
              {formMethods.formState.errors.otp && <span className='error-msg'>{formMethods.formState.errors.otp.message}</span>}
            </Form.Text>
          </Form.Group>
          {twoFactorAuthMethod === TwoFactorAuthMethod.AuthApp ?
           <VerifyAppAction  btnTitle='Confirm' btnStyle='verify-next'/> :
            <VerifySmsAction  confirmationResult={confirmationSmsResult} btnTitle='Confirm' btnStyle='verify-next' />
          }

        </div>
        </FormProvider>
      </Modal>
    </div>
  )
}

export default VerifyOtpModal