import React from 'react'
import {AuthAppCard, SmsAuthCard} from '..'
import { Row, Col } from 'react-bootstrap'

const TwoFactorAuthContainer = () => {
  return (
    <div className='security-selection'>
      <span className='select-security-method'> Select a security method </span>
      <p className='two-factor-auth-text'>
        Two-factor authentication protects your account by requiring an additional code when you log in
        on a device that we don't recognize
      </p>
      <Row>
        <Col md={5}>
          <SmsAuthCard />
        </Col>
        <Col md={5}>
          <AuthAppCard />
        </Col>

      </Row>

    </div>
  )
}

export default TwoFactorAuthContainer