import { components, OptionProps } from "react-select";
import { default as ReactSelect } from "react-select";
import './select.scss';

interface ISelectProps {
    options: { value: number | string, label: string }[];
    isMulti: boolean;
    value: any;
    placeHolder: string;
    withCheckbox?: boolean;
    emptyOptionLabel?: string;
    onChange: (options: any) => void;
    setValue: (value: any) => void;
    withEmptyOption?: boolean;
    isDisabledEmptyOption?: boolean;

}

const Select = ({ options, isMulti, value, placeHolder, withCheckbox, emptyOptionLabel, onChange, setValue, withEmptyOption = true, isDisabledEmptyOption = false }: ISelectProps) => {
    const handleChange = (e: any) => {
        setValue(e);
        onChange(e);
    }

    const isOptionLabelDisabled = (option: any) => {
        return option.value === undefined && isDisabledEmptyOption === true;
      }


    return (
        <ReactSelect options={(!isMulti && withEmptyOption) ? [{ value: undefined, label: emptyOptionLabel ?? "All" }, ...options] : options}
            isMulti={isMulti} value={value}
            hideSelectedOptions={false} closeMenuOnSelect={!isMulti}
            components={{ Option: (optionProps) => { return OptionComponent(optionProps, withCheckbox ?? true) } }}
            onChange={handleChange} placeholder={placeHolder} classNamePrefix={'multi-select'} isOptionDisabled={isOptionLabelDisabled}
        />
    )
}

const OptionComponent = (props: OptionProps, showCheckbox: boolean) => {
    return (
        <div>
            <components.Option {...props}>
                {showCheckbox && <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />}
                {" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


export default Select

