import * as React from 'react'
import { Container, Col, Stack, Button, Accordion, Form, Row } from 'react-bootstrap';
import { IGetOrderRequest, OrderOrPassThroughArray, OrderStatus, OrderType, OrderTypeDescription, PaymentType, PaymentTypeDescription, SortOptions } from '../../core';
import { useGetOrdersQuery } from '../../services';
import _ from "lodash";
import { Input, OrderItem, Select, Pagination, Loader, OrderStatusSelect, AccountNumberSearch, OrderTypeSelect } from '..';
import "./order.scss";
import { MaxLengthErrorMessage, NoOrdersRecord } from '../../utils/errorMessage';
import { patternRgx } from '../../utils/formValidator';
import { useDebcounceHook } from '../../hooks';

const Order = () => {
    const defaultSearchParam: IGetOrderRequest = { withFingerprints: true, withLocations: true, withStatusLogs: true, withOrderRetrial: true, withOrderShipment: true, withAccount: true, withAgency: true, withSterlingLogs: true, withOrderResubmission: true }
    const [requestParams, setParams] = React.useState<IGetOrderRequest>(defaultSearchParam);
    const [inputSearchValue, setInputSearch] = React.useState<string>(requestParams.filter ?? '');
    const [selectStatusValue, setStatus] = React.useState<OrderStatus[]>(requestParams.statuses ?? []);
    const [orderType, setOrderType] = React.useState<OrderType[] | null>(requestParams.types ?? null);
    const [selectPaymentType, setPaymentType] = React.useState<PaymentType | null>(requestParams.paymentType ?? null);
    const [selectAccountNumbersValue, setSelectAccountNumbersValue] = React.useState<{label: string, value: number}[]| null>(null);
    const [sortByValue, setSortBy] = React.useState<SortOptions>(requestParams.sortBy ?? SortOptions.SortByCreatedDate);
    const [sortOrderValue, setSortOrder] = React.useState<SortOptions>(requestParams.sortOrder ?? SortOptions.SortDescending);
    const [isUnshipped, setIsUnshipped] = React.useState<boolean>(requestParams.isUnshipped ?? false);
    const { data: orders, isLoading, isFetching, refetch } = useGetOrdersQuery(requestParams, { refetchOnMountOrArgChange: true });
    const [errorMessageValue, setErrorMessage] = React.useState<string>('');
    const [missingFingerprint, setMissingPassFingerprint ] = React.useState<boolean>(false);
    

    const debounceSearch = useDebcounceHook((search: string) => {
        if (searchByNameValidateFilter(search)) {
            if (!search) {
                setParams((prev) => ({ ...prev, filter: undefined, page: undefined }));
            }
            else {
                setParams((prev) => ({ ...prev, filter: search, page: undefined }));
            }
        }

    });

    const handleSelectOrderType = (value: OrderOrPassThroughArray | null) =>  setOrderType(value as OrderType[])
    
    const changeStatus = React.useMemo(() => (selectedStatus: unknown) => {
        const statuses = _.map(selectedStatus as { value: number, label: string }[], (v, i) => v.value);
        if (statuses.length === 0) {
            setParams((prev) => ({ ...prev, statuses: undefined, page: undefined }));
        }
        else {
            setParams((prev) => ({ ...prev, statuses, page: undefined }));
        }
    }, []);

    const changeOrderType = React.useMemo(() => (selectedOrderType: unknown) => {
        const type = selectedOrderType as { value: number, label: string }
        setParams((prev) => ({ ...prev, types: type.value ? [type.value] : [], page: undefined })) 

    }, []);

    const changePaymentType = React.useMemo(() => (selectedPaymentType: { value: number, label: string }) => {
        setParams((prev) => ({ ...prev, paymentType: selectedPaymentType.value, page: undefined }));
    }, [])


    const clearSearch = () => {
        setParams(defaultSearchParam);
        setInputSearch('');
        setOrderType(null);
        setPaymentType(null);
        setStatus([]);
        setIsUnshipped(false)
        setSortBy(SortOptions.SortByCreatedDate)
        setSortOrder(SortOptions.SortDescending)
        setSelectAccountNumbersValue([]);
        setMissingPassFingerprint(false)
    }

    const handleSorting = (orderBy: number) => {
        setSortBy(orderBy);
        if (sortOrderValue === SortOptions.SortDescending) {
            setSortOrder(SortOptions.SortAscending)
            setParams((prev) => ({ ...prev, sortBy: orderBy, sortOrder: SortOptions.SortAscending, page: undefined }));
        } else {
            setSortOrder(SortOptions.SortDescending)
            setParams((prev) => ({ ...prev, sortBy: orderBy, sortOrder: SortOptions.SortDescending, page: undefined }));
        }

    }


    const getSortIcon = () => {
        if (sortOrderValue === SortOptions.SortDescending) {
            return (<i className='fa fa-angle-down'></i>)
        } else {
            return (<i className='fa fa-angle-up'></i>)
        }
    }

    const searchByNameValidateFilter = (search: string) => {
        if (search.length >= 50) {
            setErrorMessage(MaxLengthErrorMessage('Search', 50));
            return false;
        }
        if (search && !patternRgx["numberCharactersAndSpecial"].value.test(search)) {
            setErrorMessage(patternRgx["numberCharactersAndSpecial"].message);
            return false;
        }
        setErrorMessage('');
        return true;
    }

    React.useEffect(() => {
        setParams((prev) => ({ ...prev, isUnshipped: isUnshipped || undefined, page: undefined }));
    }, [isUnshipped])


    React.useEffect(() => {
        const selectedAccountNumbers = _.map(selectAccountNumbersValue, (v) => v.value);
        setParams((prev) => ({ ...prev, searchByAccountIds: selectedAccountNumbers, page: undefined }));
    }, [selectAccountNumbersValue]);
    
    React.useEffect(() => {
        setParams((prev) => ({ ...prev, hasMissingPassThroughFingerprint: missingFingerprint || undefined, page: undefined }));
    }, [missingFingerprint])

    return (
        <>
            <Container fluid className="px-4">
                <Stack gap={3}>
                    {/* filter */}
                    <div>
                        {(isLoading || isFetching) && <Loader />}
                        <Row>
                            <Col md={6} className="mb-md-2" >
                                <Input onSearch={((search: string) => debounceSearch(search))} value={inputSearchValue}
                                    setValue={setInputSearch} placeHolder="Search By Name">
                                    <i className="fa fa-search"></i>
                                </Input>
                            </Col>
                            <Col md={6} xs={12}>
                            <AccountNumberSearch onSelect={setSelectAccountNumbersValue} accountNumbers={selectAccountNumbersValue} />
                            </Col>
                        </Row>
                        <Row md={6} xs={12} className="mt-md-2">
                            <Col lg={3} md={3} xs={12}>
                            <OrderTypeSelect onChange={changeOrderType} onSelect={handleSelectOrderType} value={orderType} options={OrderTypeDescription} />

                            </Col>
                            <Col lg={3} md={3} xs={12}>
                                <OrderStatusSelect onChange={changeStatus} value={selectStatusValue} setValue={setStatus} />
                            </Col>
                            <Col lg={3} md={3} xs={12} >
                                <Select withCheckbox={false}
                                    options={_.map(PaymentTypeDescription, (value, index) => ({ value: Number(index), label: value }))}
                                    isMulti={false} onChange={changePaymentType}
                                    value={selectPaymentType} setValue={setPaymentType} placeHolder="Payment Method" />
                            </Col>
                         
                        </Row>

                        <Row className="mt-md-3">
                            <Col lg={3} md={3} xs={4} className="d-flex  order-checkbox" >
                                <Form.Group>
                                    <Form.Check type="checkbox" label="Unshipped / Unrecorded Orders" checked={isUnshipped} onChange={() => setIsUnshipped(!isUnshipped)} />
                                </Form.Group>
                            </Col>
                            <Col lg={3} md={3} xs={4} className="order-checkbox" >
                                <Form.Group>
                                    <Form.Check type="checkbox" label="Completed - Download Issue" checked={missingFingerprint} 
                                    onChange={() => setMissingPassFingerprint(!missingFingerprint)} />
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={1}></Col>
                            <Col lg={2} md={2} xs={3} className='pull-right'>
                                <Button variant="outline-danger" className="ms-auto pull-right" onClick={clearSearch}>
                                    <i className="fa fa-ban"></i>  Clear</Button>
                            </Col>
                        </Row>

                    </div>
                    {/* error message (if exists) */}
                    <span className='error-message'>{errorMessageValue}</span>

                    {/* order grid */}
                    <div className="grid-header pt-4">
                        <Col onClick={() => handleSorting(SortOptions.SortByName)}>Name {(sortByValue === SortOptions.SortByName) && getSortIcon()}</Col>
                        <Col onClick={() => handleSorting(SortOptions.SortByCreatedDate)}>Order DateTime {(sortByValue === SortOptions.SortByCreatedDate) && getSortIcon()}</Col>
                        <Col onClick={() => handleSorting(SortOptions.SortByOrderType)}>Order Type {(sortByValue === SortOptions.SortByOrderType) && getSortIcon()}</Col>
                        <Col onClick={() => handleSorting(SortOptions.SortByFees)}>Total Fees {(sortByValue === SortOptions.SortByFees) && getSortIcon()}</Col>
                        <Col onClick={() => handleSorting(SortOptions.SortByStatus)}>Status {(sortByValue === SortOptions.SortByStatus) && getSortIcon()}</Col>
                    </div>
                    <div>
                        <Accordion>
                            {(!orders?.Data.Data || orders?.Data.Data.length === 0) &&
                                (
                                    <div className="bg-light grid-row py-3 align-items-center">
                                        <div className="mx-3">{NoOrdersRecord()}</div>
                                    </div>
                                )
                            }
                            {
                                _.map(orders?.Data.Data, (value, index) => {
                                    return (
                                        <OrderItem order={value} key={value.Id} refresh={refetch} />
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                    {/* Pagination */}
                    <Pagination currentPage={orders?.Data.CurrentPage ?? 1} totalPages={orders?.Data.TotalPages ?? 1} total={orders?.Data.Total ?? 0}
                        onClick={(page: number) => { setParams((prev) => ({ ...prev, page })); }} />
                </Stack>
            </Container>
        </>
    )
}

export default Order
