import { ProgressBar } from 'react-bootstrap';
import "./progress.scss"
const Progress = (props: { percentage: number }) => {
    return (
        <>
            <div className="loader-wrapper">
                <ProgressBar striped variant="success" now={props.percentage} />
            </div>
        </>
    )
}

export default Progress;