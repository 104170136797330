import { Button, Container, Form, Row, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { IForgetPassword } from "../../core";
import { useForgetPasswordMutation } from "../../services/apiService";
import { formValidator, validationRules } from "../../utils/formValidator";
import { Loader, LoggedOutHeader } from '..';
import "./forgetResetPassword.scss";
import { useAppDispatch } from "../../hooks";
import React from "react";
import { ResendEmailSuccessMessage } from "../../utils/confirmationMessage";
import { notificationActions } from "../../store/slices/notificationSlice";

const ForgetPassword = () => {
    const [forgetPassword, { data, isLoading }] = useForgetPasswordMutation();
    const { register, handleSubmit, formState: { errors } } = useForm<IForgetPassword>({ mode: 'all' });
    const onSubmit: SubmitHandler<IForgetPassword> = (formData) => forgetPassword(formData);
    const dispatch = useAppDispatch();


    React.useEffect(() => {
        if (data?.Data) {
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: ResendEmailSuccessMessage(),
                title: 'Success!'
            }))
        }
        // eslint-disable-next-line
    }, [data]);

    const authValidationRules = validationRules<IForgetPassword>({
        email: formValidator.email,
    });

    return (
        <>
            {isLoading && <Loader />}
            <LoggedOutHeader wrapperClass="header-container-gap">
                <Container className="container-wrapper">
                    <Row className="form-row">
                        <div className="form my-4 pt-4">
                            <Stack gap={2}>
                                <h6 className="text-center title">
                                    Forgot Password
                                </h6>
                                <p className="mb-3 px-4 instruction">
                                    Enter your email address and we will send you an email to change your password
                                </p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3 px-4" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control className="input-control" {...register('email', authValidationRules['email'])} isInvalid={!!errors.email} type="email" />
                                        <Form.Text>
                                            {errors.email && <span className='error-msg'>{errors.email.message}</span>}
                                        </Form.Text>
                                    </Form.Group>
                                    <div className="container-wrapper mb-3">
                                        <Button className="send-btn" size="lg" type="submit">
                                            Send
                                        </Button>
                                    </div>
                                </Form>
                            </Stack>
                        </div>
                    </Row>
                </Container>
            </LoggedOutHeader>
        </>
    )
}

export default ForgetPassword;