import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Modal } from '..';
import { validationRules } from '../../utils/formValidator';
import { UsaCountryCode } from '../../utils/confirmationMessage';
import { initRecaptcha, windowRef } from '../../utils/firebase';

interface IModalProps {
    showSetupModal: boolean,
    setShowSetupModal: (value: boolean) => void,
    title: string,
    setShowEnableAuthModal: (value: boolean) => void,
}
const SetupSmsAuthModal = ({ showSetupModal, title, setShowSetupModal, setShowEnableAuthModal }: IModalProps) => {
    const { register, trigger, setValue, clearErrors, formState: {isValid, errors}  } = useFormContext();

    const onConfirm = () => {
        if (isValid) {
            setShowEnableAuthModal(true)
            setShowSetupModal(false)
            if (!windowRef.RecaptchaVerifier ) {
                initRecaptcha();
            }
        }
        trigger('phoneNumber')
    }
    const phoneNumberValidationRules = validationRules<{ phoneNumber: string }>({
        phoneNumber: { required: true, pattern: 'phoneNumber', displayName: "Phone Number", minLength: 4, maxLength: 16 }
    });
    const closeSetupModal = () => {
        setShowSetupModal(false);
        setValue("phoneNumber", UsaCountryCode())
        clearErrors("phoneNumber")
    }
 
    return (
        <>
                    <div id='recaptcha-container' className='recaptcha'></div>

            <Modal show={showSetupModal} saveBtnTitle='Confirm' title={title} onSave={onConfirm} onClose={closeSetupModal} size='lg' >
                <div>
                    <p>Enter your phone number and we’ll send you a confirmation code next.</p>
                    <Form>
                        <Form.Label className='bold-text'> Phone Number </Form.Label>
                        <Form.Control defaultValue={UsaCountryCode()} placeholder='+1 665 555 1234' className="otp-input-control" {...register('phoneNumber', phoneNumberValidationRules['phoneNumber'])} isInvalid={!!errors.phoneNumber} type="text" onChange={() => clearErrors('phoneNumber')} />
                        <Form.Text>
                            {errors.phoneNumber && <span className='error-msg'>{errors.phoneNumber.message}</span>}
                        </Form.Text>
                    </Form>
                </div>
            </Modal>

        </>
    )
}

export default SetupSmsAuthModal