import { Button } from 'react-bootstrap';
import { Loader } from '../';
import { useDownload } from '../../hooks';

interface props {
    handleAction: () => void,
    response?: string | undefined,
    isLoading?: boolean,
    fileName: string

}
const ExportCsv = ({ handleAction, response, isLoading, fileName }: props) => {
    const exportHandler = () => handleAction();
    useDownload(response, fileName);

    return (
        <div>
            {(isLoading) && <Loader />}
            <Button className="export-csv-btn" onClick={exportHandler}> <i className="fa fa-file"></i> Export CSV</Button>
        </div>
    )
}

export default ExportCsv