import _ from "lodash";
import { IUpdateServiceSetting } from "../..";

export interface ISetting {
    Id: number;
    Value: any;
    createdAt: Date;
    updatedAt: Date;
}

export const mapSettingsToServices = (settings: ISetting[]) => {
    let servicesValues: IUpdateServiceSetting[] = [];
    _.forEach(settings, (service) => {
        servicesValues.push({
            settingId: service.Id,
            title: "title" in service.Value ? service.Value.title : "",
            description: "description" in service.Value ? service.Value.description : "",
            logo: undefined,
            serviceOrder: "serviceOrder" in service.Value ? service.Value.serviceOrder : 0
        })
    });
    return servicesValues;
}