import { Row, Col, Card, Container, Form } from "react-bootstrap";
import ServiceLogo from "./ServiceLogo";
import { IUpdateServiceSetting } from "../../core";
import { validationRules } from "../../utils/formValidator";
import _ from "lodash";
import { useFormContext } from "react-hook-form"
import React from "react";

interface itemData {
    service: IUpdateServiceSetting,
    index: number,
    draggingItem: IUpdateServiceSetting | null,
    handleDragStart: (e: any, item: IUpdateServiceSetting) => void,
    handleDragEnd: () => void,
    handleDragOver: (e: any) => void,
    handleDrop: (e: any, item: IUpdateServiceSetting) => void,
    selectedImages: string[],
    setSelectedImages: (value: string[]) => void,
    withCachedLogos: boolean,
    onSuccessLogos: () => void,
    requestImages: File[],
    setRequestImages: (value: File[]) => void,
}
const ServiceItem = ({ service, index, draggingItem, handleDragStart, handleDragEnd, handleDragOver, handleDrop, selectedImages, setSelectedImages, withCachedLogos, onSuccessLogos, requestImages, setRequestImages }: itemData) => {
    const { register, formState: { errors } } = useFormContext();
    const [isDraggable, setIsDraggable] = React.useState<boolean>(true);
    const serviceSettingValidation = validationRules<{ title: string, description: string, logo: any }>({
        title: { required: true, pattern: 'alphaNumericAndSpaceTitle', maxLength: 100, displayName: 'Title' },
        description: { required: true, pattern: 'alphaNumericAndSpaceDescription', maxLength: 500, displayName: 'Description' },
        logo: { file: { extension: ["png", "jpg", "jpeg", "gif"], size: 2000000 } }
    });

    // handle preview the selected image and sending request images
    const handleFileChange = (event: any, fileIndex: number) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            let tempImages = _.map(selectedImages, (img, index) => {
                if (Number(index) === fileIndex) {
                    return URL.createObjectURL(selectedFile);
                }
                return img;
            });
            let tempRequestImages = _.map(requestImages, (img, index) => {
                if (Number(index) === fileIndex) {
                    return selectedFile
                }
                return img;
            })
            setRequestImages(tempRequestImages)
            setSelectedImages(tempImages);
        }
    }

    return (<>
        <Row
            key={service.settingId}
            className=
            {`item ${service === draggingItem ?
                'dragging' : ''
                }`}
            draggable={isDraggable}
            onDragStart={(e) =>
                handleDragStart(e, service)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, service)}>
            <Col md={1} className="drag-icon-div"><i className="fa fa-bars drag-icon"></i></Col>
            <Col md={10}><Card body>
                <Container>
                    <Row>
                        <h5>Service {index + 1}</h5>
                    </Row>
                    <Row>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Title
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" placeholder="Write title" {...register(`services.${index}.title`, serviceSettingValidation['title'])} isInvalid={!!errors.services?.at(index)?.title}
                                    onMouseEnter={() => setIsDraggable(false)}
                                    onMouseLeave={() => setIsDraggable(true)}
                                />
                            </Col>
                        </Form.Group>
                        <Row className="mb-3">
                            <Col sm={3}>
                            </Col>
                            <Col sm={9}>
                                <Form.Text>
                                    {errors.services?.at(index)?.title && <span className='error-msg'>{errors.services?.at(index)?.title?.message}</span>}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Description</Form.Label>
                            <Col sm={9}>
                                <Form.Control as="textarea" rows={3} placeholder="Write description...." {...register(`services.${index}.description`, serviceSettingValidation['description'])} isInvalid={!!errors.services?.at(index)?.description}
                                    onMouseEnter={() => setIsDraggable(false)}
                                    onMouseLeave={() => setIsDraggable(true)}
                                />
                            </Col>
                        </Form.Group>
                        <Row className="mb-3">
                            <Col sm={3}>
                            </Col>
                            <Col sm={9}>
                                <Form.Text>
                                    {errors.services?.at(index)?.description && <span className='error-msg'>{errors.services?.at(index)?.description?.message}</span>}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Form.Group controlId="formFile" as={Row}>
                            <Form.Label column sm={3}>Upload Logo</Form.Label>
                            <Col sm={5}>
                                <Form.Control as="input" type="file" {...register(`services.${index}.logo`, serviceSettingValidation['logo'])} onChange={(e) => handleFileChange(e, index)} isInvalid={!!errors.services?.at(index)?.logo} />
                            </Col>
                            <Col md={3}>
                                {selectedImages[index] && <img className="service-logo" src={selectedImages[index]} alt="" />}
                                {selectedImages[index] === "" && <ServiceLogo id={service.settingId} withCachedLogos={withCachedLogos} onSuccessLogos={onSuccessLogos} />}
                            </Col>
                        </Form.Group>
                        <Row className="mb-3">
                            <Col sm={3}>
                            </Col>
                            <Col sm={9}>
                                <Form.Text>
                                    {errors.services?.at(index)?.logo && <span className='error-msg'>{errors.services?.at(index)?.logo?.message}</span>}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Row>

                </Container>
            </Card></Col>
        </Row>
    </>)
}

export default ServiceItem;