import React from 'react';
import { useRequestOrderFingerprintsMutation } from '../../services';
import { useAppDispatch } from '../../hooks';
import { notificationActions } from '../../store/slices/notificationSlice';
import { requestOrderFingerprintsSuccessMessage } from '../../utils/confirmationMessage';
import { Loader} from '..';
import { Button } from 'react-bootstrap';

const RequestOrderFingerprints = ({orderId, orderCode, onSuccess}: {orderId: number, orderCode: string, onSuccess: () => void}) => {
  const [requestOrderFingerprints, { data: requestOrderFingerprintsResponse, isSuccess, isLoading }] = useRequestOrderFingerprintsMutation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (requestOrderFingerprintsResponse?.Data) {
      dispatch(notificationActions.set({
          view: true,
          autoHide: true,
          state: 'success',
          text: requestOrderFingerprintsSuccessMessage(orderCode),
          title: 'Success!'
      }));
    }
    // eslint-disable-next-line
  }, [requestOrderFingerprintsResponse]);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleReqeustOrderFingerprints = () => {
    requestOrderFingerprints({id: orderId});
  }

  return (
    <>
      {isLoading && <Loader />}
      <Button className='request-order-fingerprints-btn' size='lg' onClick={handleReqeustOrderFingerprints}>Retrieve Fingerprint</Button>
    </>
  );
}

export default RequestOrderFingerprints;