export enum FingerprintAnnotation {
  None = 0,
  MissingAtBirth = 1,
  Severed = 2,
  FullyAmputated = 3,
  TipAmputated = 4,
  FingerDamaged = 5,
  FingerWorn = 6
}

export const FingerprintAnnotationDescription: Record<FingerprintAnnotation, string> = {
  [FingerprintAnnotation.None]: 'None',
  [FingerprintAnnotation.MissingAtBirth]: 'MissingAtBirth',
  [FingerprintAnnotation.Severed]: 'Severed',
  [FingerprintAnnotation.FullyAmputated]: 'FullyAmputated',
  [FingerprintAnnotation.TipAmputated]: 'TipAmputated',
  [FingerprintAnnotation.FingerDamaged]: 'FingerDamaged',
  [FingerprintAnnotation.FingerWorn]: 'FingerWorn'
}
