import React from 'react'
import { Loader, Modal } from '..'
import { validationRules } from '../../utils/formValidator';
import { useResubmitOrderMutation } from '../../services';
import { useAppDispatch } from '../../hooks';
import { Button, Form } from 'react-bootstrap';
import {  IOrder, OrderType, ResubmissionType } from '../../core';
import { OrderResubmitConfirmMessage, OrderResubmitSuccessMessage } from '../../utils/confirmationMessage';
import { useForm } from 'react-hook-form';
import { notificationActions } from '../../store/slices/notificationSlice';

interface FormState {
    id: number,
    oAti: string,
    resubmissionType: ResubmissionType,
}
const ResubmitOrder = ({ order, refreshCallback, setIsShipped}: { order: IOrder, refreshCallback : () => void, setIsShipped: (isShipped: boolean) => void}) => {
    const { register, getValues, formState: { errors, isValid }, clearErrors, unregister} = useForm<FormState>({ mode: 'all', defaultValues: {resubmissionType: ResubmissionType.CERTIFIX} });
    const [resubmitOrder, { data: resubmitOrderResponse, isLoading: resubmitOrderLoading }] = useResubmitOrderMutation();
    const dispatch = useAppDispatch();
    const [showResubmitModal, setShowResubmitModal] = React.useState<boolean>(false)
    const [selectedResubmissionType, setSelectedResubmissionType]  =  React.useState<ResubmissionType>(ResubmissionType.CERTIFIX)

    const closeModal = () => {
        setShowResubmitModal(false)
        setSelectedResubmissionType(ResubmissionType.CERTIFIX);
        unregister("oAti");
    };
        
    const showModal = () => setShowResubmitModal(true)
    const handleConfirmResubmission = () => {
        resubmitOrder({ id: order.Id, orderTypeId: order.OrderTypeId, oAti: getValues().oAti, resubmissionTypeId: selectedResubmissionType })
    } 
    const resubmitValidationRules = validationRules<{ oAti: string }>({
        oAti: { required: true, pattern: 'oAti' , displayName : "OATI Number" }
    });

    const handleSelectResubmissionType = (event: React.ChangeEvent<HTMLInputElement>) => {
        Number(event.target.value) === ResubmissionType.CERTIFIX && unregister("oAti");
        setSelectedResubmissionType( Number(event.target.value) );
    }
    React.useEffect(() => {
        if (resubmitOrderResponse?.Data) {
            closeModal();
            setIsShipped(false);
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: OrderResubmitSuccessMessage(order.ReferenceId),
                title: 'Success!'
            }))
            refreshCallback()
        }
        // eslint-disable-next-line
    }, [resubmitOrderResponse]);
  return (
<>
{(resubmitOrderLoading ) && <Loader />}
 <Button className='cancel-btn' size='lg' onClick={showModal}> Resubmit Order  </Button>
<Modal show={showResubmitModal} saveBtnTitle="Confirm Resubmission" title="Resubmit Order"
                onSave={handleConfirmResubmission} onClose={closeModal} isDisabledBtn={!isValid}>
                <>
                    { order.OrderTypeId === OrderType.CA ? (
                        <Form.Group className="mb-3">
                          <Form.Check
                              {...register("resubmissionType")}
                              inline
                              label="Certifix"
                              type="radio"
                              name='resubmission-type'
                              value={ResubmissionType.CERTIFIX}
                              id={`inline-1`}
                              onChange= {handleSelectResubmissionType}
                              defaultChecked= {true}
                               

                          />
                          <Form.Check
                              {...register("resubmissionType")}
                              inline
                              label="DOJ"
                              name="resubmission-type"
                              type="radio"
                              value={ResubmissionType.DOJ}
                              id={`inline-2`}
                              onChange= {handleSelectResubmissionType}

                          />
                           {
                            selectedResubmissionType === ResubmissionType.DOJ && (
                                <div className='oati'>
                                <Form.Label> OATI Number * </Form.Label>
                                <Form.Control className="input-control" {...register('oAti', resubmitValidationRules['oAti'])} isInvalid={!!errors.oAti} type="text" onChange={() => clearErrors('oAti')}/>
                                <Form.Text>
                                    {errors.oAti && <span className='error-msg'>{errors.oAti.message}</span>}
                                </Form.Text>
                            </div>
                            )
                           }
                        </Form.Group>
                    ) : (
                        OrderResubmitConfirmMessage()
                    )}
                </>
            </Modal >
</>  )
}

export default ResubmitOrder