import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useForm, SubmitHandler } from "react-hook-form";
import { IRegisterUser } from '../../core';
import { useRegisterUserMutation } from '../../services';
import { formValidator, validationRules } from '../../utils/formValidator';
import { Loader } from '..';
import { RequiredErrorMessage } from '../../utils/errorMessage';
import { Navigate } from 'react-router-dom';

const RegisterationForm = ({ email, invitationCode }: { email: string, invitationCode: string }) => {

    const [registerUser, { isLoading, data }] = useRegisterUserMutation();
    const { register, handleSubmit, formState: { errors, isValid } } = useForm<IRegisterUser>({
        mode: 'all',
        defaultValues: { invitationCode: invitationCode }
    });
    const onSubmit: SubmitHandler<IRegisterUser> = (user) => registerUser(user);
    const registerationRules = validationRules<{ [key: string]: string }>({
        password: formValidator.password,
        firstName: { required: true, maxLength: 30, pattern: 'alphaCharactersDashsSpaces', displayName: 'First name' },
        lastName: { required: true, maxLength: 30, pattern: 'alphaCharactersDashsSpaces', displayName: 'Last name' }
    });
    return (
        <>
            {isLoading && <Loader />}
            {data?.Data && <Navigate to="/confirmation" />}
            <Row className="registeration-row">
                <div className="registeration-form my-3 p-5">
                    <Stack gap={4}>
                        <div className="my-4">
                            <h5 className="text-center title">
                                Welcome
                            </h5>
                            <h6 className="text-center sub-title">
                                You have been invited to certifix live scan's team on sterling dashboard
                            </h6>
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col className="md-6">
                                    <Form.Group className="mb-3 px-4" controlId="firstName">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control className="input-control" {...register("firstName", registerationRules.firstName)} isInvalid={!!errors.firstName} type="text" />
                                        <Form.Text>
                                            {errors.firstName && <span className='error-msg'>{errors.firstName.message}</span>}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col className="md-6">
                                    <Form.Group className="mb-3 px-4" controlId="lastName">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control className="input-control" {...register("lastName", registerationRules.lastName)} isInvalid={!!errors.lastName} type="text" />
                                        <Form.Text>
                                            {errors.lastName && <span className='error-msg'>{errors.lastName.message}</span>}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="md-6">
                                    <Form.Group className="mb-5 px-4" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control className="input-control" type="email" placeholder={email} disabled />
                                    </Form.Group>
                                </Col>
                                <Col className="md-6">
                                    <Form.Group className="mb-5 px-4" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control className="input-control" {...register("password", registerationRules.password)} isInvalid={!!errors.password} type="password" />
                                        <Form.Text>
                                            {errors.password && <span className='error-msg'>{errors.password.message}</span>}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <Form.Group className="mb-3 px-4" controlId="accept">
                                        <Form.Check type="checkbox"  {...register("accept", { required: RequiredErrorMessage('Agreement') })} label="I understand and agree that my use of this services is subject to Certifix live scan" />
                                        <Form.Text>
                                            {errors.accept && <span className='error-msg'>{errors.accept.message}</span>}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="container-form-center">
                                <Button className="submit-btn" size="lg" type="submit" disabled={!isValid}>
                                    Join Account
                                </Button>
                            </div>
                        </Form>
                    </Stack>
                </div>
            </Row>
        </>
    )
}

export default RegisterationForm