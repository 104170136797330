import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Loader, Modal } from '..';
import { IRetentionValue, SettingEnum } from '../../core';
import { useForm } from 'react-hook-form';
import { validationRules } from '../../utils/formValidator';
import { useAppDispatch } from '../../hooks';
import { useUpdateSettingMutation } from '../../services';
import { notificationActions } from '../../store/slices/notificationSlice';
import { UpdateRetentionSettingConfirmationMessage } from '../../utils/confirmationMessage';
import "./retentionSetting.scss";

interface IRetentionModalProps {
    showModal: boolean,
    onCloseModal: (showModal: boolean) => void,
    retentionValue: IRetentionValue,
    onUpdateRetentionValue: (values: IRetentionValue) => void
}

const RetentionSettingModal = ({showModal, onCloseModal, retentionValue, onUpdateRetentionValue}: IRetentionModalProps) => {
    const { register, reset, getValues, setValue, formState: { errors } } = useForm<IRetentionValue>({ mode: 'all', defaultValues: { retentionDays: retentionValue.retentionDays} });

    const [updateRetentionSetting, { data, isLoading }] = useUpdateSettingMutation();
    const dispatch = useAppDispatch();

    const RetentionSettingValidation = validationRules<{ retentionDays: number }>({
        retentionDays: { required: true, minValue: 30, maxValue: 1000, pattern: 'positiveNegativeNumbers', displayName: 'Retention Days' },
    });
    const closeModal = () => {
        reset();
        onCloseModal(false);
    };
    const saveModalHandler = () => {
        updateRetentionSetting({ id: SettingEnum.RetentionDaysSettings, value : {retentionDays: getValues().retentionDays} });
    }

    const submitHandler = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        saveModalHandler();
    }

    React.useEffect(() => {
        if (data?.Data) {
            closeModal();
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: UpdateRetentionSettingConfirmationMessage(),
                title: 'Success!'
            }));
            onUpdateRetentionValue(data.Data.Value)
        }
        // eslint-disable-next-line 
    }, [data])

    React.useEffect(() => {
        if (showModal) {
            setValue("retentionDays", retentionValue.retentionDays);
        }
        // eslint-disable-next-line 
    }, [showModal])
    return (
        <>
            {(isLoading) && <Loader />}
            <Modal show={showModal} saveBtnTitle={"Save"} title={"Retention Setting"}
                onSave={saveModalHandler} onClose={closeModal} >
                <>
                    <Form onSubmit={submitHandler} >

                        <Form.Group as={Row} className="mb-3" >
                            <Row>
                                <Form.Label column sm={4} className='retention-title'>Retention Days</Form.Label>
                                <Col sm={6} className='retention-input'>
                                    <Form.Control className="input-control" {...register('retentionDays', RetentionSettingValidation['retentionDays'])} isInvalid={!!errors.retentionDays} type="text" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                </Col>
                                <Col sm={8}>
                                    <Form.Text>
                                        {errors.retentionDays && <span className='error-msg'>{errors.retentionDays.message}</span>}
                                    </Form.Text>
                                </Col>
                            </Row>
                        
                        </Form.Group>
                    </Form>

                </>
            </Modal >
        </>)
}

export default RetentionSettingModal