import React from 'react';
import { patternRgx, validationKeys } from '../utils/formValidator';
import { MaxLengthErrorMessage } from '../utils/errorMessage';

export const useValidationErrorMessages = (displayName: string, validationKeys: validationKeys): (input: string) => string[] => {
    return React.useMemo(() => (input: string): string[] => {
      const result: string[] = [];
      if (validationKeys['maxLength'] && input.length > validationKeys['maxLength']) {
          const maxLength = validationKeys['maxLength'];
          result.push(MaxLengthErrorMessage(displayName, maxLength));
      }
      if (validationKeys['pattern'] && input.length && !patternRgx[validationKeys['pattern']].value.test(input)) {
          const pattern = validationKeys['pattern'];
          result.push(patternRgx[pattern].message);
      }
      return result;
  }, [displayName, validationKeys]);
}