export enum OrderStatus {
    NEW = 1,
    COMPLETED = 2,
    CANCELED = 3,
    RESUBMITTED = 4,
    UNDER_RETRIAL = 5,
    COMPLETED_REFUNDED = 6,

}

export enum SterlingStatus {
    UNKNOWN = 0,
    NEW = 1,
    INPROGRESS = 2,
    CANCELED = 3,
    COMPLETED = 4
}


export const OrderStatusDescription: Record<OrderStatus, string> = {
    [OrderStatus.NEW]: 'New',
    [OrderStatus.COMPLETED]: 'Completed',
    [OrderStatus.CANCELED]: 'Canceled',
    [OrderStatus.RESUBMITTED]: 'Resubmitted',
    [OrderStatus.UNDER_RETRIAL]: 'Under Retrial',
    [OrderStatus.COMPLETED_REFUNDED]: 'Completed Refunded',
}
export const SterlingStatusDescription: Record<SterlingStatus, string> = {
    [SterlingStatus.UNKNOWN]: 'Unknown',
    [SterlingStatus.NEW]: 'New',
    [SterlingStatus.INPROGRESS]: 'InProgress',
    [SterlingStatus.COMPLETED]: 'Completed',
    [SterlingStatus.CANCELED]: 'Canceled',

}
export const StatusbgClass: Record<OrderStatus, React.CSSProperties> = {
    [OrderStatus.NEW]: { backgroundColor: 'rgb(238, 182, 60)' },
    [OrderStatus.COMPLETED]: { backgroundColor: 'rgb(15, 179, 35)' },
    [OrderStatus.CANCELED]: { backgroundColor: 'rgb(210, 20, 10)' },
    [OrderStatus.RESUBMITTED]: { backgroundColor: 'rgb(111, 136, 199)' },
    [OrderStatus.UNDER_RETRIAL]: { backgroundColor: 'rgb(44, 104, 187)' },
    [OrderStatus.COMPLETED_REFUNDED]: { backgroundColor: 'rgb(210, 20, 10)' },

}

export const OrderStatusLabel: { [key: string]: string } = {
    [OrderStatus.NEW]: 'New',
    [OrderStatus.COMPLETED]: 'Completed',
    [OrderStatus.CANCELED]: 'Canceled',
    [OrderStatus.RESUBMITTED]: 'Resubmitted',
    [OrderStatus.UNDER_RETRIAL]: 'Under Retrial', 
    [OrderStatus.COMPLETED_REFUNDED]: 'Completed Refunded'
}