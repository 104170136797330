import React from 'react'
import { validateRange } from '../../../utils/formValidator';
import { Input } from '../..';
import "./rangeFilter.scss";

interface IRangeFilterProps {
    start: string,
    end: string,
    startRangeChange: (value: string) => void,
    endRangeChange: (value: string) => void,
    onSubmit: (start: number | undefined, end: number | undefined) => void,
    onError: (value: string) => void
}

const RangeFilter = ({ start, startRangeChange, end, endRangeChange, onSubmit, onError }: IRangeFilterProps) => {

    const handleStartRange = (value: string) => {
        startRangeChange(value);
        const { isValid, message } = validateRange(value, end)
        if (isValid) {
            onSubmit(value ? Number(value) : undefined, end ? Number(end) : undefined)
        }
        onError(message)
    };
    const handleEndRange = (value: string) => {
        endRangeChange(value);
        const { isValid, message } = validateRange(start, value)
        if (isValid) {
            onSubmit(start ? Number(start) : undefined, value ? Number(value) : undefined)
        }
        onError(message)
    }

    return (
        <div className='filter-range-container'>

            <Input placeHolder='Start Range' value={start} setValue={startRangeChange} onSearch={(e) => handleStartRange(e)} />
            <span>To</span>
            <Input placeHolder='End Range' value={end} setValue={endRangeChange} onSearch={(e) => handleEndRange(e)} />


        </div>
    )
}

export default RangeFilter