import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { getLowerLevelRoles, IInviteUser, Role, RolesDescription } from '../../core';
import { formValidator, validationRules } from '../../utils/formValidator';
import { useInviteUserMutation } from '../../services';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { notificationActions } from '../../store/slices/notificationSlice';
import { InvitationMailSentSuccessMessage, NotificationSuccessTitle } from '../../utils/confirmationMessage';
import { Loader, Select, Modal } from '..';
import "./team.scss";
import { userSelector } from '../../store/slices/userSlice';

interface InviteUserModalProps {
    showModal: boolean;
    onSuccess: () => void,
    onClose: () => void,
}
const InviteUserModal = ({ showModal, onClose, onSuccess }: InviteUserModalProps) => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector(userSelector);

    const { register, getValues, reset, formState: { errors, isValid } } = useForm<IInviteUser>({ mode: 'all' });
    const [inviteUser, { isLoading: isInvitingUser, data: userInvited }] = useInviteUserMutation();
    const [userRoleId, setUserRoleId] = React.useState<{ label: string, value: number }>(RolesDescription[Role.NORMAL])

    const invitationRules = validationRules<{ email: string, roleId: string }>({
        email: formValidator.email,
        roleId: { required: true }
    })

    const sendInvitationHandler = () => {
        if (isValid) {
            inviteUser({ email: getValues('email'), roleId: userRoleId.value })
        }
    };
    const handleCloseModal = () => {
        reset(); //reset modal form whenever close modal.
        onClose()
    }

    React.useEffect(() => {
        if (userInvited?.Data) {
            onClose()
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: InvitationMailSentSuccessMessage(),
                title: NotificationSuccessTitle()
            }));
            reset()
            onSuccess()
        }
        // eslint-disable-next-line
    }, [userInvited]);

    return (
        <>
            {isInvitingUser && <Loader />}
            <Modal size='lg' show={showModal} title="Invite team member" saveBtnTitle="Send Invitation" onSave={sendInvitationHandler} onClose={handleCloseModal} isDisabledBtn={!isValid}>
                <Form >
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={2}>
                                <Form.Label>Email</Form.Label>
                            </Col>
                            <Col md={10}>
                                <Form.Control className="input-control" {...register('email', invitationRules['email'])} isInvalid={!!errors.email} type="email" size="lg" />
                                <Form.Text>
                                    {errors.email && <span className='error-msg'>{errors.email.message}</span>}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row className='select-role'>
                            <Col md={2}><span className='edit-label'> User Role</span></Col>
                            <Col md={4}>
                                <Select options={getLowerLevelRoles(userState.me?.RoleId || 0)} isMulti={false} value={userRoleId} placeHolder='User Role'
                                    onChange={(value) => setUserRoleId(value)} setValue={setUserRoleId} withCheckbox={false} withEmptyOption={false} 
                                ></Select>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal>
        </>)
}

export default InviteUserModal