import { Button, Form, Row, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { IResetPassword } from "../../core";
import { useResetPasswordMutation } from "../../services/apiService";
import { formValidator, validationRules } from "../../utils/formValidator";
import { Loader } from '..';
import "./forgetResetPassword.scss";
import { useAppDispatch } from "../../hooks";
import React from "react";
import { ResetPasswordSuccessMessage } from "../../utils/confirmationMessage";
import { notificationActions } from "../../store/slices/notificationSlice";
import { Navigate } from "react-router-dom";

const ResetPasswordForm = (props: { resetPasswordCode: string }) => {
    const [resetPassword, { data, isLoading }] = useResetPasswordMutation();
    const { register, getValues, handleSubmit, formState: { errors }, watch } = useForm<IResetPassword>({ mode: 'all', defaultValues: { resetPasswordCode: props.resetPasswordCode, password: "" } });
    const onSubmit: SubmitHandler<IResetPassword> = (formData) => resetPassword(formData);
    const dispatch = useAppDispatch();


    React.useEffect(() => {
        if (data?.Data) {
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: ResetPasswordSuccessMessage(),
                title: 'Success!'
            }))
        }
        // eslint-disable-next-line
    }, [data]);

    watch("password");
    const authValidationRules = validationRules<IResetPassword>({
        password: formValidator.password,
        confirmPassword: { comparePassword: getValues("password") }
    });

    return (
        <>
            {isLoading && <Loader />}
            {data?.Data && <Navigate to="/" />}
            <Row className="form-row">
                <div className="form my-4 pt-4">
                    <Stack gap={2}>
                        <h6 className="text-center title">
                            New Password
                        </h6>
                        <p className="mb-3 px-4 instruction">
                            Enter new strong password
                        </p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-5 px-4" controlId="password">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control className="input-control" {...register('password', authValidationRules['password'])} isInvalid={!!errors.password} type="password" />
                                <Form.Text>
                                    {errors.password && <span className='error-msg'>{errors.password.message}</span>}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-5 px-4" controlId="confirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control className="input-control" {...register('confirmPassword', authValidationRules['confirmPassword'])} isInvalid={!!errors.confirmPassword} type="password" />
                                <Form.Text>
                                    {errors.confirmPassword?.message && <span className='error-msg'>{errors.confirmPassword?.message}</span>}
                                </Form.Text>
                            </Form.Group>
                            <div className="container-wrapper mb-3">
                                <Button className="send-btn" size="lg" type="submit">
                                    Send
                                </Button>
                            </div>
                        </Form>
                    </Stack>
                </div>
            </Row>
        </>
    )
}

export default ResetPasswordForm;