import visaIcon from "../../../assets/images/visa.png";
import mastercardIcon from "../../../assets/images/master-card.png";
import discoverIcon from "../../../assets/images/discover.png";
import amexIcon from "../../../assets/images/american-express.png";
import paymentIcon from "../../../assets/images/payment-icon.png";

export enum CreditCardType {
    VISA = 1,
    MASTERCARD = 2,
    DISCOVER = 3,
    AMERICAN_EXPRESS = 4,
    OTHER = 5
}

export const CardTypeDescription: Record<CreditCardType, { discription: string, icon: string }> = {
    [CreditCardType.VISA]: { discription: 'VISA', icon: visaIcon },
    [CreditCardType.MASTERCARD]: { discription: 'MASTERCARD', icon: mastercardIcon },
    [CreditCardType.DISCOVER]: { discription: 'DISCOVER', icon: discoverIcon },
    [CreditCardType.AMERICAN_EXPRESS]: { discription: 'AMERICAN EXPRESS', icon: amexIcon },
    [CreditCardType.OTHER]: { discription: 'OTHER', icon: paymentIcon },
}