import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface NotificationState {
    view: boolean;
    state: 'danger' | 'success' | 'warning' | 'info';
    title: string | null;
    text: string | null;
    autoHide: boolean;
}

export const initialState: NotificationState = {
    view: false,
    state: 'info',
    title: null,
    text: null,
    autoHide: false,
};


export const notificationSlice = createSlice({
    name: 'notifaction',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<NotificationState>) => state = action.payload,
        reset: (state) => {
            state.view = initialState.view;
            state.state = initialState.state;
            state.title = initialState.title;
            state.text = initialState.text;
            state.autoHide = initialState.autoHide;
        }
    },
})

export const notificationActions = notificationSlice.actions;

export const notificationSelector = (state: RootState): NotificationState => state.notifaction;

export const notificationReducer = notificationSlice.reducer;