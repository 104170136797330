import React from 'react';
import { IUser } from '../../core';
import { ConfirmationDialog, Loader } from '..';
import { ResetAuthConfirmationMessage, ResetUserAuthSuccessMessage } from '../../utils/confirmationMessage';
import { useResetUserAuthMutation } from '../../services';

interface IResetUserAuthProps {
    user: IUser,
    onSuccess: () => void,
    onClose: (value: boolean) => void,
    showModal: boolean,

}
const ResetUserAuth = ({ user, onSuccess, onClose, showModal }: IResetUserAuthProps) => {
    const [resetUserAuth, { data, isLoading, isSuccess }] = useResetUserAuthMutation();
    const handleResetUserAuth = () => resetUserAuth({ id: user.Id });
    return (
        <>
            {isLoading && <Loader />}
            <ConfirmationDialog btnTitle='Reset' title='Reset User Auth' onSave={handleResetUserAuth} showModal={showModal} setShowModal={onClose}
                onSuccess={onSuccess} successMessage={ResetUserAuthSuccessMessage()} isSuccess={isSuccess} response={data}>
                <>
                    {ResetAuthConfirmationMessage()}
                </>
            </ConfirmationDialog>
        </>
    );
}

export default ResetUserAuth;