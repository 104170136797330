import { useParams } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Loader, LoggedOutHeader, ResetPasswordForm } from '..';
import './forgetResetPassword.scss';
import { useGetUserByResetPasswordCodeQuery } from '../../services';
import { InvalidResetPasswordCode } from '../../utils/errorMessage';
import { ErrorCodes, IRestResponse, IUser } from '../../core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const ResetPassword = () => {
    const { resetPasswordCode } = useParams<"resetPasswordCode">();
    const { data: user, isLoading, error } = useGetUserByResetPasswordCodeQuery({ resetPasswordCode: resetPasswordCode ?? '' });
    return (
        <>
            {isLoading && <Loader />}
            <LoggedOutHeader>
                <Container className="container-form-center">
                    {error && ((error as FetchBaseQueryError).data as IRestResponse<IUser>).Errors[0].Code === ErrorCodes.InvalidResetPasswordCode &&
                        <Row className="text-center mt-4 pt-4">
                            {InvalidResetPasswordCode()}
                        </Row>}
                    {user && <ResetPasswordForm resetPasswordCode={resetPasswordCode ?? ''} />}
                </Container>
            </LoggedOutHeader>
        </>
    )
}

export default ResetPassword