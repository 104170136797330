import { useParams } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Loader, LoggedOutHeader, RegisterationForm } from '..';
import './invitation.scss';
import { useGetUserByInvitationQuery } from '../../services';
import { InvalidLink } from '../../utils/errorMessage';
import { ErrorCodes, IRestResponse, IUser } from '../../core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const Invitation = () => {
    const { invitationCode } = useParams<"invitationCode">();
    const { data: invitedUser, isLoading, error } = useGetUserByInvitationQuery({ invitationCode: invitationCode ?? '' });
    return (
        <>
            {isLoading && <Loader />}
            <LoggedOutHeader>
                <Container className="container-form-center">
                    {error && ((error as FetchBaseQueryError).data as IRestResponse<IUser>).Errors[0].Code === ErrorCodes.InvalidInvitationLink &&
                        <Row className="text-center mt-4 pt-4">
                            {InvalidLink()}
                        </Row>}
                    {invitedUser && <RegisterationForm email={invitedUser.Data.Email} invitationCode={invitationCode ?? ''} />}
                </Container>
            </LoggedOutHeader>
        </>
    )
}

export default Invitation