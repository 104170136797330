import React from 'react';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { components, OptionProps, default as ReactSelect } from "react-select";
import { useDebcounceHook, useValidationErrorMessages } from '../../../hooks';
import { validationKeys } from '../../../utils/formValidator';
import './autoCompleteSelect.scss';

interface IAutoCompleteSelectProps<RequestParamsType, ResponseType> {
    name: string;
    value: any;
    isMulti: boolean;
    placeHolder: string;
    withCheckbox?: boolean;    
    setValue: (value: any) => void;
    getOptionsQuery: UseQuery<QueryDefinition<RequestParamsType, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, "", ResponseType, "api">>
    requestParamsConverter: (input: string) => RequestParamsType
    optionsConverter: (data: ResponseType | undefined) => {value: any, label: string}[];
    validationKeys: validationKeys;
    debounceTime?: number;
    onChange?: (value: any) => void;
}

function AutoCompleteSelect <RequestParamsType, ResponseType>(props: IAutoCompleteSelectProps<RequestParamsType, ResponseType>) {
    const handleChange = (e: any) => {
        props.setValue(e);
    }

    const [input, setInput] = React.useState<string>('');
    const [errorMessageValue, setErrorMessageValue] = React.useState<string>('');

    const validateInput = useValidationErrorMessages(props.name, props.validationKeys);

    const inputChangeHandler = useDebcounceHook((input: string) => {
        if (props.validationKeys) {
            const validationResult = validateInput(input);
            if (validationResult.length) {
                setErrorMessageValue(validationResult[validationResult.length - 1]);
                return;
            } else {
                setErrorMessageValue('');
            }
        }
        setInput(input);
    }, props.debounceTime);

    const { data } = props.getOptionsQuery(props.requestParamsConverter(input), { skip: input.length === 0 });
    return (
        <>
            <ReactSelect
                options={props.optionsConverter(data)}
                isMulti={props.isMulti}
                value={props.value}
                hideSelectedOptions={false} 
                closeMenuOnSelect={!props.isMulti}
                components={{ Option: (optionProps) => { return OptionComponent(optionProps, props.withCheckbox ?? true) } }}
                onChange={handleChange}
                onInputChange={inputChangeHandler}
                placeholder={props.placeHolder}
                classNamePrefix={'auto-complete-select'}
                menuIsOpen={input.length > 0 && !errorMessageValue && props.optionsConverter(data).length > 0}
            />
            <span className='error-message'>{errorMessageValue}</span>
        </>
    )
}

const OptionComponent = (props: OptionProps, showCheckbox: boolean) => {
    return (
        <div>
            <components.Option {...props}>
                {showCheckbox && <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />}
                {" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


export default AutoCompleteSelect;
