import 'bootstrap-daterangepicker/daterangepicker.css';
import _ from 'lodash';
import React, { useRef } from 'react';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ExportLocationsCsv, Loader, LocationItem, Pagination, Input, OrderTypeSelect, RangeFilter } from "..";
import { IGetLocationRequest, OrderOrPassThroughArray, PassThroughOrdersDescription, PassThroughOrderType, SortOptions } from '../../core';
import { useGetLocationsQuery } from '../../services';
import { MaxLengthErrorMessage, NoLocationRecord } from '../../utils/errorMessage';
import "./qualityControl.scss";
import moment from 'moment';
import { useDebcounceHook } from '../../hooks';
import { patternRgx } from '../../utils/formValidator';

const QualityControl = () => {
    const keyRef = useRef(Date.now());
    const dateFormats = {
        endpointFormat: "YYYY-MM-DD HH:mm:ss",
        displayFormat: "YYYY-MM-DD"
    }
    const passThroughOrders: PassThroughOrderType[] = Object.values(PassThroughOrderType).filter((v) => !isNaN(Number(v))) as PassThroughOrderType[];
    const [requestParams, setParams] = React.useState<IGetLocationRequest>({ sortBy: SortOptions.SortByErrorRate, sortOrder: SortOptions.SortAscending, withErrorRate: true, orderTypes: passThroughOrders, withCompletedTransactions: true, withResubmittedTransactions: true });
    const { data: locations, isLoading, isFetching } = useGetLocationsQuery(requestParams, { refetchOnMountOrArgChange: true });
    const [sortOrderValue, setSortOrder] = React.useState<SortOptions>(requestParams.sortOrder ?? SortOptions.SortDescending);
    // eslint-disable-next-line 
    const [sortBy, setSortBy] = React.useState<SortOptions>(requestParams.sortBy ?? SortOptions.SortByErrorRate);
    const [searchTerm, setSearchTerm] = React.useState<string>(requestParams.searchTerm ?? '');
    const [searchErrorMessage, setSearchErrorMessage] = React.useState<string>('');
    const [orderType, setOrderType] = React.useState<PassThroughOrderType[] | null>(null);
    const [completedTransactionsStartRange, setCompletedTransactionsStartRange] = React.useState<string>('');
    const [completedTransactionsEndRange, setCompletedTransactionsEndRange] = React.useState<string>('');
    const [rangeErrorMessage, setRangeErrorMessage] = React.useState<string>('');


    const changeDateRange: any = (event: any, picker: any) => {
        const dateFrom = picker.startDate.format(dateFormats.endpointFormat);
        const dateTo = picker.endDate.format(dateFormats.endpointFormat);

        setParams((prev) => ({ ...prev, dateFrom: dateFrom, dateTo: dateTo, page: undefined }));
    }

    const handleTransactionRange = (startRange: number | undefined, endRange: number | undefined) => {
        setParams((prev) => ({ ...prev, completedTransactionEndRange: endRange, completedTransactionStartRange: startRange, page: undefined }));
    }

    const debounceSearch = useDebcounceHook((search: string) => {
        if (searchTermValidator(search)) {
            if (!search) {
                setParams((prev) => ({ ...prev, searchTerm: undefined, page: undefined }));
            }
            else {
                setParams((prev) => ({ ...prev, searchTerm: search, page: undefined }));
            }
        }

    });
    const searchTermValidator = (searchTerm: string) => {
        if (searchTerm.length >= 50) {
            setSearchErrorMessage(MaxLengthErrorMessage('Search', 50));
            return false;
        }
        if (searchTerm && !patternRgx["alphaNumericSpaceAndDashes"].value.test(searchTerm)) {
            setSearchErrorMessage(patternRgx["alphaNumericSpaceAndDashes"].message);
            return false;
        }
        setSearchErrorMessage('');
        return true;
    }

    const changeOrderType = React.useMemo(() => (selectedOrderType: unknown) => {
        const type = selectedOrderType as { value: number, label: string }
        setParams((prev) => ({ ...prev, orderTypes: type.value ? [type.value] : passThroughOrders, page: undefined }))

    }, [passThroughOrders]);
    const handleSelectOrderType = (value: OrderOrPassThroughArray | null) =>  setOrderType(value as PassThroughOrderType[])

    const clearSearch = () => {
        keyRef.current = Date.now();
        setSortOrder(SortOptions.SortAscending)
        setSortBy(SortOptions.SortByErrorRate)
        setParams({ sortBy: SortOptions.SortByErrorRate, sortOrder: SortOptions.SortAscending, withErrorRate: true, orderTypes: passThroughOrders, withCompletedTransactions: true, withResubmittedTransactions: true });
        setSearchTerm('');
        setOrderType(null);
        setCompletedTransactionsEndRange("")
        setCompletedTransactionsStartRange("")
        setSearchErrorMessage('')
        setRangeErrorMessage('')
    }
    const handleSorting = (orderBy: number) => {
        const newSortOrder = sortOrderValue === SortOptions.SortDescending ? SortOptions.SortAscending : SortOptions.SortDescending;
        setSortBy(orderBy);
        setSortOrder(newSortOrder);
        setParams(prev => ({ ...prev, sortBy: orderBy, sortOrder: newSortOrder, page: undefined }));
    };
    const displayDateRange = () => `${moment(requestParams.dateFrom).format(dateFormats.displayFormat)} - ${moment(requestParams.dateTo).format(dateFormats.displayFormat)}`;

    const getSortIcon = () => {
        if (sortOrderValue === SortOptions.SortDescending) {
            return (<i className='fa fa-angle-down sort-icon'></i>)
        }
        return (<i className='fa fa-angle-up sort-icon'></i>)

    }

    return (
        <Container fluid className="px-4">
            <Stack gap={3}>
                <div>
                    {(isLoading || isFetching) && <Loader />}
                    <Stack gap={2} direction="horizontal">
                        <Col md={6}>
                            <DateRangePicker key={keyRef.current} onApply={changeDateRange} initialSettings={{ autoUpdateInput: false }}>
                                <input type="text" className="form-control location-date-range-picker" placeholder={`${(requestParams.dateFrom && requestParams.dateTo) ? "" : "Select Date Range"} `}
                                    value={(requestParams.dateFrom && requestParams.dateTo) && displayDateRange()} />
                            </DateRangePicker>
                        </Col>
                        <Col md={6}  >
                            <Input onSearch={((search: string) => debounceSearch(search))} value={searchTerm}
                                setValue={setSearchTerm} placeHolder="Search by Business Name, City or State">
                                <i className="fa fa-search"></i>
                            </Input>

                        </Col>
                    </Stack>
                    <Stack  gap={2} direction="horizontal">
                        <Col md={6}></Col>
                        <Col md={6}>  <span className='error-message'>{searchErrorMessage}</span>
                        </Col>
                    </Stack>
                    <Stack>
                        <Row md={6} xs={12} className="mt-md-2">
                            <Col lg={4} md={4} xs={12}>
                                <OrderTypeSelect onChange={changeOrderType} onSelect={handleSelectOrderType} value={orderType} options={PassThroughOrdersDescription} />
                            </Col>
                            <Col md={4} >
                                <RangeFilter start={completedTransactionsStartRange} startRangeChange={setCompletedTransactionsStartRange}
                                    end={completedTransactionsEndRange} endRangeChange={setCompletedTransactionsEndRange}
                                    onSubmit={handleTransactionRange} onError={setRangeErrorMessage} />
                                <span className='error-message'>{rangeErrorMessage}</span>
                            </Col>
                            <Col md={2}>
                                <Button variant="outline-danger" onClick={clearSearch} className='pull-right'>
                                    <i className="fa fa-ban "></i>  Clear</Button>
                            </Col>

                            <Col md={2}>
                                <ExportLocationsCsv exportCsvParams={requestParams} />
                            </Col>

                        </Row>
                    </Stack>

                </div>

                <div className="location-grid-header pt-4">
                    <Col>Location Id</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByBusinessName)}>Business Name {(sortBy === SortOptions.SortByBusinessName) && getSortIcon()}</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByCity)}>City {(sortBy === SortOptions.SortByCity) && getSortIcon()}</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByState)}>State {(sortBy === SortOptions.SortByState) && getSortIcon()}</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByResubmittedTransactions)} >Resubmitted Transactions {(sortBy === SortOptions.SortByResubmittedTransactions) && getSortIcon()}</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByCompletedTransactions)}>Completed Transactions {(sortBy === SortOptions.SortByCompletedTransactions) && getSortIcon()}</Col>
                    <Col onClick={() => handleSorting(SortOptions.SortByErrorRate)}>Error Rate {(sortBy === SortOptions.SortByErrorRate) && getSortIcon()}</Col>
                </div>
                <div>
                    {(!locations?.Data.Data || locations?.Data.Data.length === 0) &&
                        (
                            <div className="bg-light grid-row py-3 align-items-center">
                                <div className="mx-3">{NoLocationRecord()}</div>
                            </div>
                        )
                    }
                    {
                        _.map(locations?.Data.Data, (value, index) => {
                            return (
                                <div key={value.Id}> <LocationItem location={value} /> </div>
                            )
                        })
                    }
                </div>
                {/* Pagination */}
                <Pagination currentPage={locations?.Data.CurrentPage ?? 1} totalPages={locations?.Data.TotalPages ?? 1} total={locations?.Data.Total ?? 0}
                    onClick={(page: number) => { setParams((prev) => ({ ...prev, page })); }} />
            </Stack>
        </Container>
    )
}

export default QualityControl