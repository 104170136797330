import { alphaNumericAndMoreSpecialErrorMessage, alphanumericErrorMessage, charSpaceDashErrorMessage, confirmPasswordErrorMessage, MaxLengthErrorMessage, MinLengthErrorMessage, passwordErrorMessage, PatternErrorMessage, RequiredErrorMessage, SearchPatternErrorMessage, numericErrorMessage , alphaCharactersDashs, oAtiErrorMessage, digitsStringErrorMessage, invalidPhoneNumberFormat, alphaNumericAndSpaceMessage, fileErrorMessage, maxValueErrorMessage, minValueErrorMessage, alphaNumericSpaceAndDashErrorMessage, rangeErrorMessage, rangeBoundaryErrorMessage,exactLengthErrorMessage} from "./errorMessage"
import { RegisterOptions } from "react-hook-form";

const patternRGxKeys: Record<string, RegExp> = {
    email: /^[a-zA-Z0-9'_%+^#&$*!-][a-zA-Z0-9.'_%+^#&$*!-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*_?&-])[A-Za-z\d@$!%*_?&-]{8,30}$/,
    numberCharactersAndSpecial: /^[a-zA-Z0-9-,'. ]+$/,
    alphaCharactersDashsSpaces: /^[a-zA-Z- ]+$/,
    alphaNumeric: /^[a-zA-Z0-9]+$/,
    alphaNumericAndMoreSpecial: /^[a-zA-Z0-9-()$&@!?,_#%^*+=|/ ]+$/,
    positiveNegativeNumbers: /^-?\d+?$/,
    alphaCharactersDashs: /^[A-Za-z0-9-]+$/,
    oAti: /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{3}[0-9]{3}$/,
    digitsString: /^[0-9]+$/,
    phoneNumber: /^\+\d{6,14}$/,
    alphaNumericSpacesPeriodCommaDashUnderscoreParentheses: /^[a-zA-Z0-9-_.,/() ]+$/,
    alphaNumericSpacesAndDashes : /^[a-zA-Z0-9- ]+$/,

}

export const patternRgx: Record<string, { value: RegExp, message: string }> = {
    email: {
        value: patternRGxKeys.email,
        message: PatternErrorMessage('Email'),
    },
    password: {
        value: patternRGxKeys.password,
        message: passwordErrorMessage()
    },
    numberCharactersAndSpecial: {
        value: patternRGxKeys.numberCharactersAndSpecial,
        message: SearchPatternErrorMessage()
    },
    alphaCharactersDashsSpaces: {
        value: patternRGxKeys.alphaCharactersDashsSpaces,
        message: charSpaceDashErrorMessage()
    },
    alphaNumeric: {
        value: patternRGxKeys.alphaNumeric,
        message: alphanumericErrorMessage()
    },
    shippingNotes: {
        value: patternRGxKeys.alphaNumericAndMoreSpecial,
        message: alphaNumericAndMoreSpecialErrorMessage('Notes', '-()$&@!?,_#%^*+=|/')
    },
    cancelAndRefundNote: {
        value: patternRGxKeys.alphaNumericAndMoreSpecial,
        message: alphaNumericAndMoreSpecialErrorMessage('Note', '-()$&@!?,_#%^*+=|/')
    },
    positiveNegativeNumbers: {
        value: patternRGxKeys.positiveNegativeNumbers,
        message: numericErrorMessage(),
    },
    alphaCharactersDashs: {
        value: patternRGxKeys.alphaCharactersDashs,
        message: alphaCharactersDashs()
    },
    oAti: {
        value: patternRGxKeys.oAti,
        message: oAtiErrorMessage(),
    },
    digitsString: {
        value: patternRGxKeys.digitsString,
        message: digitsStringErrorMessage(),
    }, 
    phoneNumber: {
        value: patternRGxKeys.phoneNumber ,
        message: invalidPhoneNumberFormat()
    },
    alphaNumericAndSpaceTitle: {
        value: patternRGxKeys.alphaNumericSpacesPeriodCommaDashUnderscoreParentheses,
        message: alphaNumericAndSpaceMessage('Title')
    },
    alphaNumericAndSpaceDescription: {
        value: patternRGxKeys.alphaNumericSpacesPeriodCommaDashUnderscoreParentheses,
        message: alphaNumericAndSpaceMessage('Description')
    }, 
    alphaNumericSpaceAndDashes: {
        value: patternRGxKeys.alphaNumericSpacesAndDashes,
        message: alphaNumericSpaceAndDashErrorMessage()
    }
}

export type validationKeys = Partial<{
    displayName: string,
    required: boolean,
    minLength: number,
    maxLength: number,
    pattern: string,
    comparePassword: string,
    file: { extension: string[], size: number },
    minValue: number,
    maxValue: number,
    hasLength: number
}>

type validator<T> = { [key in keyof Partial<T>]: validationKeys }

export const validationRules = <T>(obj: validator<T>): { [key in keyof T]?: RegisterOptions<T> } => {
    let rules: { [key in keyof T]?: RegisterOptions<T> } = {};
    for (const key in obj) {
        const value = obj[key];
        const displayName = value['displayName'] ?? key
        let validator: RegisterOptions<T> = {};
        if (value['required']) {
            validator.required = RequiredErrorMessage(displayName);
        }
        if (value['minLength']) {
            const minLength = value['minLength'];
            validator.minLength = {
                value: minLength,
                message: MinLengthErrorMessage(displayName, minLength)
            };
        }
        if (value['maxLength']) {
            const maxLength = value['maxLength'];
            validator.maxLength = {
                value: maxLength,
                message: MaxLengthErrorMessage(displayName, maxLength)
            };
        }
        if (value['pattern']) {
            const pattern = value['pattern'];
            validator.pattern = {
                value: patternRgx[pattern].value,
                message: patternRgx[pattern].message
            }
        }
        if (value['comparePassword']) {
            const comparePassword = value['comparePassword'];
            validator.validate = (val) => val === comparePassword || confirmPasswordErrorMessage()
        }
        if (value['file']) {
            validator.validate = (val) => {
                const file = val as FileList;
                if (file[0]) {
                    //validate extension
                    if (value['file']?.extension) {
                        const fileExtension = file[0]?.name.split('.').pop()?.toLowerCase();
                        if (fileExtension && !value['file']?.extension.includes(fileExtension)) {
                            return fileErrorMessage();
                        }
                    }

                    //validate size
                    if (value['file']?.size) {
                        const fileSize = file[0].size;
                        if (fileSize > value['file']?.size) {
                            return fileErrorMessage();
                        }
                    }
                    return true;
                }
            }
        }
        if(value['minValue']){
            const minValue = value['minValue'];
            validator.min = {
                value: minValue,
                message: minValueErrorMessage(displayName, minValue)
            };
        }
        if(value['maxValue']){
            const maxValue = value['maxValue'];
            validator.max = {
                value: maxValue,
                message: maxValueErrorMessage(displayName, maxValue)
            };
        }
        if (value["hasLength"]) {
            validator.validate = (val) => {
                const length = value["hasLength"];
                const input = val as string
                return input.length === length || exactLengthErrorMessage(displayName,length)
            }
        } 

        rules[key] = validator;
    }
    return rules;
}

export const formValidator = {
    email: { required: true, maxLength: 100, minLength: 3, pattern: 'email', displayName: 'Email' },
    password: { required: true, minLength: 8, maxLength: 30, pattern: 'password', displayName: 'Password' },
}


export const validateRange = (startRange: string, endRange: string) : {isValid: boolean, message: string} => {
    const startRangeNum = Number(startRange);
    const endRangeNum = Number(endRange);
    if ((endRangeNum > 1000000 || startRangeNum > 1000000)) {
        return {isValid: false, message: maxValueErrorMessage("Filter Range", 1000000) }
    }
    if ((endRangeNum < 0 || startRangeNum < 0)) {
        return {isValid: false, message: minValueErrorMessage("Filter Range", 0) }
    }
    if ((endRange && !startRange) || (!endRange && startRange)) {
        return {isValid: false, message: rangeErrorMessage()}
    }

    if ((startRange || endRange) && (!patternRgx["digitsString"].value.test(startRange) || !patternRgx["digitsString"].value.test((endRange)))) {
        return {isValid: false, message: patternRgx['digitsString'].message}
    }
    if (endRangeNum < startRangeNum) {
        return {isValid: false, message: rangeBoundaryErrorMessage()}
    }
    return {isValid: true, message: ""}
}

