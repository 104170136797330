import React from 'react'
import { Card, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SecurityMethodsContainer = () => {
  return (
    <>
         <Container fluid className="px-4 my-4">
        <Card body>
          <h3 className='card-title title'>Security</h3>
          <Link to={"2fa"} className='link'  >
          <div className='two-factor-auth' >
            <span>Two-Factor Authentication</span>
            <span className='icon-2fa'><i className="fa fa-chevron-right"></i></span>
          </div>
          </Link>
        </Card>
      </Container>
    </>
  )
}

export default SecurityMethodsContainer