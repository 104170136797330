import { IOrderLocation } from "../../core";
import React from 'react';
import { useRequestOrderLocationMutation } from "../../services";
import Loader from "../shared/loader/Loader";
import './orderDetails.scss'
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { notificationActions } from "../../store/slices/notificationSlice";
import { invalidExternalLocationIdErrorMessage } from "../../utils/confirmationMessage";

const OrderLocation = ({ orderId, location, externalLocationId }
    : { orderId: number, location?: IOrderLocation, externalLocationId: string }) => {

    const [orderLocation, setOrderLocation] = React.useState<IOrderLocation | undefined>(location);

    const [requestOrderLocation, { data: requestOrderLocationResponse, isLoading }] = useRequestOrderLocationMutation();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (requestOrderLocationResponse?.Data) {
            setOrderLocation(requestOrderLocationResponse.Data)
        }
    }, [requestOrderLocationResponse]);


    const handleGetOrderLocation = () => {
        if (externalLocationId) {
            requestOrderLocation({ externalLocationId, orderId })
        } else {
            dispatch(notificationActions.set({
                view: true,
                state: 'danger',
                title: 'Error',
                text: invalidExternalLocationIdErrorMessage(),
                autoHide: true,
            }));
        }

    }

    return <>
        {isLoading && <Loader />}
        {orderLocation ?
            (`${orderLocation?.Name} - ${orderLocation?.Address1}, ` +
                (orderLocation?.Address2 ?? `${orderLocation?.Address2}, `) +
                `${orderLocation?.City}, ${orderLocation?.State}`)
            : <> N/A
                <Link to="" className="get-location-link"
                    onClick={() => handleGetOrderLocation()}>
                    Get Order Location
                </Link>
            </>}
    </>
}
export default OrderLocation;