import { OrderFingerPrint } from "../core";

export const scoreToQuality = ({QualityScore: score, Annotation: annotation}: OrderFingerPrint): { score: string, filledCount: number, filledColor: string, quality: string } => {
    if(score === null && annotation !== 0) {
        return { score: 'Annotated', filledCount: 0, filledColor: '', quality: '' };
    }
    if (score === null || score === undefined) {
        return { score: 'N/A', filledCount: 0, filledColor: '', quality: '' };
    }
    switch (true) {
        case score < 0:
            return { score: 'N/A', filledCount: 0, filledColor: '', quality: '' };
        case score <= 20:
            return { score: score?.toString(), filledCount: 1, filledColor: 'red', quality: 'Very Bad' };
        case score <= 40:
            return { score: score?.toString(), filledCount: 2, filledColor: 'red', quality: 'Bad' };
        case score <= 60:
            return { score: score?.toString(), filledCount: 3, filledColor: 'yellow', quality: 'Fair' };
        case score <= 80:
            return { score: score?.toString(), filledCount: 4, filledColor: 'green', quality: 'Good' };
        case score <= 100:
            return { score: score?.toString(), filledCount: 5, filledColor: 'green', quality: 'Very Good' };
        default:
            return { score: 'N/A', filledCount: 0, filledColor: '', quality: '' };
    }
}
