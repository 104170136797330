import React from 'react'
import { ICancelOrderRequest, IOrder, PaymentType } from '../../core'
import { Loader, RefundDialog} from '..'
import { useCancelOrderMutation } from '../../services';
import { CancelOrderSuccessMessage, confirmCancelationDialogMessage } from '../../utils/confirmationMessage';
import { Button } from 'react-bootstrap';
import { fireRefundEvent } from '../../utils/gtm';

const CancelOrder = ({order, onSuccess}: {order: IOrder, onSuccess: () => void}) => {
    const [cancelOrder, { data: cancelOrderResponse, isSuccess: isCancelled, isLoading: cancelOrderLoading }] = useCancelOrderMutation();
    const [showModal, setShowModal] =React.useState<boolean>(false);

    const title= order.paymentType === PaymentType.onApplicant ? "Cancel and Refund" : "Cancel";
    const btnTitle = order.paymentType === PaymentType.onApplicant ? 'Confirm Cancel and Refund' : 'Confirm Cancel';
    
    const initModal = () => setShowModal(true);

    const handleCancelOrder = (value: ICancelOrderRequest) => {
        cancelOrder(value);
    }
    React.useEffect(()=>{
        if(cancelOrderResponse?.Data && order.paymentType === PaymentType.onApplicant){
          fireRefundEvent(order);
        }
        // eslint-disable-next-line
      }, [cancelOrderResponse]);
  
  
    return (
        <>
            {cancelOrderLoading && <Loader />}
            <Button className='cancel-btn' size='lg' onClick={initModal} > {order.paymentType === PaymentType.onApplicant ? "Cancel and Refund" : "Cancel"} </Button>
            <RefundDialog 
                orderId={order.Id}
                confirmMessage={confirmCancelationDialogMessage(order.paymentType)}
                onRefund={handleCancelOrder}
                title={title}
                btnTitle={btnTitle}
                showModal={showModal}
                setShowModal={setShowModal}
                isSuccess={isCancelled}
                response={cancelOrderResponse}
                successMessage={CancelOrderSuccessMessage(order.ReferenceId)}
                onSuccess={onSuccess}
            />
        </>
    )
}

export default CancelOrder