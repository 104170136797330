import { Col, Dropdown, Row } from 'react-bootstrap'
import { IUser, Role, RegistrationType } from '../../core'
import * as React from "react";
import "./team.scss";
import { RemoveUser, EditUserModal, ResetUserAuth } from '..';
import { useIsAuthUserPermitted, useIsRolePermitted } from '../../hooks';

const UserItem = ({ User, onUpdate }: { User: IUser, onUpdate: () => void }) => {
    const isRolePermitted = useIsRolePermitted(User.RoleId)
    const isUserAdmin = useIsAuthUserPermitted(Role.ADMIN);

    const [currentUser, setCurrentUser] = React.useState(User);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showResetAuthModal, setResetAuthModal] = React.useState(false)

    const handleUpdateUser = (userData: IUser) => {
        setCurrentUser(userData);
        onUpdate()
    }


    return (
        <>
            <Row className="user-item">
                <div key={currentUser.Id} className="grid-item py-3 align-items-center">
                    <Col md={3} className='name-col'>{currentUser.FirstName} {currentUser.LastName}</Col>
                    <Col md={1}>  {currentUser.RoleId === Role.ADMIN ? <button className="admin-identifier" disabled>Admin</button> :
                        currentUser.RoleId === Role.SUPERVISOR ? <button className="admin-identifier" disabled>Supervisor</button> : ""}</Col>
                    <Col md={3} className='name-col hidden-in-responsive'>{currentUser.Email}</Col>

                    <Col md={2} className='d-flex'>

                        {currentUser.RoleId !== Role.ADMIN &&
                            <>
                                <div className={
                                    currentUser.RegistrationType === RegistrationType.APPLICATION ?
                                        'is-pending' : (currentUser.IsActive ? 'is-active' : 'inactive')} />
                                <div className='hidden-in-responsive'>
                                    {currentUser.RegistrationType === RegistrationType.ACCOUNT ?
                                        (currentUser.IsActive ? 'Active' : 'Inactive')
                                        : 'Pending Invitation'
                                    }
                                </div>
                            </>
                        }
                    </Col>
                    {(currentUser.RegistrationType !== RegistrationType.APPLICATION && isRolePermitted) ? <Col md={2}>
                        <span className='icon edit-icon' onClick={() => setShowEditModal(true)}>
                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </span>
                        <span className='icon delete-icon' onClick={() => setShowDeleteModal(true)}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </span>

                    </Col> : <Col md={2}></Col>}

                    <Col md={1}>
                        {(isUserAdmin && currentUser.RoleId !== Role.ADMIN && currentUser.RegistrationType !== RegistrationType.APPLICATION) &&
                            <div className="user-options">
                                <Dropdown>
                                    <Dropdown.Toggle >
                                        <div className="dots" >
                                            <div className="ellipse-7 mt-3" />
                                            <div className="ellipse-7" />
                                            <div className="ellipse-7" />
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => setResetAuthModal(true)}> Reset 2FA Secret </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                    </Col>
                </div>
            </Row >
            <RemoveUser onSuccess={onUpdate} setShowModal={setShowDeleteModal} showModal={showDeleteModal} user={currentUser} />
            <EditUserModal onSuccess={handleUpdateUser} showModal={showEditModal} onClose={setShowEditModal} user={currentUser} ></EditUserModal>
            <ResetUserAuth onSuccess={onUpdate} showModal={showResetAuthModal} user={currentUser} onClose={setResetAuthModal} />
        </>
    )
}

export default UserItem