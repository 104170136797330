import moment from "moment";
import React from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useForm } from "react-hook-form";
import { Loader, Modal } from "..";
import { ICreateOrderShipment, IOrderShipment, OrderType } from "../../core";
import { useAppDispatch } from "../../hooks";
import { useSetShippingInfoMutation } from "../../services";
import { notificationActions } from "../../store/slices/notificationSlice";
import { AccessCodeCreatedSuccessMessage, OrderShipmentCreatedSuccessMessage } from "../../utils/confirmationMessage";
import { validationRules } from "../../utils/formValidator";
import { FingerprintsNotAvailable } from "../../utils/errorMessage";

const Shipment = ({ orderTypeId, orderId, isDimmed, setOrderShipment, setIsShipped }: { orderTypeId: OrderType, orderId: number, isDimmed: boolean, setOrderShipment: (orderShipment: IOrderShipment) => void, setIsShipped: (isShipped: boolean) => void }) => {
    const dateFormates = {
        endpointFormat: "YYYY-MM-DD",
        displayFormat: "MM/DD/YYYY"
    }

    const dispatch = useAppDispatch();
    const [showShipmentModal, setShowShipmentModal] = React.useState<boolean>(false);
    const { register: registerShippingInfo, setValue: setValueShippingInfo, reset: resetShippingInfo, trigger: triggerShippingInfo, getValues: getValuesShippingInfo, formState: { errors: errorsShippingInfo, isValid } } = useForm<ICreateOrderShipment>({ mode: 'all', defaultValues: { orderId: orderId, shipDate: moment().format(dateFormates.endpointFormat) , orderTypeId : orderTypeId} });
    const [createShipment, { data: createShipmentfResponse, isLoading: createShipmentLoading }] = useSetShippingInfoMutation();
    
    const initShippingInfoModal = () => {
        resetShippingInfo();
        setShowShipmentModal(true);
    }

    const closeShippingInfoModal = () => {
        setShowShipmentModal(false);
    }

    const setShippingInfo = () => {
        createShipment(getValuesShippingInfo());
    }

    const shippingInfoValidationRules = validationRules<{ trackingNumber: string, notes?: string }>({
        trackingNumber : orderTypeId === OrderType.FBI ? 
        { required: true, maxLength: 20, pattern: 'alphaCharactersDashs', displayName: 'Access Code' } :
        { required: true, maxLength: 50, pattern: 'alphaCharactersDashs', displayName: 'Tracking Number' } ,

        notes: { maxLength: 100, pattern:'shippingNotes', displayName: 'Notes' }
    });

    const changeShipDateHandler = (date: string) => {
        setValueShippingInfo('shipDate', moment(date).format(dateFormates.endpointFormat));
        triggerShippingInfo()
    }

    React.useEffect(() => {
        if (createShipmentfResponse?.Data) {
            closeShippingInfoModal();
            setOrderShipment(createShipmentfResponse?.Data);
            setIsShipped(true);
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: orderTypeId === OrderType.FBI ? AccessCodeCreatedSuccessMessage() : OrderShipmentCreatedSuccessMessage(),
                title: 'Success!'
            }))
        } // eslint-disable-next-line
    }, [createShipmentfResponse]);
    const formLabels =  orderTypeId === OrderType.FBI ?  {
        title : "Access Code Information",
        textButton : "Record Access Code",
        numberLabel : "Access Code",
        date : "Record Date"

     } : {
        title : "Shipment Information",
        textButton : "Record Shipment",
        numberLabel : "Tracking Number",
        date : "Ship Date"
      }

    return (
        <>
            {(createShipmentLoading) && <Loader />}
            {!isDimmed ? (
                <Button className='set-shipping-info-btn' size='lg' onClick={initShippingInfoModal}>{formLabels.textButton}</Button>
            ) : (
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip>
                                {FingerprintsNotAvailable()}
                            </Tooltip>
                        }>
                        <span>
                            <Button className='set-shipping-info-btn' size='lg' disabled>{formLabels.textButton}</Button>
                        </span>
                    </OverlayTrigger>
            )}

            <Modal show={showShipmentModal} saveBtnTitle="Submit" title={formLabels.title}
                onSave={setShippingInfo} onClose={closeShippingInfoModal} isDisabledBtn={!isValid}>
                <Form onSubmit={setShippingInfo}>
                    <Form.Group className="mb-3" >
                        <Form.Label>{formLabels.numberLabel}</Form.Label>
                        <Form.Control className="input-control" {...registerShippingInfo('trackingNumber', shippingInfoValidationRules['trackingNumber'])} isInvalid={!!errorsShippingInfo.trackingNumber} />
                        <Form.Text>
                            {errorsShippingInfo.trackingNumber && <span className='error-msg'>{errorsShippingInfo.trackingNumber.message}</span>}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>{formLabels.date}</Form.Label>
                        <DateRangePicker initialSettings={{ singleDatePicker: true, startDate: moment().format(dateFormates.displayFormat), locale: { format: dateFormates.displayFormat } }}
                            onCallback={changeShipDateHandler}
                        >
                            <input type="text" className="form-control col-4" />
                        </DateRangePicker>
                        <Form.Text>
                            {errorsShippingInfo.shipDate && <span className='error-msg'>{errorsShippingInfo.shipDate.message}</span>}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Notes</Form.Label>
                        <Form.Control as="textarea" rows={3} {...registerShippingInfo('notes', shippingInfoValidationRules['notes'])} isInvalid={!!errorsShippingInfo.notes} />
                        <Form.Text>
                            {errorsShippingInfo.notes && <span className='error-msg'>{errorsShippingInfo.notes.message}</span>}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal>
        </>
    )
}

export default Shipment;
