import { Button, Modal } from 'react-bootstrap';
import './modalApp.scss'
interface IModalProps {
    show: boolean;
    size?: 'sm' | 'lg' | 'xl';
    title: string;
    children: JSX.Element;
    saveBtnTitle: string;
    isDisabledBtn?: boolean;
    hideCancelBtn?: boolean;
    hideCloseBtn?: boolean;
    hideSaveBtn?: boolean;
    onSave: () => void;
    onClose: () => void;
}

const ModalApp = (props: IModalProps) => {
    const handleClose = () => props.onClose();
    const handleSave = () => props.onSave();

    return (
        <>
            <Modal centered show={props.show} onHide={handleClose} size={props.size} >
                <Modal.Header closeButton ={!props.hideCloseBtn} closeVariant="white" className="px-4">
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    {props.children}
                </Modal.Body>
                <Modal.Footer className="px-4 mb-3">
                    {!props.hideCancelBtn &&
                        <Button className="modal-btn-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                    }
                   {!props.hideSaveBtn && <Button className="modal-btn-save" onClick={handleSave} disabled={props.isDisabledBtn ? props.isDisabledBtn : false}>
                        {props.saveBtnTitle}
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalApp
