import { Col } from 'react-bootstrap'
import { ILocation } from '../../core'

const LocationItem = ({ location }: { location: ILocation }) => {

  return (
    <>
               <div className="location-grid-row py-3 align-items-center">
                <Col>{location.ExternalLocationId}</Col>
                <Col>{location.Name}</Col>
                <Col>{location.City}</Col>
                <Col>{location.State}</Col>
                <Col>{location.ResubmittedTransactions}</Col>
                <Col>{location.CompletedTransactions}</Col>
                <Col>{location.ErrorRate ? Number(location.ErrorRate).toFixed(2): "UNDEFINED"}</Col>

            </div>
    </>
  )
}

export default LocationItem