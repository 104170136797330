import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { IGetOrderRequest, OrderStatus, OrderType, SortOptions, PaymentType, PaymentTypeDescription, OrderOrPassThroughArray, OrderTypeDescription } from "../../core";
import { useGetOrdersQuery } from "../../services";
import { NoOrdersRecord } from "../../utils/errorMessage";
import { AccountingOrderItem, Loader, Pagination, Select, OrderStatusSelect, ExportOrdersCsv, AccountNumberSearch, OrderTypeSelect } from "..";
import _ from "lodash";
import "./accounting.scss";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Accounting = () => {
    const keyRef = useRef(Date.now());
    const dateFormates = {
        endpointFormat: "YYYY-MM-DD HH:mm:ss",
        displayFormat: "MM/DD/YYYY",
    }
    const initialDateRange = {
        dateFrom: moment().subtract(1, "M").startOf("M").format(dateFormates.endpointFormat),
        dateTo: moment().subtract(1, "M").endOf("M").format(dateFormates.endpointFormat)
    }
    const [requestParams, setParams] = React.useState<IGetOrderRequest>({ ...initialDateRange, withFeeSum: true, sortBy: SortOptions.SortByCreatedDate, sortOrder: SortOptions.SortDescending });
    const [selectStatusValue, setStatus] = React.useState<OrderStatus[]>(requestParams.statuses ?? []);
    const [orderType, setOrderType] = React.useState<OrderType[] | null>(requestParams.types ?? null);
    const [startDate, setStartDate] = React.useState<string>(requestParams.dateFrom ?? '');
    const [endDate, setEndDate] = React.useState<string>(requestParams.dateTo ?? '');
    const [selectPaymentType, setPaymentType] = React.useState<PaymentType | null>(requestParams.paymentType ?? null);
    const [selectAccountNumbersValue, setSelectAccountNumbersValue] = React.useState<{label: string, value: number}[]| null>(null);
    const { data: orders, isLoading, isFetching } = useGetOrdersQuery(requestParams, { refetchOnMountOrArgChange: true });

    const changeStatus = React.useMemo(() => (selectedStatus: unknown) => {
        const statuses = _.map(selectedStatus as { value: number, label: string }[], (v, i) => v.value);
        if (statuses.length === 0) {
            setParams((prev) => ({ ...prev, statuses: undefined, page: undefined }));
        }
        else {
            setParams((prev) => ({ ...prev, statuses, page: undefined }));
        }
    }, []);

    const changeOrderType = React.useMemo(() => (selectedOrderType: unknown) => {
        const type = selectedOrderType as { value: number, label: string }
        setParams((prev) => ({ ...prev, types: type.value ? [type.value] : [], page: undefined })) 

    }, []);
    const handleSelectOrderType = (value: OrderOrPassThroughArray | null) =>  setOrderType(value as OrderType[])

    const changePaymentType = React.useMemo(() => (selectedPaymentType: { value: number, label: string }) => {
        setParams((prev) => ({ ...prev, paymentType: selectedPaymentType.value, page: undefined }));
    }, [])

    const clearSearch = () => {
        keyRef.current = Date.now();
        setOrderType(null)
        setStatus([]);
        setPaymentType(null);
        setStartDate(initialDateRange.dateFrom)
        setEndDate(initialDateRange.dateTo)
        setParams({ ...initialDateRange, withFeeSum: true });
        setSelectAccountNumbersValue([])
    }

    const changeDateRange: any = (event: any, picker: any) => {
        const dateFrom = picker.startDate.format(dateFormates.endpointFormat);
        const dateTo = picker.endDate.format(dateFormates.endpointFormat);
        setStartDate(dateFrom);
        setEndDate(dateTo);
        setParams((prev) => ({ ...prev, dateFrom: dateFrom, dateTo: dateTo, page: undefined }));
    }

    useEffect(() => {
        const selectedAccountNumbers = _.map(selectAccountNumbersValue, (v) => v.value);
        setParams((prev) => ({ ...prev, searchByAccountIds: selectedAccountNumbers, page: undefined }));
    }, [selectAccountNumbersValue])

    return (
        <>
            <Container fluid className="px-4">
                <Stack gap={3}>
                    {/* filter */}
                    <div>
                        {(isLoading || isFetching) && <Loader />}
                        <Stack gap={3} >
                            <Row md={6} xs={12} className="mt-md-2">
                                <Col md={3}>
                                    <DateRangePicker key={keyRef.current} onApply={changeDateRange} initialSettings={{ startDate: moment(startDate).format(dateFormates.displayFormat), endDate: moment(endDate).format(dateFormates.displayFormat), locale: { format: dateFormates.displayFormat } }}>
                                        <input type="text" className="form-control accounting-date-range-picker" />
                                    </DateRangePicker>
                                </Col>
                                <Col md={3}>
                                <OrderTypeSelect onChange={changeOrderType} onSelect={handleSelectOrderType} value={orderType} options={OrderTypeDescription} />

                                </Col>
                                <Col md={3}>
                                    <Select withCheckbox={false}
                                        options={_.map(PaymentTypeDescription, (value, index) => ({ value: Number(index), label: value }))}
                                        isMulti={false} onChange={changePaymentType}
                                        value={selectPaymentType} setValue={setPaymentType} placeHolder="Select Payment Method..." />
                                </Col>
                                <Col md={3}>
                                    <div className="select-status">
                                        <OrderStatusSelect onChange={changeStatus} value={selectStatusValue} setValue={setStatus} />
                                    </div>
                                </Col>

                            </Row>
                            <Row md={6} xs={12} className="mt-md-2">
                                <Col md={6} xs={12}>
                                    <AccountNumberSearch onSelect={setSelectAccountNumbersValue} accountNumbers={selectAccountNumbersValue} />
                                </Col>
                                <Col md={2}></Col>
                                <Col md={2}>
                                <Button variant="outline-danger pull-right" onClick={clearSearch}>
                                <i className="fa fa-ban"></i>  Clear</Button>
                                </Col>
                                <Col md={2}>
                                <div > 
                                    <ExportOrdersCsv exportCsvParams={requestParams} />
                                    </div>
                                </Col>
                            </Row>
                        </Stack>
                    </div>
                    {/* order grid */}
                    <div className="total-fees">
                        <Row>
                            <Col>
                                <Stack gap={5} direction="horizontal">
                                    <label>Total Rolling Fee:</label>
                                    <label className="total-fees-label">$ {orders?.Data.RollingFeeSum ?? 0}</label>
                                    <label>Total Government Fee:</label>
                                    <label className="total-fees-label">$ {orders?.Data.GovFeeSum ?? 0}</label>
                                </Stack>
                            </Col>
                            <Col className="col-auto">
                                <Stack gap={5} direction="horizontal">
                                    <label>Orders:</label>
                                    <label className="total-fees-label">{orders?.Data.Total ?? 0}</label>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                    <div className="grid-header pt-4">
                        <Col>Order Date</Col>
                        <Col>Applicant Name</Col>
                        <Col>Order Type</Col>
                        <Col>ORI</Col>
                        <Col>Rolling Fee</Col>
                        <Col>Government Fee</Col>
                        <Col>Status</Col>
                    </div>
                    <div>
                        {(!orders?.Data.Data || orders?.Data.Data.length === 0) &&
                            (
                                <div className="bg-light grid-row py-3 align-items-center">
                                    <div className="mx-3">{NoOrdersRecord()}</div>
                                </div>
                            )
                        }
                        {
                            _.map(orders?.Data.Data, (value, index) => {
                                return (
                                    <AccountingOrderItem order={value} key={value.Id} />
                                )
                            })
                        }
                    </div>
                    {/* Pagination */}
                    <Pagination currentPage={orders?.Data.CurrentPage ?? 1} totalPages={orders?.Data.TotalPages ?? 1} total={orders?.Data.Total ?? 0}
                        onClick={(page: number) => { setParams((prev) => ({ ...prev, page })); }} />
                </Stack>
            </Container>
        </>
    )
}

export default Accounting