export enum OrderType {
    FDLE = 1,
    PRINTTOCARD = 2,
    FBI = 3,
    CA = 4
}

export enum PassThroughOrderType {
    PRINTTOCARD = 2,
    FBI = 3,
    CA = 4
}

export type OrderOrPassThroughArray = OrderType[] | PassThroughOrderType[];

export const OrderTypeDescription: Record<OrderType, string> = {
    [OrderType.FDLE]: 'FDLE',
    [OrderType.PRINTTOCARD]: 'Print-to-Card',
    [OrderType.FBI]: 'FBI',
    [OrderType.CA]: 'California',
}

export const PassThroughOrdersDescription: Record<PassThroughOrderType, string> = {
    [PassThroughOrderType.PRINTTOCARD]: OrderTypeDescription[OrderType.PRINTTOCARD],
    [PassThroughOrderType.FBI]: OrderTypeDescription[OrderType.FBI],
    [PassThroughOrderType.CA]: OrderTypeDescription[OrderType.CA],
}

export const GtmOrderTypeDescription: Record <OrderType, string> = {
    [OrderType.FDLE]: "FDLE Fingerprinting",
    [OrderType.PRINTTOCARD]: 'Inkcard Fingerprinting',
    [OrderType.FBI]:'FBI Fingerprinting',
    [OrderType.CA ]:'CA Fingerprinting',
}
