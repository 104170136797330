import { Stack, Figure } from 'react-bootstrap';
import logo from "../../../assets/images/Certifix logo.jpeg";
import { getAuthFromStorage, useLogoutMutation } from '../../services';
import { Navigate } from 'react-router-dom';

const LoggedOutHeader = ({ wrapperClass, children }: { wrapperClass?: string, children: JSX.Element }) => {
    const [logout, { error, data }] = useLogoutMutation();
    const handleLogout = () => logout();
    const auth = getAuthFromStorage();
    return (<>
        {(data || error) && <Navigate to="/" replace={true} />}
        <Stack gap={3} className={wrapperClass ?? ''}>
            <header className="bg-white top-bar">
                <div className="text-center">
                    <Figure className="mb-0">
                        <Figure.Image width={209} height={204} src={logo} />

                    </Figure>
                    { auth &&
                        <span className='back-to-login' onClick={handleLogout}>Back to login</span>
                    }
                </div>
            </header>
            {children}
        </Stack>
    </>
    )
}

export default LoggedOutHeader