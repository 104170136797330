import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { Loader, SetupAuthAppModal, VerifyOtpModal } from '..';
import { TwoFactorAuthMethod } from '../../core';
import { useIsAuthMethodSelected } from '../../hooks';
import { useDisableTwoFactorAuthMutation, useEnableAuthAppMutation } from '../../services';
import { ReactComponent as AuthAppIcon } from '../../../assets/images/auth-app-icon.svg'; 
import { VerifyAuthAppModalBody } from "../../utils/confirmationMessage"

const AuthAppCard = ({ onDisableOtp , userAuthMethods }: { onDisableOtp?: () => void , userAuthMethods?: TwoFactorAuthMethod[] }) => {
  const isAuthSelected = useIsAuthMethodSelected(TwoFactorAuthMethod.AuthApp, userAuthMethods || [])

  const [enableAuthApp, { data: qrCode, isLoading: isEnableAuthAppLoading, error: enableAuthAppError }] = useEnableAuthAppMutation();
  const [disableTwoFactorAuth, { data: disabled, error: disableError, isLoading: isDisableLoading }] = useDisableTwoFactorAuthMutation()

  const [showAuthAppModal, setShowAuthAppModal] = React.useState(false);
  const [showVerifyOtpModal, setShowVerifyOtpModal] = React.useState(false)
  

  const handleEnableAuthApp = () => {
    if (!isAuthSelected) {
      enableAuthApp()
    } else {
      disableTwoFactorAuth({ twoFactorAuthMethod: TwoFactorAuthMethod.AuthApp }).finally(() => onDisableOtp && onDisableOtp())
    }
  }

  React.useEffect(() => {
    if (qrCode?.Data) {
      setShowAuthAppModal(true)
    }
    else {
      setShowAuthAppModal(false)
    }
  }, [qrCode])


  return (
    <>
      {(isDisableLoading || isEnableAuthAppLoading) && <Loader />}
      {(disabled || disableError || enableAuthAppError) && <Navigate to={'/login'} replace={true} />}
      <Card className='auth-card'>
        <div>
        <span><AuthAppIcon/></span>
        <span className='auth-title'> Authentication app </span>
        </div>
        <p className='auth-body'>
          We'll recommend an app to download, if you don't have one.
          It will generate a code that you'll enter when you log in.
        </p>
        <Button className={`auth-btn ${isAuthSelected && "turn-off"}`} onClick={handleEnableAuthApp}>
          {isAuthSelected ? "Turn Off" : "Use Authentication App"}
        </Button>
      </Card>
      <SetupAuthAppModal qrCode={qrCode?.Data} showModal={showAuthAppModal} setShowModal={setShowAuthAppModal} setShowVerifyOtpModal={setShowVerifyOtpModal} />
      <VerifyOtpModal showModal={showVerifyOtpModal} setShowModal={setShowVerifyOtpModal} twoFactorAuthMethod={TwoFactorAuthMethod.AuthApp} modalBody={VerifyAuthAppModalBody()} />

    </>
  )
}

export default AuthAppCard