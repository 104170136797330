import { DropdownButton, Figure, Stack, } from 'react-bootstrap';
import { useAppSelector } from '../../hooks'
import { useLogoutMutation } from '../../services';
import logo from '../../../assets/images/Certifix logo.jpeg';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { userSelector } from '../../store/slices/userSlice';
import './header.scss';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

enum Routes{
    security = `/security`,
    home = `/home`
}

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [logout, { error, data }] = useLogoutMutation();
    const user = useAppSelector(userSelector);
    const handleLogout = () => logout();
    const currentLocation = location.pathname
    const handleNavigate = () => currentLocation.includes(Routes.home) ? navigate(`${Routes.security}`, { replace: true }) : navigate(`${Routes.home}`, { replace: true })
    return (
        <>
            {(data || error) && <Navigate to="/" replace={true} />}
            <header className="bg-light header">
                <Stack direction="horizontal" gap={3}>
                    <div>
                        <Figure className="mb-0">
                            <Figure.Image width={171} height={188} src={logo} />
                        </Figure></div>
                    <div className="ms-auto">
                        <p className="mt-3 normal-font">
                            Welcome back{`${user.me?.FirstName || user.me?.LastName ? `, ${user.me?.FirstName} ${user.me?.LastName}` : ""}`}
                        </p>
                    </div>
                    <div>
                        <DropdownButton title="">
                            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                            { currentLocation.includes(Routes.home) && <DropdownItem onClick={handleNavigate}> Security </DropdownItem> }
                            { (currentLocation.includes(Routes.security) && user.me )&& <DropdownItem onClick={handleNavigate}> Home </DropdownItem> }
                        </DropdownButton>
                    </div>
                </Stack>
            </header>
        </>
    )
}

export default Header;