import { Button, Container, Form, Row, Stack } from 'react-bootstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, Navigate } from 'react-router-dom';
import { Loader, LoggedOutHeader } from '..';
import { IAuthRequest } from '../../core';
import { useAuthenticateUserMutation } from "../../services";
import { formValidator, validationRules } from '../../utils/formValidator';
import "./login.scss";

const Login = () => {
    const [authenticateUser, { data, isLoading }] = useAuthenticateUserMutation();
    const { register, handleSubmit, formState: { errors } } = useForm<IAuthRequest>({ mode: 'all' });
    const onSubmit: SubmitHandler<IAuthRequest> = (auth) => authenticateUser(auth);

    const authValidationRules = validationRules<IAuthRequest>({
        email: formValidator.email,
        password: { required: true }
    });
   
    return (
        <>
            {isLoading && <Loader />}
            {data?.Data && <Navigate to="/verify-otp" replace={true} />}
            <LoggedOutHeader wrapperClass="header-container-gap">
                <Container className="container-wrapper">
                    <Row className="form-row">
                        <div className="form my-4 pt-4">
                            <Stack gap={2}>
                                <h6 className="text-center title">
                                    Login
                                </h6>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3 px-4" controlId="email">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control className="input-control" {...register('email', authValidationRules['email'])} isInvalid={!!errors.email} type="email" />
                                        <Form.Text>
                                            {errors.email && <span className='error-msg'>{errors.email.message}</span>}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group className="mb-5 px-4" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control className="input-control" {...register('password', authValidationRules['password'])} isInvalid={!!errors.password} type="password" />
                                        <Form.Text>
                                            {errors.password && <span className='error-msg'>{errors.password.message}</span>}
                                        </Form.Text>
                                        <Link className='forget-password-link' to="/forget-password">Forgot Password?</Link>
                                    </Form.Group>
                                    <div className="container-wrapper mb-3">
                                        <Button className="login-btn" size="lg" type="submit">
                                            Login
                                        </Button>
                                    </div>
                                </Form>
                            </Stack>
                        </div>
                    </Row>
                </Container>
            </LoggedOutHeader>
        </>
    )
}

export default Login;