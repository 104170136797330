import { Col } from 'react-bootstrap'
import { IOrder, OrderStatus, OrderStatusDescription, OrderTypeDescription, StatusbgClass } from '../../core'
import { useIsCompletedOrder } from '../../hooks'
import {formatDateUTC} from "../../utils/dateTime";

const AccountingOrderItem = ({ order }: { order: IOrder }) => {
    const statusDescription = useIsCompletedOrder(order.StatusId) ? OrderStatusDescription[OrderStatus.COMPLETED] : OrderStatusDescription[order.StatusId]

    return (
        <>
            <div className="accounting-grid-row py-3 align-items-center">
                <Col>{order.createdAt ? formatDateUTC(order.createdAt) : ''}</Col>
                <Col className='name-col'>{order.FirstName} {order.LastName}</Col>
                <Col>{OrderTypeDescription[order.OrderTypeId]}</Col>
                <Col>{order.Ori}</Col>
                <Col>$ {order.RollingFee ?? 0}</Col>
                <Col>$ {order.GovFee ?? 0}</Col>
                <Col className='status-col'><div className='circle' style={StatusbgClass[order.StatusId]}></div>{statusDescription}</Col>
            </div>

        </>
    )
}

export default AccountingOrderItem