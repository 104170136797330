import { useMemo } from "react";
import { Role, GetLevelByRole } from "../core";
import { useAppSelector } from "./storeHook";
import { userSelector } from "../store/slices/userSlice";

// Hook to check if the current user can act on a target user based on role levels.
// Returns true if the current user's role level is lower than the target user's.

export const useIsRolePermitted = (targetUserRole: Role): boolean => {
  const userState = useAppSelector(userSelector);

  return useMemo(() => {
    const currentRoleLevel = GetLevelByRole[userState.me?.RoleId || 0]; // get role level for current user
    const targetRoleLevel = GetLevelByRole[targetUserRole]; // get role level for the target user
    return currentRoleLevel < targetRoleLevel;

  }, [ targetUserRole, userState]);
};