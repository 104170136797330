export enum SortOptions {
    SortAscending = 1,
    SortDescending = 2,
    SortByName = 3,
    SortByCreatedDate = 4,
    SortByOrderType = 5,
    SortByFees = 6,
    SortByStatus = 7,
    SortByRole = 8,
    SortByEmail = 9,
    SortByIsActive = 10,
    SortById = 11,
    SortByErrorRate = 12,
    SortByCompletedTransactions = 13,
    SortByCity = 14,
    SortByState = 15,
    SortByBusinessName = 16,
    SortByResubmittedTransactions =17,
}