import {  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ApiRequest } from "../../services";
import { IUser } from '../../core';

interface UserState {
    me: IUser | null;
    isLoggedIn: boolean;
};

export const initialState: UserState = {
    me: null,
    isLoggedIn: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.me = initialState.me;
            state.isLoggedIn = initialState.isLoggedIn;
        },
    },
    extraReducers: (builder) => {
      
        //update user after request me
        builder.addMatcher((ApiRequest.endpoints.me.matchFulfilled), (state, action) => {
            state.me = action.payload.Data;
            state.isLoggedIn = true;
        });
     
        builder.addMatcher((ApiRequest.endpoints.logout.matchFulfilled || ApiRequest.endpoints.logout.matchRejected), (state, action) => {
            state.me = initialState.me;
            state.isLoggedIn = initialState.isLoggedIn;
        });
            
       
    }
});

export const userSelector = (state: RootState): UserState => state.user;
export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;