import React from 'react'
import { Col, Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OrderDetails } from '..'
import { IOrder, OrderStatus, OrderStatusDescription, OrderTypeDescription, StatusbgClass , SterlingStatusDescription } from '../../core'
import { useIsCompletedOrder } from '../../hooks'
import { formatDateTime } from '../../utils/dateTime'
import deliveredIcon from "../../../assets/images/delivered.png";

const OrderItem = ({ order, refresh }: { order: IOrder, refresh: () => void }) => {
    const [isShipped, setIsShipped] = React.useState<boolean>(order.IsShipped);
    const statusDescription = useIsCompletedOrder(order.StatusId) ? OrderStatusDescription[OrderStatus.COMPLETED] : OrderStatusDescription[order.StatusId]

    const setIsShippedValue = (isShipped: boolean) => {
        setIsShipped(isShipped);
    }

    return (
        <>
            <Accordion.Item eventKey={order.Id.toString()} className='order-item'>
                <Accordion.Header>
                    <div className="grid-row py-3 align-items-center">
                        <Col className='name-col'>{order.FirstName} {order.LastName}</Col>
                        <Col className='hidden-in-responsive'>{order.createdAt ? formatDateTime(order.createdAt) : ''}</Col>
                        <Col className='hidden-in-responsive'>{OrderTypeDescription[order.OrderTypeId]}</Col>
                        <Col className='hidden-in-responsive'>$ {order.Amount}</Col>
                        <OverlayTrigger
                            placement='top-start'
                            overlay={
                                <Tooltip>
                                    Actual Status: <strong>{SterlingStatusDescription[order.SterlingStatus]}</strong>
                                </Tooltip>
                            }
                        >
                            <Col className='hidden-in-responsive status-col'><div className='circle' style={StatusbgClass[order.StatusId]}></div><span>{statusDescription}</span> {isShipped && <img className='delivery-icon' src={deliveredIcon} alt='' ></img>}</Col>
                        </OverlayTrigger>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <OrderDetails order={order} setIsShipped={setIsShippedValue} refresh={refresh} />
                </Accordion.Body>
            </Accordion.Item>

        </>
    )
}

export default OrderItem