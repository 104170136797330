import { useNavigate } from 'react-router-dom';
import { SecurityMethod, TwoFactorAuthContainer } from '..';
import { TwoFactorAuthMethod } from '../../core';
import { useAppSelector, useStoreUserAuthMethods } from '../../hooks';
import { authMethodSelector } from '../../store/slices/authMethodSlice';

const TwoFactorAuthentication = () => {
  useStoreUserAuthMethods();
  const authMethodState = useAppSelector(authMethodSelector);
  const navigate = useNavigate();
  const handleBackBtn = () => navigate("/security", {replace: true})
  const userAuthMethods = authMethodState.twoFactorAuthMethods ? authMethodState.twoFactorAuthMethods?.map((auth)=> { return auth}) : []
  const backupMethods = Object.values(TwoFactorAuthMethod).filter((method) => typeof method === 'number' && !authMethodState.twoFactorAuthMethods?.includes(method)) as TwoFactorAuthMethod[]
  
  return (
    <>
      <div className='two-factor-auth-header'>
        <span className='back-btn' onClick={handleBackBtn}>
          <i className="fa fa-chevron-left"></i> Back
        </span>
        <h5>Two-Factor Authentication</h5>
      </div>

      { !authMethodState.twoFactorAuthMethods?.length ? <TwoFactorAuthContainer  /> : <>
      
      <SecurityMethod title='Selected method' securityMethods={userAuthMethods} enabledAuthMethods={userAuthMethods}  />
      <SecurityMethod title='Add a backup method' securityMethods={backupMethods} enabledAuthMethods={userAuthMethods} />
      </>
      }    </>
  )
}

export default TwoFactorAuthentication