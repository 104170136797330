import _ from "lodash";
import { Tab, Nav, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAppSelector, useCurrentLocation } from "../../hooks";
import { userSelector } from "../../store/slices/userSlice";
import { tabs } from "../../utils/tabs";
import './NavSidemenu.scss'


interface INavProps {
    onChange?: (title: string) => void;
}
const NavSidemenu = (props: INavProps) => {
    const userState = useAppSelector(userSelector);
    const currrentLocation = useCurrentLocation();
    const { route } = currrentLocation;
    const tabsArray = tabs;
    return (
        <>
            <div className='nav-sidemenu'>
                <Tab.Container activeKey={route}>                  
                    <Nav variant="pills" className="flex-column" >
                        <Stack gap={3}>
                            {
                                _.map(tabsArray, (value, index) => {
                                    return (
                                        <div key={index} onClick={() => props.onChange ? props.onChange(value.text) : {}}>
                                            {_.includes(value.roles, userState.me?.RoleId) &&
                                                <Nav.Item>
                                                    <Nav.Link eventKey={value.name} as={Link} to={value.route}>
                                                        <span className='tab-text-icon'>
                                                            <i className={value.icon}></i>
                                                            <span className='tab-text'>{value.text}</span>
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Stack>

                    </Nav>
                </Tab.Container>
            </div>


        </>
    )
}

export default NavSidemenu