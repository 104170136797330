import React from 'react'
import { Button, Form } from 'react-bootstrap'
import './orderDetails.scss'
import { formValidator, validationRules } from '../../utils/formValidator';
import { useForm } from 'react-hook-form';
import { IOrder, IRestResponse } from '../../core';
import { Loader, Modal } from '..';
import { notificationActions } from '../../store/slices/notificationSlice';
import { useAppDispatch } from '../../hooks';
import { ResendEmailSuccessMessage } from '../../utils/confirmationMessage';

interface FormState {
    id: number,
    email: string,
    readOnly: boolean,
    checked: boolean,
}
interface IModalProps {
    btnTitle: string,
    title: string,
    resendBtnTitle: string,
    response?: IRestResponse<boolean>,
    isLoading: boolean,
    order: IOrder,
    handleAction: ({ id, email }: { id: number, email: string }) => void,

}
const ResendEmail = ({ order, btnTitle, title, resendBtnTitle, isLoading, response, handleAction }: IModalProps) => {

    const { register, setValue, reset, trigger, getValues, formState: { errors, isValid } } = useForm<FormState>({ mode: 'all', defaultValues: { id: order.Id, email: order.Email, readOnly: true, checked: true } });
    const dispatch = useAppDispatch();
    

    const [showResendModal, setShowResendModal] = React.useState<boolean>(false)

    const resendValidationRules = validationRules<{ email: string }>({
        email: formValidator.email,
    });

    const initResendModal = () => {
        //use reset to update the form values if an order is edited
        reset({id: order.Id,email: order.Email, readOnly: true, checked: true });
        setShowResendModal(true)
       
    }
    const closeResendModal = () => {
        setShowResendModal(false)   
    }
    
    const changeCheckboxHandler = () => {
        if (getValues().checked) {
            setValue('email', '')
            setValue('readOnly', false);
            setValue('checked', false);
        } else {
             reset({ id: order.Id, email: order.Email, readOnly: true, checked: true });
        }
        trigger();
    }
    
    const onSaveModalHandler = () => {

        handleAction({ id: getValues().id, email: getValues().email });

    }


    React.useEffect(() => {
        if (response?.Data) {
            closeResendModal();
            dispatch(notificationActions.set({
                view: true,
                autoHide: true,
                state: 'success',
                text: ResendEmailSuccessMessage(),
                title: 'Success!'
            }))
        }
        closeResendModal();

        // eslint-disable-next-line
    }, [response]);

    return (
        <>
            {(isLoading) && <Loader />}
            <Button className='resend-email-btn' size='lg' onClick={initResendModal}> {resendBtnTitle} </Button>
            <Modal show={showResendModal} saveBtnTitle={btnTitle} title={title} onSave={onSaveModalHandler} onClose={closeResendModal} isDisabledBtn={!isValid || errors.email ? true : false}>
                <Form >

                    <Form.Group className="mb-3" >
                        <Form.Label>Email address</Form.Label>
                        <Form.Control readOnly={getValues().readOnly} className="input-control" {...register('email', resendValidationRules['email'])} isInvalid={!!errors.email} type="email" />
                        <Form.Text>
                            {errors.email && <span className='error-msg'>{errors.email.message}</span>}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Use default email" defaultChecked={getValues().checked} onChange={changeCheckboxHandler} />
                    </Form.Group>
                </Form>
            </Modal>

        </>
    )
}

export default ResendEmail