import { ConfirmationResult } from 'firebase/auth';
import React from 'react';
import { useAppDispatch } from '../../hooks';
import { saveTimerStartTime } from '../../services';
import { notificationActions } from '../../store/slices/notificationSlice';
import { SendOtpError } from '../../utils/errorMessage';
import { sendOtp, initRecaptcha, windowRef } from '../../utils/firebase';
import { Timer } from '../../core';
import { Loader } from '..';

interface SendOtpProps {
    setConfirmationResult?: (value: ConfirmationResult) => void,
    title: string,
    setRestartTimer: (value: boolean) => void,
    setTime: (value: number) => void,
    time: number,
    isCodeResent: boolean, 
    phoneNumber?: string
}
const SendOtp = ({ setConfirmationResult, setRestartTimer, setTime, title, time, isCodeResent, phoneNumber }: SendOtpProps) => {
    const dispatch = useAppDispatch();
    const [sendOtpLoading, setSendOtpLoading] = React.useState<boolean>(false);
    const handleSendOtp = () => {
        if (time === 0 || time === Timer.initialSmsTime) {
            saveTimerStartTime()
            setRestartTimer(true)
            setTime(Timer.initialSmsTime);
            setSendOtpLoading(true)
            phoneNumber  && sendOtp(phoneNumber, windowRef.RecaptchaVerifier).then((res) => {
              setConfirmationResult &&  setConfirmationResult(res)
                setSendOtpLoading(false)
            }).catch((error) => {
                setSendOtpLoading(false)
                dispatch(notificationActions.set({
                    view: true,
                    autoHide: true,
                    state: 'danger',
                    text: SendOtpError(),
                    title: 'Error'
                }));
            });
            
        }
    }

    React.useEffect(() => {
        if (!windowRef.RecaptchaVerifier) {
            initRecaptcha();
        }
    // Adding a return statement as a placeholder for a cleanup function
        return () => {
        };
    }, []);

    return (
        <>
        <div id='recaptcha-container'></div>
            {sendOtpLoading && <Loader />}
            <span className={`send-code ${(time >1 && !isCodeResent) && 'dimmed'}`} onClick={handleSendOtp}>{title}</span>
        </>
    )
}

export default SendOtp