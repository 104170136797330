import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoggedOutHeader } from "..";
import { InviteUSerConfirmationMessage } from '../../utils/confirmationMessage';

const Confirmation = () => {
    return (
        <>
            <LoggedOutHeader>
                <Container className="container-form-center">
                    <Row className="text-center mt-4 pt-4">
                        {InviteUSerConfirmationMessage()}<Link to="/login">here.</Link>
                    </Row>
                </Container>
            </LoggedOutHeader>
        </>
    )
}

export default Confirmation