import { useEffect } from "react";
import { Form, Stack } from "react-bootstrap";
import { IOrder, IUpdateOrderRequest, OrderType, EditType } from "../../core";
import { useUpdateOrderMutation } from "../../services";
import { formValidator, validationRules } from "../../utils/formValidator";
import { useForm, RegisterOptions, Controller } from "react-hook-form";
import { Select, Modal, Loader } from "..";
import { usStates } from "../../utils/usStates";
import { useAppDispatch } from "../../hooks";
import { notificationActions } from "../../store/slices/notificationSlice";
import { UpdateOrderSuccessMessage } from "../../utils/confirmationMessage";

const EditOrder = ({
  order,
  showModal,
  onClose,
  onSuccess,
  editType,
}: {
  order: IOrder;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editType: EditType;
}) => {
  const [updateOrder, { data: editOrderResponse, isLoading }] =
    useUpdateOrderMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm<IUpdateOrderRequest>({
    mode: "all",
    defaultValues: {
      Email: order.Email,
      Address: {
        address1: order.Address1,
        address2: order.Address2,
        city: order.City,
        state: order.State,
        zip: order.Zip,
      },
    },
  });

  const stateOptions = usStates.map((state) => ({
    value: state.abbreviation,
    label: `${state.name} (${state.abbreviation})`,
  }));

  const handleClose = () => {
    onClose();
    reset({
      Email: order.Email,
      Address: {
        address1: order.Address1,
        address2: order.Address2,
        city: order.City,
        state: order.State,
        zip: order.Zip,
      },
    });
  };

  const onSubmit = (data: IUpdateOrderRequest) => {
    const updateData: IUpdateOrderRequest = {
      id: order.Id,
      Email: editType === "email" ? data.Email : undefined,
      Address: editType === "address" ? data.Address : undefined,
    };

    updateOrder(updateData);
  };

  useEffect(() => {
    if (editOrderResponse?.Data) {
      onClose();
      onSuccess();
      dispatch(
        notificationActions.set({
          view: true,
          autoHide: true,
          state: "success",
          text: UpdateOrderSuccessMessage(),
          title: "Success!",
        })
      );
    }

    // eslint-disable-next-line
  }, [editOrderResponse]);

  const formValidationRules = validationRules<{
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  }>({
    email: formValidator.email,
    address1: { required: true, maxLength: 100, displayName: "Address" },
    address2: { maxLength: 100, displayName: "Address" },
    city: {
      required: true,
      maxLength: 20,
      pattern: "alphaCharactersDashsSpaces",
      displayName: "City",
    },
    state: { required: true, displayName: "State" },
    zip: {
      required: true,
      hasLength: 5,
      pattern: "digitsString",
      displayName: "Zip Code",
    },
  });

  return (
    <Modal
      show={showModal}
      onClose={handleClose}
      onSave={handleSubmit(onSubmit)}
      title={`Edit ${editType}`}
      saveBtnTitle="Save"
      hideCancelBtn={false}
      hideCloseBtn={false}
      hideSaveBtn={false}
      isDisabledBtn={isLoading || !isValid}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {editType === "email" ? (
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                {...register(
                  "Email",
                  formValidationRules.email as RegisterOptions
                )}
                isInvalid={!!errors.Email}
              />
              <Form.Text>
                {errors.Email && (
                  <span className="error-msg">{errors.Email.message}</span>
                )}
              </Form.Text>
            </Form.Group>
          ) : (
            <>
              <Stack gap={3}>
                <Form.Group controlId="address1">
                  <Form.Label>
                    {order.OrderTypeId === OrderType.CA
                      ? "Address *"
                      : order.OrderTypeId === OrderType.FBI ||
                        order.OrderTypeId === OrderType.PRINTTOCARD
                      ? "Street Address or P.O. Box *"
                      : "Address 1 *"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register(
                      "Address.address1",
                      formValidationRules.address1 as RegisterOptions
                    )}
                    isInvalid={!!errors.Address?.address1}
                  />
                  {errors.Address?.address1 && (
                    <>
                      <Form.Text>
                        <span className="error-msg">
                          {errors.Address.address1.message}
                        </span>
                      </Form.Text>
                      <br />
                    </>
                  )}
                  {order.OrderTypeId === OrderType.CA && (
                    <Form.Text>
                      <small>
                        Street Number Direction Street Name, Apartment Number
                        (ex: 15 W Davidson, #40)
                      </small>
                    </Form.Text>
                  )}
                </Form.Group>

                {order.OrderTypeId !== OrderType.CA && (
                  <Form.Group controlId="address2">
                    <Form.Label>
                      {order.OrderTypeId === OrderType.FBI ||
                      order.OrderTypeId === OrderType.PRINTTOCARD
                        ? "Apt, Suite, Unit, building, floor, etc."
                        : "Address 2"}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register(
                        "Address.address2",
                        formValidationRules.address2 as RegisterOptions
                      )}
                    />
                    <Form.Text>
                      {errors.Address?.address2 && (
                        <span className="error-msg">
                          {errors.Address.address2.message}
                        </span>
                      )}
                    </Form.Text>
                  </Form.Group>
                )}

                <Stack direction="horizontal" gap={3}>
                  <Form.Group controlId="city">
                    <Form.Label>City *</Form.Label>
                    <Form.Control
                      type="text"
                      {...register(
                        "Address.city",
                        formValidationRules.city as RegisterOptions
                      )}
                      isInvalid={!!errors.Address?.city}
                    />
                    <Form.Text>
                      {errors.Address?.city && (
                        <span className="error-msg">
                          {errors.Address.city.message}
                        </span>
                      )}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="state">
                    <Form.Label>State *</Form.Label>
                    <Controller
                      name="Address.state"
                      control={control}
                      rules={formValidationRules.state as RegisterOptions}
                      render={({ field }) => (
                        <Select
                          options={stateOptions}
                          isMulti={false}
                          withCheckbox={false}
                          value={stateOptions.find(
                            (option) => option.value === field.value
                          )}
                          placeHolder="Select a state"
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          setValue={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          withEmptyOption={false}
                        />
                      )}
                    />
                    {errors.Address?.state && (
                      <Form.Text className="error-msg">
                        {errors.Address.state.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Stack>

                <Form.Group controlId="zip">
                  <Form.Label>Zip *</Form.Label>
                  <Form.Control
                    type="text"
                    {...register(
                      "Address.zip",
                      formValidationRules.zip as RegisterOptions
                    )}
                    isInvalid={!!errors.Address?.zip}
                  />
                  <Form.Text>
                    {errors.Address?.zip && (
                      <span className="error-msg">
                        {errors.Address.zip.message}
                      </span>
                    )}
                  </Form.Text>
                </Form.Group>
              </Stack>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default EditOrder;
